/**
 * Componente que exibe uma descrição informativa com um ícone.
 *
 * Props:
 * - text: O texto da descrição.
 *
 * Exemplo de uso:
 * ```jsx
 * <InfoDescription text="Esta é uma descrição informativa." />
 * ```
 */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareInfo } from "@fortawesome/pro-light-svg-icons";

export const InfoDescription = ({text}) => {
    return (
        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed mb-9 p-6">
            <FontAwesomeIcon icon={faSquareInfo} size="2xl" className="me-5" />
            <div className="d-flex flex-stack flex-grow-1">
                <div className="fw-bold">
                    <div className="fs-6 text-gray-600">
                        {text}
                    </div>
                </div>
            </div>
        </div>
    );
};
