import { Route, Routes } from "react-router-dom";
import { OnesheetListWrapper } from "../../modules/onesheet/OnesheetListWrapper";
import { OnesheetWizard } from "../../modules/onesheet/CreateOnesheetWizard";
import { OnesheetForm } from "../../modules/onesheet/OnesheetForm";

const OnesheetRoutes = () => {
    return (
        <Routes>
            <Route path="" element={<OnesheetListWrapper />} />
            <Route path="wizard" element={<OnesheetWizard />} />
            <Route path=":onesheetId" element={<OnesheetForm />} />
        </Routes>
    );
};

export { OnesheetRoutes };
