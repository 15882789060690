import { Link } from "react-router-dom";
import "./LatestCoursesWidget.css";
import { CustomCarousel } from "../../../components/CustomCarousel";
import { Card, CardBody } from "next2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/pro-light-svg-icons";

import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { CardTitle } from "../../../components/Cards/CardTitle";
/**
 * Widget para "Últimos Cursos"
 * @param {String} className classname customizado
 * @param {Array} courses lista de cursos
 * @returns {JSX.Element}
 * @constructor
 */
const LatestCoursesWidget = ({ className, courses }) => {
    const { t } = useTranslate();
    return (
        <Card className="h-full bg-body">
            <CardBody className="p-0 bg-body">
                {courses?.length > 0 ? (
                    <CustomCarousel
                        items={courses.map((course, index) => (
                            <div
                                className={`card bg-no-repeat bg-cover ${className}`}
                                style={{
                                    backgroundImage: `url(${course.thumbnail})`,
                                    backgroundPosition: "center",
                                }}
                            >
                                <div
                                    style={{
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'end',
                                        background: 'linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(255, 255, 255, 0) 60%)',
                                        width: '100%',
                                        paddingBottom: '2.5rem',
                                        justifyContent: 'center'
                                    }}
                                >
                                    <Link
                                        key={index}
                                        to={`/academy/course/${course.id}`}
                                        className="text-white text-center fs-4 fw-bold h-full align-content-end d-grid"
                                    >
                                        {course.name}
                                    </Link>
                                </div>
                            </div>
                        ))}
                    />
                ) : (
                    <Link to={"/academy"} className={"p-0 h-full"}>
                        <div
                            className={`flex flex-col justify-center items-center text-white h-full`}
                        >
                            <FontAwesomeIcon
                                icon={faBook}
                                className="mb-4"
                                size={"2xl"}
                            />
                            <CardTitle
                                text={t("GENERAL.AVAILABLE-FEATURES.KBASE")}
                            />
                        </div>
                    </Link>
                )}
            </CardBody>
        </Card>
    );
};

export { LatestCoursesWidget };
