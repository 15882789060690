import React, { useEffect, useState } from "react";
import { useApi } from "../../../../../helpers/Api/ApiWrapper";
import { useAuth } from "../../../../auth";
import { getColorSchemeForProgressBar } from "../../../../../helpers/utils/healthCheckColorScheme";

/**
 * Barrinha de completude de itens marcados no HealthCheck
 * @param {number} percentage porcentagem total de preenchimento
 * @returns {JSX.Element}
 * @constructor
 */
const HealthCheckProgressBar = ({ percentage }) => {
    const { GET } = useApi();
    const { currentArtist } = useAuth();
    const [updatedPercentage, setUpdatedPercentage] = useState(0);

    /**
     * Se o valor de porcentagem for passado como parâmetro (a tela de marcar itens de HC passa), usaremos esse valor.
     * Como medida de precaução, para os casos onde percentage não é passado (a tela de dashboard nao passa), fazemos
     * uma chamada de API pra pegar a qtd de itens totais e marcados, e definimos a porcentagem a partir deles
     */
    useEffect(() => {
        if (percentage) {
            setUpdatedPercentage(percentage);
        } else {
            GET(`ds/hc/list-with-checked/${currentArtist.id}`)
                .then((response) => {
                    setUpdatedPercentage(
                        Math.floor(
                            (response.data.checkedCount /
                                response.data.totalCount) *
                                100
                        )
                    );
                })
                .catch((e) => console.error(e));
        }
    }, [percentage]);

    return (
        <>
            <div className="d-flex align-items-center w-200px w-sm-300px flex-column mt-3">
                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                    <span className="text-white font-semibold">{updatedPercentage}%</span>
                </div>
                <div className="h-10px mx-3 w-100 bg-light mb-3 rounded">
                    <div
                        className={`bg-${getColorSchemeForProgressBar(
                            updatedPercentage
                        )} rounded h-10px progress-bar`}
                        role="progressbar"
                        style={{
                            width: `${updatedPercentage}%`,
                        }}
                    ></div>
                </div>
            </div>
        </>
    );
};

export { HealthCheckProgressBar };
