import React from 'react';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

function ModalComponent({children, isOpen, modalHeader, toggle, modalFooter, ...props}) {
    return (
        <Modal size={props.size || 'xl'}
               isOpen={isOpen}
               toggle={toggle}
        >
            <ModalHeader toggle={toggle}>
                {modalHeader}
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
            <ModalFooter>
                {modalFooter}
            </ModalFooter>
        </Modal>
    )
}

export default ModalComponent;
