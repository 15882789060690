import { Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import "filepond/dist/filepond.min.css";
import { useAlert } from "../../../../helpers/utils/CustomAlert";
import { useAppOniApi } from "../../../../helpers/Api/AppOniApiWrapper";
import { useTranslate } from "../../../../helpers/i18n/useTranslate";
import Warning from "../AppOniComponents/Warning";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import AudioManagementComponent from "../AppOniComponents/AudioManagementComponent";
import { dynamicSort } from "../../../../helpers/usefulFunctions";
import { CustomSaveButton } from "../../../../components/CustomSaveButton";
import { faArrowLeft, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Renderiza a parte de submissão de áudios de fonogramas do formulario de label
 * @param {function} setFormPhase Funcao que renderiza os estágios do formulário corretamente
 * @param {String} projectId id do projeto relacionado
 * @param {String} labelId id da label, em caso de edicao
 * @param {number} formPhase estágio atual do formulário
 * @returns {JSX.Element}
 * @constructor
 */
export default function AssetsTracks({
    setFormPhase,
    projectId,
    labelId,
    formPhase,
}) {
    const { t } = useTranslate();
    const { apponi_get } = useAppOniApi();
    const { dialogs } = useAlert();
    const [assets, setAssets] = useState([]);
    const [assetsLoading, setAssetsLoading] = useState(true);

    useEffect(() => {
        if (formPhase !== 3) setAssets([]);
        else {
            apponi_get(`catalog/api/label-assets/?label_id=${labelId}`)
                .then((response) => {
                    let loadedAssetsData = response.data.results;
                    loadedAssetsData.sort(
                        dynamicSort("APPONI.LABELFORM.order")
                    );
                    loadedAssetsData = loadedAssetsData.filter(
                        (asset) => asset.media !== "VIDE"
                    );
                    setAssets([...loadedAssetsData]);
                })
                .finally(() => {
                    setAssetsLoading(false);
                });
        }
    }, [formPhase]);

    return (
        <>
            <Warning
                title={t("APPONI.LABELFORM.audio_files")}
                description={`${t(
                    "APPONI.LABELFORM.audio_files_tutorial_1"
                )} <strong>"${t(
                    "APPONI.LABELFORM.label_media_audi"
                )}"</strong> ${t("APPONI.LABELFORM.or")} <strong>"${t(
                    "APPONI.LABELFORM.label_media_auvd"
                )}"</strong> ${t("APPONI.LABELFORM.audio_files_tutorial_2")}.`}
            />
            {assetsLoading ? (
                <LoadingSpinner />
            ) : (
                assets.map((asset, index) => (
                    <Row key={`asset-${index}-audio`}>
                        <div
                            style={{
                                height: "2px",
                                width: "100%",
                                backgroundColor: "#dee2e6",
                            }}
                            className={`my-${2}`}
                        />
                        <Col xs={5}>
                            <h2>{asset.title}</h2>
                        </Col>
                        <Col xs={7}>
                            {asset.asset ? (
                                <span>
                                    <i
                                        className="fa fa-exclamation-triangle mr-2"
                                        style={{ color: "orange" }}
                                    />
                                    <strong className="strongStyle">
                                        {t(
                                            "APPONI.LABELFORM.existing_related_asset"
                                        )}
                                        <br />
                                        {t(
                                            "APPONI.LABELFORM.audio_file_blocked"
                                        )}
                                    </strong>
                                </span>
                            ) : (
                                <AudioManagementComponent
                                    assetId={asset.id}
                                    audioTrackUrl={asset.audio_track_url}
                                />
                            )}
                        </Col>
                    </Row>
                ))
            )}
            <div className="d-flex justify-content-between mt-5">
                <CustomSaveButton
                    icon={<FontAwesomeIcon icon={faArrowLeft} />}
                    buttonText={"APPONI.LABELFORM.back_to_label_filling_2"}
                    onClick={() => setFormPhase(2)}
                    className={`btn btn-lg btn-secondary`}
                />
                <CustomSaveButton
                    icon={<FontAwesomeIcon icon={faCheckCircle} />}
                    buttonText={"APPONI.LABELFORM.finish"}
                    onClick={() => {
                        // window.location.replace(`/projects/dt/${projectId}`);
                        dialogs.success(() =>
                            window.location.replace(`/projects/dt/${projectId}`)
                        );
                    }}
                    className={`btn btn-lg btn-success`}
                />
            </div>
        </>
    );
}
