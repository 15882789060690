export function makeFormDataBodyForAppOni(data, formsetData = null) {
    /*
    Função que gera o FormData dos dados de formulário passados como parâmetro.
    Args:
        data: state com os campos e dados do form
        formsetData: (opcional) lista de dicts que contêm os dados dos formsets. Formato exigido por este parâmetro:
            [{
                    djangoFormsetPrefix: 'labelasset', -> esta string será concatenada com '_set' nos campos do formset
                    totalForms: assetList.length, -> tamanho da lista de formsets
                    initialForms: initialForms, -> states de controle do formset
                    minNumForms: minNumForms, -> states de controle do formset
                    maxNumForms: maxNumForms, -> states de controle do formset
                    content: assetList, -> lista de objetos
                }, ...]
            dessa forma, teremos um objeto deste formato para cada formset da página
     */
    let formDataBody = new FormData();
    // Iterando sobre o dicionário do objeto principal
    for (let field in data) {
        let value = data[field];
        // Se o campo em questão for um dict, queremos na verdade o valor desse dict
        if (value && value.constructor === Object) value = value.value;
        if (
            field === "primary_artists" ||
            field === "featuring_artists" ||
            field === "users"
        ) {
            for (let objectId of data[field]) {
                formDataBody.append(field, objectId);
            }
        } else {
            formDataBody.append(field, value);
        }
    }
    if (formsetData) handleFormsetForAppOni(formDataBody, formsetData);

    return formDataBody;
}

function handleFormsetForAppOni(formDataBody, formsetData) {
    for (let formsetInfo of formsetData) {
        // Podemos ter mais de um formset numa página, de objetos diferentes
        let prefix = `${formsetInfo["djangoFormsetPrefix"]}_set-`; // Prefixo que será usado pra todos os campos

        // Adicionando o metadata deste formset
        formDataBody.append(`${prefix}TOTAL_FORMS`, formsetInfo["totalForms"]);
        formDataBody.append(
            `${prefix}INITIAL_FORMS`,
            formsetInfo["initialForms"]
        );
        formDataBody.append(
            `${prefix}MIN_NUM_FORMS`,
            formsetInfo["minNumForms"]
        );
        formDataBody.append(
            `${prefix}MAX_NUM_FORMS`,
            formsetInfo["maxNumForms"]
        );

        // Adicionando os dados de cada um dos forms deste formset
        let i = 0;
        for (let form of formsetInfo["content"]) {
            // Para cada form do formset
            for (let field in form) {
                // Para cada campo do form, adicione-o ao formDataBody
                let value = form[field];
                // Se o campo em questão for um dict, queremos na verdade o valor desse dict
                if (value && value.constructor === Object) value = value.value;
                // if (field === "main_interpreter") {
                //     formDataBody.append(
                //         `${prefix}${i}-${field}`,
                //         value.map((artists) => artists.value)
                //     );
                // }
                if (
                    field === "primary_artists" ||
                    field === "featuring_artists"
                ) {
                    for (let artistId of form[field]) {
                        formDataBody.append(
                            `${prefix}${i}-${field}`,
                            artistId.value
                        );
                    }
                } else {
                    formDataBody.append(`${prefix}${i}-${field}`, value);
                }
            }
            i++;
        }
    }
}
