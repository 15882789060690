import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import clsx from "clsx";
import { checkIsActive, KTSVG } from "../../../../_metronic/helpers";
import { useThemeMode } from "../../theme-mode/ThemeModeProvider";
import "./MenuItem.css";

type Props = {
    to: string;
    title: string;
    id: string;
    icon?: string;
    fontIcon?: string;
    className?: string;
    hasArrow?: boolean;
    hasBullet?: boolean;
};

const MenuItem: React.FC<Props> = ({
    to,
    title,
    icon,
    fontIcon,
    hasArrow = false,
    hasBullet = false,
    className,
    id,
}) => {
    const { pathname } = useLocation();
    const { mode } = useThemeMode();
    const [classColor, setClassColor] = useState("");

    /**
     * Efeito para manipular a cor do texto com base na largura da janela e no modo de visualização.
     *
     * Este efeito monitora a largura da janela do navegador e ajusta a cor do texto com base no modo de visualização
     * e no tamanho da janela. Quando o modo é "light" (claro) e a largura da janela é maior que 992 pixels, a cor
     * do texto é definida como branco ("text-white"). Caso contrário, a cor do texto é definida como escura ("text-dark").
     *
     * Foi preciso fazer isso pois no mobile o menu abre em um drawer, as fontes precisam ser brancas, mas no mobile, o drawer (no tema claro)
     * fica branco, e as fontes precisam ser pretas
     *
     * @param {string} mode - O modo de visualização atual, que determina a cor do texto.
     * @param {string} classColor - A classe de cor do texto a ser definida com base nas condições.
     *
     * @returns {void}
     */

    useEffect(() => {
        function handleResize() {
            if (mode === "light" && window.innerWidth > 992) {
                setClassColor("text-white");
            } else {
                setClassColor("text-dark");
            }
        }
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [mode]);

    return (
        <div className={`menu-item me-lg-1 ${className}`} id={id}>
            <Link
                className={clsx("menu-link py-5", {
                    active: checkIsActive(pathname, to),
                })}
                to={to}
            >
                {hasBullet && (
                    <span className="menu-bullet">
                        <span className="bullet bullet-dot"></span>
                    </span>
                )}

                {icon && (
                    <span className="menu-icon">
                        <KTSVG path={icon} className="svg-icon-2" />
                    </span>
                )}

                {fontIcon && (
                    <span className="menu-icon">
                        <i className={clsx("bi fs-3", fontIcon)}></i>
                    </span>
                )}
                {/* aqui aumenta o espacamento do menu */}
                <span className={`menu-title py-2 ${mode}-menu-title`}>
                    {title}
                </span>

                {hasArrow && <span className="menu-arrow"></span>}
            </Link>
        </div>
    );
};

export { MenuItem };
