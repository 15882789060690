import React from "react";
import { Input } from "reactstrap";
import FormField from "../../FormField";
import { useTranslate } from "../../../../../../../helpers/i18n/useTranslate";

const FormImageInput = React.forwardRef(
    (
        {
            className,
            label,
            fieldId,
            fieldName,
            validationError,
            required,
            helpText,
            onChange,
            currentFile,
            currentFileThumb,
            filename,
            removeFile,
            disabled = false,
        },
        ref
    ) => {
        const { t } = useTranslate();
        return (
            <FormField
                className={className}
                label={label}
                fieldName={fieldName}
                validationError={validationError}
                helpText={helpText}
                required={required}
                inputComponent={
                    <>
                        {currentFile && (
                            <div>
                                {t("APPONI.LABELFORM.current")}:
                                {currentFileThumb ? (
                                    <img
                                        className="rounded mw-100"
                                        src={currentFile}
                                        style={{ maxHeight: "100px" }}
                                        alt="Thumbnail"
                                    />
                                ) : (
                                    <a
                                        href={currentFile}
                                        target="_blank"
                                        id={fieldId || fieldName}
                                        ref={ref}
                                    >
                                        {filename}
                                    </a>
                                )}
                                <br />
                                {removeFile && (
                                    <a
                                        href=""
                                        onClick={removeFile}
                                        style={{ color: "red" }}
                                    >
                                        {t("APPONI.LABELFORM.remove")}
                                    </a>
                                )}
                                <br />
                                {t("APPONI.LABELFORM.modify")}:
                            </div>
                        )}
                        <Input
                            type="file"
                            name={fieldName}
                            id={fieldId || fieldName}
                            className={validationError && "input-error"}
                            onChange={onChange}
                            disabled={disabled}
                            required={required}
                            accept="image/png, image/jpeg, image/jpg"
                        />
                    </>
                }
            />
        );
    }
);

export default FormImageInput;
