import { observer } from "mobx-react-lite";
import { PageTitle } from "../../layout/core";
import React, { useEffect } from "react";
import { ImageWidget } from "./ImageWidget";
import { useApi } from "../../helpers/Api/ApiWrapper";
import { useAuth } from "../auth";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import { Link } from "react-router-dom";
import { useCreateButton } from "../../helpers/subHeaderButtons/createButtonProvider";

export default function FeedPage() {
    const { setHref } = useCreateButton();
    const { GET } = useApi();
    const { currentUser } = useAuth();
    const { t } = useTranslate();

    const [feed, setFeed] = React.useState(null);

    /**
     * Pega os posts
     * @returns {Promise<void>}
     */
    const fetchFeed = async () => {
        const response = await GET("/ds/instagram-posts");
        let data = response.data;
        let apiFeed = data.map((item) => {
            return {
                type: item.type,
                data: item,
            };
        });
        setFeed(apiFeed);
    };

    /**
     * Roda a funcao de pegar os posts quando a página carrega
     */
    useEffect(() => {
        fetchFeed();
        setHref("");
    }, []);

    return (
        <>
            <PageTitle description={t("INTRO.FEEDSOCIAL")} breadcrumbs={[]}>
                APPS.DATASCIENCE.FEED
            </PageTitle>
            {/*If the current artist has no facebook long lived token*/}
            {currentUser.facebookToken ? (
                <>
                    <div
                        className="flex flex-wrap gap-3 justify-center"
                        id={"trem"}
                    >
                        {feed && feed.length > 0 ? (
                            <>
                                {feed.map((item, index) => {
                                    return (
                                        <ImageWidget
                                            key={index}
                                            type={item.type}
                                            data={item.data}
                                        />
                                    );
                                })}
                            </>
                        ) : (
                            <div className="flex justify-center align-items-center">
                                <span className="text-slate-50 font-semibold text-xl">
                                    {t("APPS.DATASCIENCE.FEED.SEARCHING")}
                                </span>
                            </div>
                        )}
                    </div>
                </>
            ) : (
                //If the current artist has no facebook long lived tokenq
                <div className="flex justify-content-center items-center">
                    <Link to="/core/profile/settings">
                        <button className="btn btn-primary">
                            {t("BUTTON.FACEBOOKTOKEN.REDIRECT.CONFIG")}{" "}
                        </button>
                    </Link>
                </div>
            )}
        </>
    );
}
