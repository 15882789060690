/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { Dropdown1 } from "../../../_metronic/partials";
import { useAuth } from "../auth";
import {
    faHeart,
    faComment,
    faChartSimple,
} from "@fortawesome/pro-thin-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import {
    faBookmark,
    faChartLineUp,
    faClock,
    faCommentAlt,
    faEye,
    faShareNodes,
    faThumbsUp,
} from "@fortawesome/pro-light-svg-icons";

// @ts-ignore
export const ImageWidget = ({ type, data }) => {
    const { currentUser, currentArtist, logout } = useAuth();
    const [ratingNumber, setRatingNumber] = useState(null);
    const [buttonClassRating, setButtonClassRating] = useState("");
    const { t } = useTranslate();

    /**
     * Formata uma data fornecida no formato 'YYYY-MM-DD' ou objeto Date para o formato 'DD/MM/YYYY'.
     *
     * @param {string | Date} dateToFormat - A data a ser formatada, pode ser uma string no formato 'YYYY-MM-DD' ou um objeto Date.
     * @returns {string} - A data formatada no formato 'DD/MM/YYYY'.
     */
    const formatDate = (dateToFormat) => {
        const dateObj = new Date(dateToFormat);
        const month = dateObj.toLocaleString("pt-BR", { month: "2-digit" });
        const day = dateObj.toLocaleString("pt-BR", { day: "2-digit" });
        const year = dateObj.toLocaleString("pt-BR", { year: "numeric" });
        return `${day}/${month}/${year}`;
    };

    /**
     * Formata um número fornecido, utilizando a notação de abreviação para milhões (M) e milhares (K).
     * Se o número for maior ou igual a 1 milhão, será formatado como 'X.XM'.
     * Se o número for maior ou igual a 1 mil, será formatado como 'X.XK'.
     * Caso contrário, o número será retornado sem formatação.
     *
     * @param {number} numberToFormat - O número a ser formatado.
     * @returns {string | number} - O número formatado com a notação de abreviação ou o próprio número se não aplicável.
     */
    const formatNumberWithAbbreviation = (numberToFormat) => {
        if (!numberToFormat || numberToFormat === undefined) return 0;
        if (numberToFormat >= 1000000) {
            return `${(numberToFormat / 1000000).toFixed(1)}M`;
        }
        if (numberToFormat >= 1000) {
            return `${(numberToFormat / 1000).toFixed(1)}K`;
        }
        return numberToFormat;
    };

    /**
     * Atualiza o estado do componente com base no valor de classificação obtido de um objeto de dados.
     * Formata o número para duas casas decimais e o atribui ao estado 'ratingNumber'.
     * Determina uma classe de botão com base na classificação formatada, aplicando uma classe de sucesso
     * se a classificação for maior ou igual a 1 e uma classe de perigo caso contrário.
     *
     * @param {Object} data - O objeto de dados contendo a propriedade 'post_rating'.
     * @returns {void}
     */
    useEffect(() => {
        const numeroDoJson = data.post_rating;
        const numeroFormatado = parseFloat(numeroDoJson.toFixed(2));
        setRatingNumber(numeroFormatado);

        const buttonClassRating =
            numeroFormatado >= 1
                ? "btn-active-light-success"
                : "btn-active-light-danger";
        setButtonClassRating(buttonClassRating);
    }, [data.post_rating]);

    return (
        <div className="card w-full md:max-w-[49%] lg:max-w-[32%] xl:max-w-[24%]">
            {/* begin::Body */}
            <div className="card-body pb-0">
                {/* begin::Header */}
                <div className="d-flex align-items-center mb-5">
                    {/* begin::User */}
                    <div className="d-flex align-items-center flex-grow-1">
                        {/* begin::Avatar */}
                        <div className="symbol symbol-50px me-5">
                            {currentUser ? (
                                currentUser["photo"] ? (
                                    <img
                                        alt="User profile photo"
                                        src={currentUser["photo"]}
                                    />
                                ) : (
                                    <div className="symbol-label fs-2 fw-bold bg-info text-inverse-info">
                                        {currentUser
                                            ? currentUser["first_name"]
                                                ? currentUser["first_name"][0]
                                                : currentUser["email"][0]
                                            : "USR"}
                                    </div>
                                )
                            ) : null}
                        </div>
                        {/* end::Avatar */}

                        {/* begin::Info */}
                        <div className="d-flex flex-column">
                            <a
                                href="#"
                                className="text-gray-800 text-hover-primary fs-6 fw-bold"
                            >
                                {currentUser
                                    ? currentUser["first_name"]
                                    : "User"}
                            </a>

                            <span className="text-gray-400 fw-semibold">
                                {formatDate(data.post_date)}
                            </span>
                        </div>
                        {/* end::Info */}
                    </div>
                    {/* end::User */}

                    {/* begin::Menu */}
                    <div className="my-0">
                        <div>
                            <a
                                href="#"
                                id="rating"
                                className={`btn btn-sm btn-light btn-color-muted ${buttonClassRating} px-4 py-2`}
                            >
                                <Tooltip
                                    anchorSelect="#rating"
                                    style={{ zIndex: 1000 }}
                                    id="rating"
                                >
                                    {t("APPS.DATASCIENCE.TAGS.RATING")}
                                </Tooltip>
                                {ratingNumber}x
                            </a>
                        </div>
                    </div>
                    {/* end::Menu */}
                </div>
                {/* end::Header */}

                {/* begin::Post */}
                <div className="mb-5 flex flex-col items-center">
                    {/* begin::Image */}
                    <img
                        alt={data.caption}
                        src={data.image}
                        className="bg-contain bg-no-repeat bg-center w-[290px] h-[290px] object-cover rounded-lg"
                    ></img>
                    {/* end::Image */}

                    {/* begin::Text */}
                    <p className="text-gray-800 my-5 pt-2">{data.caption}</p>
                    {/* end::Text */}
                    {/* end::Toolbar */}
                    {/* end::Toolbar */}
                </div>
                {/* end::Post */}
            </div>

            <div className="card-footer">
                {/* begin::Toolbar */}
                {type === "VIDEO" ? (
                    <div className="flex justify-center gap-1 md:gap-2 mb-5 flex-wrap">
                        <a
                            href="#"
                            id="likes"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#likes"
                                style={{ zIndex: 1000 }}
                                id="likes"
                            >
                                {t("APPS.DATASCIENCE.TAGS.LIKES")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faThumbsUp}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.likes)}
                        </a>
                        <a
                            href="#"
                            id="comments"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#comments"
                                style={{ zIndex: 1000 }}
                                id="comments"
                            >
                                {t("APPS.DATASCIENCE.TAGS.COMMENTS")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faCommentAlt}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.comments)}
                        </a>
                        <a
                            href="#"
                            id="totalInterection"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#totalInterection"
                                style={{ zIndex: 1000 }}
                                id="totalInterection"
                            >
                                {t("APPS.DATASCIENCE.TAGS.TOTAL-INTERECTIONS")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faChartSimple}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(
                                data.total_interactions
                            )}
                        </a>
                        <a
                            href="#"
                            id="postShare"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#postShare"
                                style={{ zIndex: 1000 }}
                                id="postShare"
                            >
                                {t("APPS.DATASCIENCE.TAGS.POST_SHARES")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faShareNodes}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.post_shares)}
                        </a>
                        <a
                            href="#"
                            id="savesPost"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#savesPost"
                                style={{ zIndex: 1000 }}
                                id="savesPost"
                            >
                                {t("APPS.DATASCIENCE.TAGS.POST_SAVES")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faBookmark}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.post_saves)}
                        </a>
                        <a
                            href="#"
                            id="igReelsAvgWatchTime"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#igReelsAvgWatchTime"
                                style={{ zIndex: 1000 }}
                                id="igReelsAvgWatchTime"
                            >
                                {t(
                                    "APPS.DATASCIENCE.TAGS.IG_REELS_AVG_WATCH_TIME"
                                )}
                            </Tooltip>
                            <FontAwesomeIcon icon={faEye} className="mr-1" />
                            {formatNumberWithAbbreviation(
                                data.ig_reels_avg_watch_time
                            )}
                        </a>
                        <a
                            href="#"
                            id="igReelsVideoViewTotalTime"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#igReelsVideoViewTotalTime"
                                style={{ zIndex: 1000 }}
                                id="igReelsVideoViewTotalTime"
                            >
                                {t(
                                    "APPS.DATASCIENCE.TAGS.IG_REELS_VIDEO_VIEW_TOTAL_TIME"
                                )}
                            </Tooltip>
                            <FontAwesomeIcon icon={faEye} className="mr-1" />
                            {formatNumberWithAbbreviation(
                                data.ig_reels_video_view_total_time
                            )}
                        </a>
                        <a
                            href="#"
                            id="igReelsPlay"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#igReelsPlay"
                                style={{ zIndex: 1000 }}
                                id="igReelsPlay"
                            >
                                {t("APPS.DATASCIENCE.TAGS.IG_REELS_PLAYS")}
                            </Tooltip>
                            <FontAwesomeIcon icon={faClock} className="mr-1" />
                            {formatNumberWithAbbreviation(data.ig_reels_plays)}
                        </a>
                    </div>
                ) : null}
                {type === "IMAGE" ? (
                    <div className="flex justify-center gap-1 md:gap-2 mb-5 flex-wrap">
                        <a
                            href="#"
                            id="comments"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#comments"
                                style={{ zIndex: 1000 }}
                                id="comments"
                            >
                                {t("APPS.DATASCIENCE.TAGS.COMMENTS")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faComment}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.comments)}
                        </a>

                        <a
                            href="#"
                            id="likes"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-danger px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#likes"
                                style={{ zIndex: 1000 }}
                                id="likes"
                            >
                                {t("APPS.DATASCIENCE.TAGS.LIKES")}
                            </Tooltip>
                            <FontAwesomeIcon icon={faHeart} className="mr-1" />
                            {formatNumberWithAbbreviation(data.likes)}
                        </a>

                        <a
                            href="#"
                            id="reachImage"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#reachImage"
                                style={{ zIndex: 1000 }}
                                id="reachImage"
                            >
                                {t("APPS.DATASCIENCE.TAGS.REACHIMAGE")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faChartLineUp}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.reach)}
                        </a>
                        <a
                            href="#"
                            id="totalInterections"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#totalInterections"
                                style={{ zIndex: 1000 }}
                                id="totalInterections"
                            >
                                {t("APPS.DATASCIENCE.TAGS.TOTAL-INTERECTIONS")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faChartSimple}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(
                                data.total_interactions
                            )}
                        </a>
                        <a
                            href="#"
                            id="impressions"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#impressions"
                                style={{ zIndex: 1000 }}
                                id="impressions"
                            >
                                {t("APPS.DATASCIENCE.TAGS.IMPRESSIONS")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faChartSimple}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.impressions)}
                        </a>
                        <a
                            href="#"
                            id="savesPost"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#savesPost"
                                style={{ zIndex: 1000 }}
                                id="savesPost"
                            >
                                {t("APPS.DATASCIENCE.TAGS.POST_SAVES")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faChartSimple}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.post_saves)}
                        </a>
                    </div>
                ) : null}
                {type === "CAROUSEL_ALBUM" ? (
                    <div className="flex justify-center gap-1 md:gap-2 mb-5 flex-wrap">
                        <a
                            href="#"
                            id="totalInterection"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-success px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#totalInterection"
                                style={{ zIndex: 1000 }}
                                id="totalInterection"
                            >
                                {t("APPS.DATASCIENCE.TAGS.TOTAL-INTERECTIONS")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faChartSimple}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(
                                data.total_interactions
                            )}
                        </a>
                        <a
                            href="#"
                            id="reachCarrosel"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#reachCarrosel"
                                style={{ zIndex: 1000 }}
                                id="reachCarrosel"
                            >
                                Alcance
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faChartLineUp}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.reach)}
                        </a>
                        <a
                            href="#"
                            id="impressions"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#impressions"
                                style={{ zIndex: 1000 }}
                                id="impressions"
                            >
                                {t("APPS.DATASCIENCE.TAGS.IMPRESSIONS")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faChartSimple}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.impressions)}
                        </a>
                        <a
                            href="#"
                            id="savesPost"
                            className="btn btn-sm btn-light btn-color-muted btn-active-light-primary px-4 py-2"
                        >
                            <Tooltip
                                anchorSelect="#savesPost"
                                style={{ zIndex: 1000 }}
                                id="savesPost"
                            >
                                {t("APPS.DATASCIENCE.TAGS.POST_SAVES")}
                            </Tooltip>
                            <FontAwesomeIcon
                                icon={faBookmark}
                                className="mr-1"
                            />
                            {formatNumberWithAbbreviation(data.post_saves)}
                        </a>
                    </div>
                ) : null}
            </div>
            {/* end::Body */}
        </div>
    );
};
