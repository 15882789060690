import React, { useEffect, useState } from "react";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { useAuth } from "../../auth";
import { Link } from "react-router-dom";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompactDisc, faLightbulb } from "@fortawesome/pro-light-svg-icons";
import {
    calculateTimeUntil,
    dateStrToHtml,
    isFutureDate,
} from "../../../helpers/usefulFunctions";
import { Card, CardBody } from "next2";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

const CountdownProjects = ({ className }) => {
    const { t } = useTranslate();
    const { currentArtist } = useAuth();
    const { GET } = useApi();
    const [isLoading, setIsLoading] = useState(true); // Spinner de loading para mostrar antes de carregar os dados do card
    const [nextRelease, setNextRelease] = useState(null);
    const [nextReleaseTimeString, setNextReleaseTimeString] = useState("");

    let interval; // Tem que declarar o interval num contexto global pra conseguir startar e limpar ele

    /**
     * Encontra a data futura mais próxima dentre uma lista de datas
     * @param {Array} dateList lista de datas no formato dd/mm/aaaa
     * @returns {*|null} A data mais próxima, ou null
     */
    function findClosestFutureDate(dateList) {
        // Filtra apenas as datas futuras
        const futureDates = dateList.filter((dateString) =>
            isFutureDate(dateString)
        );

        // Se não houver datas futuras, retorna null
        if (futureDates.length === 0) {
            return null;
        }

        // Ordena as datas futuras em ordem crescente
        futureDates.sort((a, b) => {
            const dateA = new Date(a.split("/").reverse().join("/"));
            const dateB = new Date(b.split("/").reverse().join("/"));
            return dateA - dateB;
        });

        // A primeira data na lista ordenada é a mais próxima
        return futureDates[0];
    }

    /**
     * Pega os dados do próximo lançamento pra colocar no card da dashboard
     * @returns {Promise<void>}
     *
     */
    async function setLatestProject() {
        try {
            const response = await GET("/core/releases");
            let projects = response.data.results;
            const nextReleaseDate = findClosestFutureDate(
                projects.map((p) => p.release_date)
            );
            // Encontra o próximo lançamento, se houver
            if (nextReleaseDate) {
                const tempNextRelease = projects.filter(
                    (p) => p.release_date === nextReleaseDate
                );
                setNextRelease(tempNextRelease[0]);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }

    /**
     * Pega o próximo lançamento quando a pagina carrega
     */
    useEffect(() => {
        setLatestProject();
    }, []);

    /**
     * Faz um furdunço quando chega o momento de lancamento
     */
    const celebrateReleaseArrival = () => {
        clearInterval(interval.current); // Por enquanto só limpa o contador mesmo. todo fazer o furdunço
    };

    /**
     * Temporizador que reduz automaticamente a quantidade de tempo para o próximo lançamento
     */
    const startTimer = () => {
        interval = setInterval(() => {
            const { days, hours, minutes, seconds } = calculateTimeUntil(
                nextRelease.release_date,
                nextRelease.release_time || "00:00:00"
            );
            setNextReleaseTimeString(
                t("APPS.CATALOG.PROJECT.COUNTDOWN.DESC")
                    .replace("$DAYS$", days)
                    .replace("$HOURS$", hours)
                    .replace("$MINUTES$", minutes)
                    .replace("$SECONDS$", seconds)
            );
            // Limpa o interval quando o contador chega a zero
            if (Math.max(days, hours, minutes, seconds) === 0)
                celebrateReleaseArrival();
        }, [1000]); // Atualiza a cada segundo
    };

    /**
     *  Esse UseEffect inicia o contador automatico até o próximo lançamento
     */
    useEffect(() => {
        if (!nextRelease) return;
        startTimer();
    }, [nextRelease]);

    return (
        <>
            {currentArtist ? (
                <>
                    {isLoading ? (
                        // Spinner de Loading
                        <LoadingSpinner />
                    ) : (
                        <>
                            <Card
                                className={`p-0 h-full bg-body ${className}`}
                                style={{
                                    backgroundImage: `url(${nextRelease?.cover_thumb})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                }}
                            >
                                <CardBody className="p-0">
                                    <div
                                        className="p-8 h-full backdrop-blur-sm flex flex-col justify-center"
                                    >
                                        <p
                                            className={`font-semibold text-white text-center`}
                                        >
                                            {nextRelease
                                                ? t(
                                                      "APPS.CATALOG.PROJECT.COUNTDOWN"
                                                  ).replace(
                                                      "$TIME$",
                                                      nextReleaseTimeString
                                                  )
                                                : t(
                                                      "APPS.CATALOG.PROJECT.COUNTDOWN-NULL"
                                                  )}
                                        </p>
                                        <Link
                                                className="font-bold block text-sky-500 text-center"
                                                to={
                                                    nextRelease
                                                        ? `/projects/dt/${nextRelease.id}`
                                                        : "/projects/ed"
                                                }
                                            >
                                                {nextRelease
                                                    ? nextRelease.title
                                                    : t(
                                                          "APPS.CATALOG.PROJECT.COUNTDOWN-CTA"
                                                      )}
                                            </Link>
                                    </div>
                                </CardBody>
                            </Card>
                        </>
                    )}
                </>
            ) : (
                <Link to={"/projects"} className={"p-0"}>
                    <div
                        className={`card rounded ${className} card-xl-stretch d-flex justify-content-center align-items-center text-gray-800 text-hover-primary fw-bold`}
                    >
                        <FontAwesomeIcon icon={faCompactDisc} size={"2xl"} />
                        <span className="fs-3 mt-2">
                            {t("APPS.CORE.PROJECTS")}
                        </span>
                    </div>
                </Link>
            )}
        </>
    );
};

export { CountdownProjects };
