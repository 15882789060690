import React, { useEffect, useState } from "react";
import { ChartWidget } from "../ChartWidget";
import { useApi } from "../../../../helpers/Api/ApiWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dateToBrazilianFormat } from "../../../../helpers/usefulFunctions";

const DataChartWidget = ({
    className,
    chartColor,
    chartHeight,
    platform,
    icon,
    iconColor,
    parameter,
    title,
    description,
    label,
}) => {
    const [xAxis, setXAxis] = useState([]);
    const [yAxis, setYAxis] = useState([]);
    const [minValue, setMinValue] = useState(0);
    const [maxValue, setMaxValue] = useState(0);
    const { GET } = useApi();

    /**
     * Carrega os dados para renderizar o gráfico quando a tela carrega
     */
    useEffect(() => {
        getChartData();
    }, []);

    /**
     * Pega os pontos do gráfico no backend
     * @returns {Promise<*>}
     */
    const getChartData = async () => {
        try {
            const playlistReach = await GET(
                `/ds/data-for-chart?parameter=${parameter}&plataform=${platform}`
            );
            let chartPoints = playlistReach.data.results;
            let yAxisValues = [];
            let xAxisValues = [];
            chartPoints.forEach((point) => {
                yAxisValues.push(point.value);
                xAxisValues.push(dateToBrazilianFormat(point.date));
            });
            setYAxis(yAxisValues);
            setXAxis(xAxisValues);
        } catch (err) {
            console.error(err);
        }
    };

    /**
     * Define a escala do eixo Y do gráfico depois que todos os valores de Y são carregados
     */
    useEffect(() => {
        if (yAxis.length !== 0) {
            setMaxValue(Math.round(Math.max(...yAxis) * 1.03)); // Limiar superior
            setMinValue(Math.round(Math.min(...yAxis) * 0.97)); // Limiar inferior
        }
    }, [yAxis]);
    return (
        <ChartWidget
            icon={
                <FontAwesomeIcon
                    icon={icon}
                    color={iconColor}
                    size={"xl"}
                    className={"me-3"}
                />
            }
            title={title}
            description={description}
            label={label}
            chartColor={chartColor}
            chartHeight={chartHeight}
            className={className}
            minValue={minValue}
            maxValue={maxValue}
            xAxis={xAxis}
            yAxis={yAxis}
        />
    );
};

export { DataChartWidget };
