import { useApi } from "../../helpers/Api/ApiWrapper";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import { useAlert } from "../../helpers/utils/CustomAlert";
import React, { useEffect, useState } from "react";
import { useCreateButton } from "../../helpers/subHeaderButtons/createButtonProvider";
import { PageTitle } from "../../layout/core";
import { InfoForFields } from "../../components/InfoForFields";
import { CustomSelect } from "../../components/Form/CustomSelect";
import { toast } from "react-toastify";
import { useAuth } from "../auth";
import {
    formatNumberWithAbbreviation,
    insertOrRemoveFromArray,
    isNotEmpty,
    slugify,
} from "../../helpers/usefulFunctions";
import { useOnesheetFileUploader } from "../../helpers/Api/UploadOnesheetFileToBucket";
import { motion } from "framer-motion";
import { makeId } from "onipkg";
import FormWizard from "react-form-wizard-component";
import "react-form-wizard-component/dist/style.css";
import styles from "./CreateOnesheetWizard.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAt,
    faMicrophoneStand,
    faUser,
} from "@fortawesome/pro-light-svg-icons";
import {
    faInstagram,
    faSpotify,
    faTiktok,
    faXTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { makeFormDataBody } from "../../helpers/utils/formUtils";
import Warning from "../catalog/labels/AppOniComponents/Warning";
import { SelectArtistCard } from "../core/profile/components/HealthCheck/SelectArtistCard";
import { toAbsoluteUrl } from "../../helpers/utils/toAbsoluteUrl";
import YouTubeNumbersGif from "../../../_metronic/assets/gifs/os-yt-numbers.gif";
import SpotifyNumbersGif from "../../../_metronic/assets/gifs/os-spf-numbers.gif";

/**
 * Formulário de criacao de onesheet. Haverão algumas perguntas pra serem respondidas pelo artista, e ao final, a onesheet
 * será criada "automágicamente" com base no tipo que determinaremos a partir das respostas. Após a criação, redirecionaremos
 * o artista pra tela OnesheetForm pra ele poder mexer na onesheet recém criada dele
 * @returns {JSX.Element}
 * @constructor
 */
const OnesheetWizard = () => {
    const breadCrumbs = [
        {
            title: "APPS.ONESHEETS.TITLE",
            path: "/onesheet",
            isSeparator: false,
            isActive: false,
        },
    ];
    const initialData = {
        name: "",
        type: "INVI",
        artist: { label: undefined, value: undefined },
    };
    const { currentArtist } = useAuth();
    const { GET, POST } = useApi();
    const { t } = useTranslate();
    const { uploadOnesheetFileToBucket } = useOnesheetFileUploader();
    const { alert } = useAlert();
    const [onesheet, setOnesheet] = useState(initialData);
    const [artists, setArtists] = useState([]);
    const [topPlatform, setTopPlatform] = useState("");
    const [selectedPlatform, setSelectedPlatform] = useState("");
    const [hasData, setHasData] = useState(true);
    const [selectedNumbers, setSelectedNumbers] = useState([]);
    const [formSending, setFormSending] = useState(false);
    const [fieldErrors, setFieldErrors] = useState([]);
    const [loadingData, setLoadingData] = useState(false);
    const [loadingPhrase, setLoadingPhrase] = useState(0); // Indica qual frase de carregamento será mostrada na animação de criação de Onesheet
    const [artistDataNumbers, setArtistDataNumbers] = useState({
        spotifyPlaylistReach: null,
        spotifyStreams: null,
        spotifyFanbase: null,
        youtubeFanbase: null,
        youtubeViews: null,
        instagramFanbase: null,
        instagramLikes: null,
        tiktokFanbase: null,
        tiktokLikes: null,
        twitterFanbase: null,
        twitterLikes: null,
    });
    let loadingPhrasesInterval;
    const { setHref } = useCreateButton();
    setHref("");

    const onesheetElementValues = {
        INVI: [
            {
                // Cabecalho
                title: "$ARTIST$",
                subtitle: "",
                type: "onipages_emphasis",
                value: "",
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: true,
                order: 0,
            },
            {
                // Form de convites
                title: t("APPS.INVITES.FORM.TITLE").replace(
                    "$NAME",
                    "$ARTIST$"
                ),
                subtitle: t("APPS.INVITES.FORM.SUBTITLE"),
                type: "onipages_invite",
                value: "",
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: true,
                order: 1,
            },
            {
                // Último Lançamento
                title: t(
                    "FORMS.ONESHEET.SECTIONS.onipages_latest-release-title"
                ).replace("$NAME", "$ARTIST$"),
                subtitle: "$TITLE$",
                type: "onipages_latest-release",
                value: '""|$LAST_RELEASE$|""',
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: false,
                order: 2,
            },
            {
                // Números de Redes Sociais
                title: t("FORMS.ONESHEET.SECTIONS.onipages_featured-numbers"),
                subtitle: "",
                type: "onipages_featured-numbers",
                value: "",
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: false,
                order: 3,
            },
            {
                // Última Tour
                title: t("FORMS.ONESHEET.SECTIONS.onipages_last-tour"),
                subtitle: "",
                type: "onipages_last-tour",
                value: "",
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: false,
                order: 4,
            },
            {
                // Notícias
                title: t("FORMS.ONESHEET.SECTIONS.onipages_news"),
                subtitle: t(
                    "FORMS.ONESHEET.SECTIONS.onipages_news-subtitle"
                ).replace("$NAME", "$ARTIST$"),
                type: "onipages_news",
                value: "[]",
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: false,
                order: 5,
            },
            {
                // Últimos Lançamentos
                title: t("FORMS.ONESHEET.SECTIONS.onipages_latest-albums"),
                subtitle: t(
                    "FORMS.ONESHEET.SECTIONS.onipages_latest-albums-subtitle"
                ),
                type: "onipages_latest-albums",
                value: '[{"id":"imagesGrid-abcd","title":"","link":"","image":""}]',
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: false,
                order: 6,
            },
            {
                // Biografia
                title: t("FORMS.ONESHEET.SECTIONS.onipages_bio"),
                subtitle: t(
                    "FORMS.ONESHEET.SECTIONS.onipages_bio-subtitle"
                ).replace("$NAME", "$ARTIST$"),
                type: "onipages_bio",
                value: '""||""',
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: false,
                order: 7,
            },
            {
                // Galeria de Fotos
                title: t("FORMS.ONESHEET.SECTIONS.onipages_images-grid"),
                subtitle: t(
                    "FORMS.ONESHEET.SECTIONS.onipages_images-grid-subtitle"
                ).replace("$NAME", "$ARTIST$"),
                type: "onipages_images-grid",
                value: "[]",
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: false,
                order: 8,
            },
            {
                // Galeria de Vídeos
                title: t("FORMS.ONESHEET.SECTIONS.onipages_video-gallery"),
                subtitle: "",
                type: "onipages_video-gallery",
                value: "[]",
                background: "#000000",
                effect: "",
                font_color: "#FFF",
                secondary_color: "#3d3d3d",
                show: false,
                order: 9,
            },
        ],
    };

    /**
     * Retorna os lancamentos recentes pra montagem da Onesheet
     */
    const getLatestReleases = async () => {
        try {
            const response = await GET(
                `/core/artists/${onesheet.artist.value}`
            );
            return response.data.last_releases;
        } catch (e) {
            toast.error(t("GENERAL.ERROR-PAGE.TITLE"));
            return null;
        }
    };

    /**
     * Carrega os artistas disponíveis para o campo de artista
     * @returns {Promise<void>}
     */
    const getArtists = async () => {
        const artistsResponse = await GET("/core/artists");
        if (artistsResponse.status === 200) {
            let tempArtists = artistsResponse.data.map((artist) => ({
                label: artist.name,
                value: artist.id,
            }));
            setArtists(tempArtists);
        }
    };

    /**
     * Calcula qual é a melhor plataforma do artista com base nos números de spotify e youtube
     * @param {array} spotifyNumbers números relacionados ao spotify
     * @param {array} youtubeNumbers números relacionados ao youtube
     */
    const calculateBestPlatform = (spotifyNumbers, youtubeNumbers) => {
        return spotifyNumbers.reduce((partialSum, a) => partialSum + a, 0) >=
            youtubeNumbers.reduce((partialSum, a) => partialSum + a, 0)
            ? "Spotify"
            : "YouTube";
    };

    /**
     * Pega os números de redes sociais do artista da onesheet pra montar o wizard personalizado
     * @returns {Promise<void>}
     */
    const getArtistNumbers = async () => {
        setLoadingData(true);
        try {
            let spotifyPlaylistReachResponse = await GET(
                `/ds/data-for-chart?parameter=playlist_reach`
            );
            let spotifyStreamsResponse = await GET(
                `/ds/data-for-chart?parameter=streams`
            );
            let spotifyFanbaseResponse = await GET(
                `/ds/data-for-chart?parameter=fanbase`
            );

            let youtubeFanbaseResponse = await GET(
                `/ds/data-for-chart?parameter=youtube_fanbase&plataform=youtube`
            );
            let youtubeViewsResponse = await GET(
                `/ds/data-for-chart?parameter=youtube_views&plataform=youtube`
            );

            let instagramFanbaseResponse = await GET(
                `/ds/data-for-chart?parameter=instagram_fanbase&plataform=meta`
            );
            let instagramLikesResponse = await GET(
                `/ds/data-for-chart?parameter=instagram_likes&plataform=meta`
            );

            let tiktokFanbaseResponse = await GET(
                `/ds/data-for-chart?parameter=tiktok_fanbase&plataform=tiktok`
            );
            let tiktokLikesResponse = await GET(
                `/ds/data-for-chart?parameter=tiktok_likes&plataform=tiktok`
            );

            let twitterFanbaseResponse = await GET(
                `/ds/data-for-chart?parameter=twitter_fanbase&plataform=twitter`
            );
            let twitterLikesResponse = await GET(
                `/ds/data-for-chart?parameter=twitter_likes&plataform=twitter`
            );
            let numbersDict = {
                spotify_playlist_reach:
                    spotifyPlaylistReachResponse.data.values.at(-1),
                spotify_streams: spotifyStreamsResponse.data.values.at(-1),
                spotify_fanbase: spotifyFanbaseResponse.data.values.at(-1),
                youtube_fanbase: youtubeFanbaseResponse.data.values.at(-1),
                youtube_views: youtubeViewsResponse.data.values.at(-1),
                instagram_fanbase: instagramFanbaseResponse.data.values.at(-1),
                instagram_likes: instagramLikesResponse.data.values.at(-1),
                tiktok_fanbase: tiktokFanbaseResponse.data.values.at(-1),
                tiktok_likes: tiktokLikesResponse.data.values.at(-1),
                twitter_fanbase: twitterFanbaseResponse.data.values.at(-1),
                twitter_likes: twitterLikesResponse.data.values.at(-1),
            };
            setArtistDataNumbers(numbersDict);
            // Verificando se o maior valor é maior que 0. Se o maior valor for 0, o artista ainda não tem dados
            const numericValues = Object.values(numbersDict).filter(
                (value) => typeof value === "number" && !isNaN(value)
            );
            const maxNumericValue =
                numericValues.length > 0 ? Math.max(...numericValues) : 0;
            setHasData(!Boolean(maxNumericValue === 0 && onesheet.artist));

            let biggerNumbersPlatform = calculateBestPlatform(
                [
                    // numbersDict.spotify_playlist_reach, // NAO CONSIDERA PLAYLIST REACH NA SOMA
                    numbersDict.spotify_streams,
                    numbersDict.spotify_fanbase,
                ],
                [numbersDict.youtube_fanbase, numbersDict.youtube_views]
            );
            setTopPlatform(biggerNumbersPlatform);
            setSelectedPlatform(
                biggerNumbersPlatform === "Spotify"
                    ? "SP"
                    : biggerNumbersPlatform === "YouTube"
                    ? "YT"
                    : ""
            );
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingData(false);
        }
    };

    /**
     * Atualiza o artista da onesheet quando o currentArtist muda e, pega os números dele
     */
    useEffect(() => {
        if (currentArtist?.id) getArtistNumbers();
        setOnesheet({
            ...onesheet,
            artist: { value: currentArtist?.id, label: currentArtist?.name },
        });
    }, [currentArtist?.id]);

    /**
     * Handler de edição de dados
     * @param evt
     */
    const handleChange = (evt) => {
        let field = evt.target.name;
        let value = evt.target.value;
        setOnesheet({
            ...onesheet,
            [field]: value,
        });
        let newFieldErrors = [...fieldErrors];
        newFieldErrors.splice(newFieldErrors.indexOf(field), 1);
        setFieldErrors(newFieldErrors);
    };

    /**
     * Valida as seções do wizard. Temos que garantir que o formulário tem nome e artista
     */
    const checkValidateForm = () => {
        let newFieldErrors = [...fieldErrors];
        if (!isNotEmpty(onesheet.artist) && !newFieldErrors.includes("artist"))
            newFieldErrors.push("artist");
        if (!isNotEmpty(onesheet.name) && !newFieldErrors.includes("name"))
            newFieldErrors.push("name");
        if (fieldErrors.length !== newFieldErrors.length)
            setFieldErrors(newFieldErrors);
    };

    /**
     * Mostra mensagem de erro ao tentar submeter uma seção do wizard
     */
    const errorMessages = () => {
        toast.error(t("APPS.ONESHEET.WIZARD.ERROR-MSG"));
    };

    /**
     * Handler de submissao do formulário
     * @param e
     */
    const handleComplete = async () => {
        setFormSending(true);
        // Inicia a animação de criação
        loadingPhrasesInterval = setInterval(() => {
            setLoadingPhrase(Math.floor(Math.random() * 11));
        }, 5000); // 5000 pra mudar a frase a cada 1sec
        // Se não tiver onesheet.artist é pq tá no modo gravadora e o cliente não selecionou nenhum artista
        if (!onesheet.artist) {
            toast.error(t("FORMS.ONESHEET.SELECT-ARTIST"));
            setFieldErrors(["artist"]);
            return;
        }
        let elementContents = [...onesheetElementValues[onesheet.type]];
        const releases = await getLatestReleases();
        const lastRelease = releases ? releases[0] : null;
        // Se o cara nao tiver lancamento, nao precisa ter os elementos onipages_latest-release e onipages_latest-albums
        if (releases?.length <= 0) {
            elementContents = elementContents.filter(
                (data) =>
                    ![
                        "onipages_latest-release",
                        "onipages_latest-albums",
                    ].includes(data.type)
            );
        }
        try {
            let formData = makeFormDataBody(onesheet);
            const response = await POST("marketing/onesheets", formData);
            // Se a response conter um id, criou os metadados. Agora vamos criar os elementos
            let createdOnesheetId = response.data.amplifyus_id;
            if (createdOnesheetId) {
                // Pra cada elemento nesse tipo de onesheet, devemos preencher cada um de seus valores com o conteúdo certo
                let elements = await Promise.all(
                    elementContents.map(async (data) => {
                        // Coloca a foto do último lçto no cabeçalho
                        if (data.type === "onipages_emphasis" && lastRelease) {
                            data.background = await uploadOnesheetFileToBucket(
                                lastRelease.cover_url,
                                `${slugify(lastRelease.title)}.png`,
                                `${
                                    process.env.REACT_APP_ENVIRONMENT
                                }/onesheet/${createdOnesheetId}/${makeId(
                                    7
                                )}/background/`,
                                true
                            );
                            // Coloca a foto do último lçto no elemento correspondente
                        } else if (
                            data.type === "onipages_latest-release" &&
                            lastRelease
                        ) {
                            let imageCover = await uploadOnesheetFileToBucket(
                                lastRelease.cover_url,
                                `${slugify(lastRelease.title)}.png`,
                                `${
                                    process.env.REACT_APP_ENVIRONMENT
                                }/onesheet/${createdOnesheetId}/${makeId(
                                    7
                                )}/background/`,
                                true
                            );
                            data.value = `${JSON.stringify({
                                dsp_deezer_id: lastRelease?.dsp_deezer_id,
                                dsp_itunes_id: lastRelease?.dsp_itunes_id,
                                dsp_spotify_id: lastRelease?.dsp_spotify_id,
                                dsp_youtube_id: lastRelease?.dsp_youtube_id,
                                dsp_youtube_music_id:
                                    lastRelease?.dsp_youtube_music_id,
                            })}|${imageCover}|${JSON.stringify("")}`;
                            data.subtitle = data.subtitle.replace(
                                "$TITLE$",
                                lastRelease?.title
                            );
                            data.show = true;
                            // Coloca as fotos os últimos álbums corretamente
                        } else if (
                            data.type === "onipages_latest-albums" &&
                            releases.length > 0
                        ) {
                            data.show = true;
                            let value = await Promise.all(
                                releases.map(async (rlse) => {
                                    let tabId = makeId(7);
                                    return {
                                        id: `imagesGrid-${tabId}`,
                                        title: rlse.title,
                                        image: await uploadOnesheetFileToBucket(
                                            rlse.cover_url,
                                            `${slugify(rlse.title)}.png`,
                                            `${process.env.REACT_APP_ENVIRONMENT}/onesheet/${createdOnesheetId}/${tabId}/background/`,
                                            true
                                        ),
                                        dsp_deezer_id: rlse.dsp_deezer_id,
                                        dsp_itunes_id: rlse.dsp_itunes_id,
                                        dsp_spotify_id: rlse.dsp_spotify_id,
                                        dsp_youtube_id: rlse.dsp_youtube_id,
                                        dsp_youtube_music_id:
                                            rlse.dsp_youtube_music_id,
                                    };
                                })
                            );
                            data.value = JSON.stringify(value);
                        } else if (
                            data.type === "onipages_featured-numbers" &&
                            hasData
                        ) {
                            data.show = true;
                            let socialData = Object.entries(
                                artistDataNumbers
                            ).filter(([label, value]) => {
                                return selectedNumbers.includes(label);
                            });
                            let socialDataDict = {}; // Essa variável vai ser um dict contendo os campos de números selecionados pelo artista, o valor do número e a flag de show
                            socialData.forEach((item) => {
                                const key = item[0];
                                socialDataDict[key] = item[1];
                                socialDataDict[`show_${key}`] = true;
                            });
                            socialDataDict = {
                                ...socialDataDict,
                                ...{
                                    spotify_streams:
                                        artistDataNumbers.spotify_streams,
                                    show_spotify_streams: true,
                                    spotify_fanbase:
                                        artistDataNumbers.spotify_fanbase,
                                    show_spotify_fanbase: true,
                                    spotify_playlist_reach:
                                        artistDataNumbers.spotify_playlist_reach,
                                    show_spotify_playlist_reach: true,
                                    youtube_views:
                                        artistDataNumbers.youtube_views,
                                    show_youtube_views: true,
                                    youtube_fanbase:
                                        artistDataNumbers.youtube_fanbase,
                                    show_youtube_fanbase: true,
                                },
                            };
                            let value = {
                                featured_social: selectedPlatform,
                                ...socialDataDict,
                            };
                            data.value = JSON.stringify([value]);
                        }
                        data.page = response.data.id;
                        data.title = data.title.replace(
                            "$ARTIST$",
                            onesheet.artist.label
                        );
                        data.subtitle = data.subtitle.replace(
                            "$ARTIST$",
                            onesheet.artist.label
                        );
                        data.value = data.value.replace(
                            "$ARTIST$",
                            onesheet.artist.label
                        );
                        return data;
                    })
                );
                let elementsFormData = new FormData();
                elementsFormData.append("elements", JSON.stringify(elements));
                await POST(
                    `marketing/onesheets/el/${createdOnesheetId}`,
                    elementsFormData
                );
                // Coloca a frase de "sucesso" na animação quando a onesheet termina de ser criada
                setLoadingPhrase(999);
                // Faz o redirecionamento para a onesheet criada após certo tempo automaticamente
                setTimeout(
                    () =>
                        window.location.replace(
                            `/onesheet/${createdOnesheetId}`
                        ),
                    3000 // Redireciona após 3sec
                );
                // Retira a animação
                clearInterval(loadingPhrasesInterval);
            } else {
                alert.fire({
                    title: t("GENERAL.ERROR-PAGE.SUBTITLE"),
                    text: Object.entries(response.data).map(
                        ([field, error]) => `-> ${field}: ${error}\n`
                    ),
                });
            }
        } catch (e) {
            // Se o status for 403, mostra pro usuário qual é o problema (normalmente é limite atingido no plano de assinatura)
            let errorMessage =
                e.response.status === 403
                    ? e.response.data.detail
                    : t("GENERAL.CONNECTION-ERROR");
            toast.error(errorMessage);
            console.error(e);
            setFormSending(false);
        }
    };

    return (
        <>
            <PageTitle breadcrumbs={breadCrumbs}>APPS.ONESHEETS.NEW</PageTitle>
            {currentArtist ? (
                <div className="card mb-5 mb-xl-10">
                    <FormWizard
                        shape="circle"
                        color="#0C09DB"
                        onComplete={handleComplete}
                        // onTabChange={tabChanged}
                        nextButtonTemplate={(handleNext) => (
                            <button
                                className={styles.baseButton}
                                onClick={(e) => {
                                    checkValidateForm();
                                    handleNext(e);
                                }}
                            >
                                {t("APPS.ONESHEET.WIZARD.BUTTONS.NEXT")}
                            </button>
                        )}
                        backButtonTemplate={(handlePrevious) => (
                            <button
                                className={styles.baseButton}
                                onClick={handlePrevious}
                            >
                                {t("APPS.ONESHEET.WIZARD.BUTTONS.PREV")}
                            </button>
                        )}
                        finishButtonTemplate={(handleComplete) => (
                            <button
                                className={styles.finishButton}
                                onClick={handleComplete}
                            >
                                {t("APPS.ONESHEET.WIZARD.BUTTONS.FINISH")}
                            </button>
                        )}
                    >
                        {/* PRIMEIRA SECAO DO WIZARD. DADOS GERAIS */}

                        <FormWizard.TabContent
                            title={
                                <span
                                    className={"text-white fw-semibold text-xl"}
                                >
                                    {t("APPS.ONESHEET.WIZARD.FIRST.LABEL")}
                                </span>
                            }
                            icon={<FontAwesomeIcon icon={faUser} />}
                        >
                            <div className="container">
                                {!hasData && (
                                    <div className="flex justify-center">
                                        <Warning
                                            title={t(
                                                "APPS.ONESHEET.WIZARD.NO-DATA.TITLE"
                                            )}
                                            description={t(
                                                "APPS.ONESHEET.WIZARD.NO-DATA.DESC"
                                            )}
                                        />
                                    </div>
                                )}
                                <div className="flex flex-column">
                                    <div className="mb-5 flex flex-column flex-start">
                                        <label
                                            htmlFor="name"
                                            className="required "
                                        >
                                            {t("FORMS.MARKETING.ONESHEET.NAME")}
                                            <InfoForFields
                                                id="name"
                                                text={t(
                                                    "FORMS.MARKETING.ONESHEET.NAME.INFO"
                                                )}
                                            />
                                        </label>
                                        <input
                                            id="name"
                                            type="text"
                                            name="name"
                                            className="form-control form-control-lg form-control-solid"
                                            onChange={handleChange}
                                            value={onesheet.name}
                                        />
                                        {fieldErrors.includes("name") ? (
                                            <div className="text-danger mt-2">
                                                {t(
                                                    "FORMS.MARKETING.ONESHEET.NAME.MANDATORY"
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                {!currentArtist && (
                                    <div className="flex flex-column flex-start">
                                        <label
                                            htmlFor="artist"
                                            className="required"
                                        >
                                            {t("FORMS.ONESHEET.SELECT-ARTIST")}
                                            <InfoForFields
                                                id="artist"
                                                text={t(
                                                    "FORMS.MARKETING.ONESHEET.ARTIST.INFO"
                                                )}
                                            />
                                        </label>
                                        <CustomSelect
                                            placeholder={t(
                                                "FORMS.GENERAL.PICK-ONE"
                                            )}
                                            className="w-100"
                                            options={artists}
                                            id="artist"
                                            name="artist"
                                            onChange={(selected) =>
                                                handleChange({
                                                    target: {
                                                        name: "artist",
                                                        value: selected,
                                                    },
                                                })
                                            }
                                            defaultValue={onesheet.artist.value}
                                        />
                                        {fieldErrors.includes("artist") ? (
                                            <div className="text-danger mt-2">
                                                {t(
                                                    "ERRORS.ONESHEET.SELECT-ARTIST"
                                                )}
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </div>
                        </FormWizard.TabContent>

                    {/* SEGUNDA SECAO DO WIZARD. PLATAFORMA MAIS TOPPERSON */}
                    <FormWizard.TabContent
                        title={
                            <span
                                className={"text-white font-semibold text-xl"}
                            >
                                {t("APPS.ONESHEET.WIZARD.SECOND.LABEL")}
                            </span>
                        }
                        icon={<FontAwesomeIcon icon={faMicrophoneStand} />}
                        isValid={fieldErrors.length === 0}
                        validationError={errorMessages}
                    >
                        <div className="flex flex-col content-center">
                            {loadingData ? (
                                <div>
                                    <LoadingSpinner size="2xl" />
                                    <span
                                        className={
                                            "text-white font-semibold text-xl"
                                        }
                                    >
                                        {t("APPS.ONESHEET.WIZARD.LOADING")}
                                    </span>
                                </div>
                            ) : hasData ? (
                                <>
                                    <span className="text-2xl font-bold">
                                        {t("APPS.ONESHEET.WIZARD.SECOND.TITLE")}{" "}
                                        {topPlatform}!
                                    </span>
                                    <span className="text-lg text-gray-600 mt-3">
                                        {t(
                                            "APPS.ONESHEET.WIZARD.SECOND.SUBTITLE"
                                        )}
                                    </span>
                                    <div className="flex justify-center gap-10">
                                        {/*  CARD COM DADOS DO YOUTUBE  */}
                                        <div className="mt-8 min-h-[260px]">
                                            <div
                                                onClick={() =>
                                                    setSelectedPlatform("YT")
                                                }
                                                className="w-[220px] h-full"
                                            >
                                                <div
                                                    className={`block h-full rounded-lg border shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ${
                                                        selectedPlatform ===
                                                            "YT" &&
                                                        styles.selectedYoutube
                                                    }`}
                                                >
                                                    <div className="flex justify-end mt-3 mr-3">
                                                        <InfoForFields
                                                            id="ytnumbers"
                                                            text="É assim que os números vão aparecer na página final"
                                                            extensiveContent={
                                                                <>
                                                                    <div className="h-[100px] w-[200px]">
                                                                        <img
                                                                            src={
                                                                                YouTubeNumbersGif
                                                                            }
                                                                            className=""
                                                                            alt="gif"
                                                                        />
                                                                        <p className="bg-white text-black p-2 mt-1">
                                                                            {t(
                                                                                "APPS.ONESHEET.WIZARD.GIF-DESCRIPTION"
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            }
                                                        />
                                                    </div>
                                                    <div className="border-b-2 border-neutral-100 border-opacity-10 px-6 py-3 text-center">
                                                        <span className="mb-4 text-xl text-gray-900 font-extrabold">
                                                            <FontAwesomeIcon
                                                                icon={faYoutube}
                                                                color={
                                                                    "#FF0000"
                                                                }
                                                                size={"xl"}
                                                                className={
                                                                    "mr-1"
                                                                }
                                                            />{" "}
                                                            {t(
                                                                "APPS.ONESHEET.WIZARD.YOUTUBE"
                                                            )}
                                                        </span>
                                                        {/*<h3 className="mb-6 text-3xl">*/}
                                                        {/*  <strong>$ 299</strong>*/}
                                                        {/*  <small className="text-sm text-neutral-500 dark:text-neutral-300">/year</small>*/}
                                                        {/*</h3>*/}

                                                        {/*<button type="button"*/}
                                                        {/*  className="inline-block w-full rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"*/}
                                                        {/*  data-te-ripple-init data-te-ripple-color="light">*/}
                                                        {/*  Buy*/}
                                                        {/*</button>*/}
                                                    </div>
                                                    <div className="p-6">
                                                        <ol className="list-inside">
                                                            <li className="mb-4">
                                                                <span className="mr-3 h-5 w-5 text-primary-400 font-extrabold text-xl">
                                                                    {" "}
                                                                    {formatNumberWithAbbreviation(
                                                                        artistDataNumbers.youtube_views
                                                                    )}{" "}
                                                                </span>
                                                                <span className="text-white font-semibold text-lg">
                                                                    {t(
                                                                        "APPS.ONESHEET.WIZARD.VIEWS"
                                                                    )}
                                                                </span>
                                                            </li>
                                                            <li className="mb-4">
                                                                <span className="mr-3 h-5 w-5 text-primary-400 font-extrabold text-xl">
                                                                    {" "}
                                                                    {formatNumberWithAbbreviation(
                                                                        artistDataNumbers.youtube_fanbase
                                                                    )}{" "}
                                                                </span>
                                                                <span className="text-white font-semibold text-lg">
                                                                    {t(
                                                                        "APPS.ONESHEET.WIZARD.YT-FOLLOWERS"
                                                                    )}
                                                                </span>
                                                            </li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*  CARD COM DADOS DO SPOTIFY  */}
                                        <div
                                            onClick={() =>
                                                setSelectedPlatform("SP")
                                            }
                                            className="mt-8 w-[220px]"
                                        >
                                            <div
                                                className={`block h-full rounded-lg border shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ${
                                                    selectedPlatform === "SP" &&
                                                    styles.selectedSpotify
                                                }`}
                                            >
                                                <div className="flex justify-end mt-3 mr-3">
                                                        <InfoForFields
                                                            id="spfnumbers"
                                                            place="top"
                                                            text="É assim que os números vão aparecer na página final"
                                                            extensiveContent={
                                                                <>
                                                                    <div className="h-[100px] w-[200px]">
                                                                        <img
                                                                            src={
                                                                                SpotifyNumbersGif
                                                                            }
                                                                            className=""
                                                                            alt="gif"
                                                                        />
                                                                        <p className="bg-white text-black p-2 mt-1">
                                                                            {t(
                                                                                "APPS.ONESHEET.WIZARD.GIF-DESCRIPTION"
                                                                            )}
                                                                        </p>
                                                                    </div>
                                                                </>
                                                            }
                                                        />
                                                    </div>
                                                <div className="border-b-2 border-neutral-100 border-opacity-10 px-6 py-3 text-center">
                                                    <span className="mb-4 text-xl text-gray-900 font-extrabold">
                                                        <FontAwesomeIcon
                                                            icon={faSpotify}
                                                            color={"#1DB954"}
                                                            size={"xl"}
                                                            className={"mr-1"}
                                                        />{" "}
                                                        {t(
                                                            "APPS.ONESHEET.WIZARD.SPOTIFY"
                                                        )}
                                                    </span>
                                                    {/*<h3 className="mb-6 text-3xl">*/}
                                                    {/*  <strong>$ 299</strong>*/}
                                                    {/*  <small className="text-sm text-neutral-500 dark:text-neutral-300">/year</small>*/}
                                                    {/*</h3>*/}

                                                        {/*<button type="button"*/}
                                                        {/*  className="inline-block w-full rounded bg-primary px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"*/}
                                                        {/*  data-te-ripple-init data-te-ripple-color="light">*/}
                                                        {/*  Buy*/}
                                                        {/*</button>*/}
                                                    </div>
                                                    <div className="p-6">
                                                        <ol className="list-inside">
                                                            <li className="mb-4">
                                                                <span className="mr-3 h-5 w-5 text-primary-400 font-extrabold text-xl">
                                                                    {" "}
                                                                    {formatNumberWithAbbreviation(
                                                                        artistDataNumbers.spotify_streams
                                                                    )}{" "}
                                                                </span>
                                                                <span className="text-white font-semibold text-lg">
                                                                    {t(
                                                                        "APPS.ONESHEET.WIZARD.STREAMS"
                                                                    )}
                                                                </span>
                                                            </li>
                                                            <li className="mb-4">
                                                                <span className="mr-3 h-5 w-5 text-primary-400 font-extrabold text-xl">
                                                                    {" "}
                                                                    {formatNumberWithAbbreviation(
                                                                        artistDataNumbers.spotify_fanbase
                                                                    )}{" "}
                                                                </span>
                                                                <span className="text-white font-semibold text-lg">
                                                                    {t(
                                                                        "APPS.ONESHEET.WIZARD.FOLLOWERS"
                                                                    )}
                                                                </span>
                                                            </li>
                                                            <li className="mb-4">
                                                                <span className="mr-3 h-5 w-5 text-primary-400 font-extrabold text-xl">
                                                                    {" "}
                                                                    {formatNumberWithAbbreviation(
                                                                        artistDataNumbers.spotify_playlist_reach
                                                                    )}{" "}
                                                                </span>
                                                                <span className="text-white font-semibold text-lg">
                                                                    {t(
                                                                        "APPS.ONESHEET.WIZARD.PLAYLIST-REACH"
                                                                    )}
                                                                </span>
                                                            </li>
                                                        </ol>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="flex justify-center">
                                        <Warning
                                            title={t(
                                                "APPS.ONESHEET.WIZARD.NO-DATA.TITLE"
                                            )}
                                            description={t(
                                                "APPS.ONESHEET.WIZARD.NO-DATA.DESC"
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        </FormWizard.TabContent>
                        {/* TERCEIRA SECAO DO WIZARD. NÚMEROS DE REDES SOCIAIS */}
                        <FormWizard.TabContent
                            title={
                                <span
                                    className={
                                        "text-white font-semibold text-xl"
                                    }
                                >
                                    {t("APPS.ONESHEET.WIZARD.THIRD.LABEL")}
                                </span>
                            }
                            icon={<FontAwesomeIcon icon={faAt} />}
                            // isValid={fieldErrors.length === 0}
                            // validationError={errorMessages}
                        >
                            <div className="flex flex-col content-center">
                                {loadingData ? (
                                    <div>
                                        <LoadingSpinner size="2xl" />
                                        <span
                                            className={
                                                "text-white font-semibold text-xl"
                                            }
                                        >
                                            {t("APPS.ONESHEET.WIZARD.LOADING")}
                                        </span>
                                    </div>
                                ) : hasData ? (
                                    <>
                                        <span className="text-2xl font-bold">
                                            {t(
                                                "APPS.ONESHEET.WIZARD.THIRD.TITLE"
                                            )}
                                        </span>
                                        <div className="flex justify-center gap-10 mt-6">
                                            {artistDataNumbers.instagram_fanbase && (
                                                <div
                                                    className={`cursor-pointer p-4 block h-full rounded-lg border shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ${
                                                        selectedNumbers.includes(
                                                            "instagram_fanbase"
                                                        ) && "bg-blue-800"
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedNumbers([
                                                            ...insertOrRemoveFromArray(
                                                                selectedNumbers,
                                                                "instagram_fanbase"
                                                            ),
                                                        ]);
                                                    }}
                                                >
                                                    <span className="mb-4 text-xl text-gray-900 font-extrabold">
                                                        <FontAwesomeIcon
                                                            icon={faInstagram}
                                                            color={"#C13584"}
                                                            size={"xl"}
                                                            className={"mr-1"}
                                                        />{" "}
                                                        {formatNumberWithAbbreviation(
                                                            artistDataNumbers.instagram_fanbase
                                                        )}{" "}
                                                        {t(
                                                            "APPS.ONESHEET.WIZARD.FOLLOWERS"
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            {artistDataNumbers.instagram_likes && (
                                                <div
                                                    className={`cursor-pointer p-4 block h-full rounded-lg border shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ${
                                                        selectedNumbers.includes(
                                                            "instagram_likes"
                                                        ) && "bg-blue-800"
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedNumbers([
                                                            ...insertOrRemoveFromArray(
                                                                selectedNumbers,
                                                                "instagram_likes"
                                                            ),
                                                        ]);
                                                    }}
                                                >
                                                    <span className="mb-4 text-xl text-gray-900 font-extrabold">
                                                        <FontAwesomeIcon
                                                            icon={faInstagram}
                                                            color={"#C13584"}
                                                            size={"xl"}
                                                            className={"mr-1"}
                                                        />{" "}
                                                        {formatNumberWithAbbreviation(
                                                            artistDataNumbers.instagram_likes
                                                        )}{" "}
                                                        {t(
                                                            "APPS.ONESHEET.WIZARD.LIKES"
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            {artistDataNumbers.twitter_fanbase && (
                                                <div
                                                    className={`cursor-pointer p-4 block h-full rounded-lg border shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ${
                                                        selectedNumbers.includes(
                                                            "twitter_fanbase"
                                                        ) && "bg-blue-800"
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedNumbers([
                                                            ...insertOrRemoveFromArray(
                                                                selectedNumbers,
                                                                "twitter_fanbase"
                                                            ),
                                                        ]);
                                                    }}
                                                >
                                                    <span className="mb-4 text-xl text-gray-900 font-extrabold">
                                                        <FontAwesomeIcon
                                                            icon={faXTwitter}
                                                            color={"#000"}
                                                            size={"xl"}
                                                            className={"mr-1"}
                                                        />{" "}
                                                        {formatNumberWithAbbreviation(
                                                            artistDataNumbers.twitter_fanbase
                                                        )}{" "}
                                                        {t(
                                                            "APPS.ONESHEET.WIZARD.FOLLOWERS"
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            {artistDataNumbers.twitter_likes && (
                                                <div
                                                    className={`cursor-pointer p-4 block h-full rounded-lg border shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ${
                                                        selectedNumbers.includes(
                                                            "twitter_likes"
                                                        ) && "bg-blue-800"
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedNumbers([
                                                            ...insertOrRemoveFromArray(
                                                                selectedNumbers,
                                                                "twitter_likes"
                                                            ),
                                                        ]);
                                                    }}
                                                >
                                                    <span className="mb-4 text-xl text-gray-900 font-extrabold">
                                                        <FontAwesomeIcon
                                                            icon={faXTwitter}
                                                            color={"#000"}
                                                            size={"xl"}
                                                            className={"mr-1"}
                                                        />{" "}
                                                        {formatNumberWithAbbreviation(
                                                            artistDataNumbers.twitter_likes
                                                        )}{" "}
                                                        {t(
                                                            "APPS.ONESHEET.WIZARD.LIKES"
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            {artistDataNumbers.tiktok_fanbase && (
                                                <div
                                                    className={`cursor-pointer p-4 block h-full rounded-lg border shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ${
                                                        selectedNumbers.includes(
                                                            "tiktok_fanbase"
                                                        ) && "bg-blue-800"
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedNumbers([
                                                            ...insertOrRemoveFromArray(
                                                                selectedNumbers,
                                                                "tiktok_fanbase"
                                                            ),
                                                        ]);
                                                    }}
                                                >
                                                    <span className="mb-4 text-xl text-gray-900 font-extrabold">
                                                        <FontAwesomeIcon
                                                            icon={faTiktok}
                                                            color={"#06F1EA"}
                                                            size={"xl"}
                                                            className={"mr-1"}
                                                        />{" "}
                                                        {formatNumberWithAbbreviation(
                                                            artistDataNumbers.tiktok_fanbase
                                                        )}{" "}
                                                        {t(
                                                            "APPS.ONESHEET.WIZARD.FOLLOWERS"
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                            {artistDataNumbers.tiktok_likes && (
                                                <div
                                                    className={`cursor-pointer p-4 block h-full rounded-lg border shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] ${
                                                        selectedNumbers.includes(
                                                            "tiktok_likes"
                                                        ) && "bg-blue-800"
                                                    }`}
                                                    onClick={() => {
                                                        setSelectedNumbers([
                                                            ...insertOrRemoveFromArray(
                                                                selectedNumbers,
                                                                "tiktok_likes"
                                                            ),
                                                        ]);
                                                    }}
                                                >
                                                    <span className="mb-4 text-xl text-gray-900 font-extrabold">
                                                        <FontAwesomeIcon
                                                            icon={faTiktok}
                                                            color={"#06F1EA"}
                                                            size={"xl"}
                                                            className={"mr-1"}
                                                        />{" "}
                                                        {formatNumberWithAbbreviation(
                                                            artistDataNumbers.tiktok_likes
                                                        )}{" "}
                                                        {t(
                                                            "APPS.ONESHEET.WIZARD.LIKES"
                                                        )}
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div className="flex justify-center">
                                        <Warning
                                            title={t(
                                                "APPS.ONESHEET.WIZARD.NO-DATA.TITLE"
                                            )}
                                            description={t(
                                                "APPS.ONESHEET.WIZARD.NO-DATA.DESC"
                                            )}
                                        />
                                    </div>
                                )}
                            </div>
                        </FormWizard.TabContent>
                    </FormWizard>
                    {/* ANIMACAO PIKA DAS GALAXIAS */}
                    {formSending && (
                        <div
                            className="d-flex justify-content-center align-items-center"
                            style={{
                                position: "fixed",
                                height: "100vh",
                                width: "100vw",
                                top: "0",
                                bottom: "0",
                                right: "0",
                                left: "0",
                                backdropFilter: "blur(7px)",
                            }}
                        >
                            <motion.div
                                style={{
                                    width: "200px",
                                    height: "200px",
                                    background: "#07024D",
                                    position: "absolute",
                                }}
                                animate={{
                                    scale: [1, 2, 2, 1, 1],
                                    rotate: [0, 0, 180, 180, 0],
                                    borderRadius: [
                                        "0%",
                                        "0%",
                                        "50%",
                                        "50%",
                                        "0%",
                                    ],
                                }}
                                transition={{
                                    duration: 2,
                                    ease: "easeInOut",
                                    times: [0, 0.2, 0.5, 0.8, 1],
                                    repeat: Infinity,
                                    repeatDelay: 1,
                                }}
                            />
                            <span
                                className=" fs-4 fw-semibold text-white"
                                style={{
                                    position: "absolute",
                                    zIndex: "1001",
                                }}
                            >
                                {/* COLOCA A FRASE DE CARREGAMENTO DE ACORDO COM o state loadingPhrase */}
                                {t(
                                    `APPS.ONESHEET.LOADING.PHRASE-${loadingPhrase}`
                                )}
                            </span>
                        </div>
                    )}
                </div>
            ) : (
                <SelectArtistCard text={"APPS.ONESHEET.WIZARD.SELECT-ARTIST"} />
            )}
            {/* BEGIN estilo do footer customizado. Não retirar daqui. Só funciona assim */}
            <style>
                {`
                    .wizard-card-footer{
                      display: flex;
                      justify-content: center;
                      margin-top: 50px;
                    }
                `}
            </style>
            {/* END estilo do footer customizado. Não retirar daqui. Só funciona assim */}
        </>
    );
};

export { OnesheetWizard };
