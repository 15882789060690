import { useAuth } from "../../auth";
import { SelectArtistCard } from "../../core/profile/components/HealthCheck/SelectArtistCard";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { Link } from "react-router-dom";
import { HealthCheckProgressBar } from "../../core/profile/components/HealthCheck/HealthCheckProgressBar";
import { Card, CardBody, CardHeader } from "next2";
import { CardTitle } from "../../../components/Cards/CardTitle";
import { CardSubtitle } from "../../../components/Cards/CardSubtitle";

/**
 * Card a ser exibido na Dashboard para fazer a chamada para a tela de HC
 * @returns {JSX.Element}
 * @constructor
 */
const GoToHealthCheckWidget = () => {
    const { currentArtist } = useAuth();
    const { t } = useTranslate();
    return (
        <>
            {currentArtist ? (
                <>
                    <Card
                        className="p-8 h-full bg-body"
                        id={"intro-healthcheck"}
                    >
                        <CardHeader className="p-0 mb-4 items-start justify-between">
                            <div className="flex flex-col md:mb-4">
                                <CardTitle text={t("APPS.CORE.ACCOUNT.HC")} />
                                <CardSubtitle
                                    text={t("APPS.CORE.ACCOUNT.HC.HELP")}
                                />
                            </div>
                            <Link
                                to="/core/profile"
                                state={{ selectedTab: "healthCheck" }}
                                className="btn btn-primary fw-semibold"
                            >
                                {t("GENERAL.SEE")}
                            </Link>
                        </CardHeader>
                        <CardBody className="p-0 overflow-visible py-2">
                            <HealthCheckProgressBar />
                        </CardBody>
                    </Card>
                    {/* <div className="card h-200px card-xl-stretch">
                        <div className="card-body d-flex align-items-center justify-content-between flex-wrap">
                            <div className="me-2">
                                <h2 className="fw-bold text-gray-800 mb-3">
                                    {t("APPS.CORE.ACCOUNT.HC")}
                                </h2>
                                <div
                                    className="text-muted fw-semibold w-200px w-sm-400px fs-6"
                                    style={{
                                        wordWrap: "break-word",
                                    }}
                                >
                                    {t("APPS.CORE.ACCOUNT.HC.HELP")}
                                </div> */}
                    {/*
                     * Chamando a barra de progresso do HC sem passar o parametro percentage, pra ela fazer a
                     * chamada de API e descobrir pra nós
                     */}
                    {/* <HealthCheckProgressBar />
                            </div>
                            <Link
                                to="/core/profile/health-check"
                                className="btn btn-primary fw-semibold"
                            >
                                {t("GENERAL.SEE")}
                            </Link>
                        </div>
                    </div> */}
                </>
            ) : (
                <SelectArtistCard />
            )}
        </>
    );
};

export { GoToHealthCheckWidget };
