import { Navigate, Route, Routes } from "react-router-dom";
import { registerOptionalParams } from "../OptionalParamRoute";
import { lazy } from "react";
import SuspensedView from "../SuspensedView";
import { PartnersListWrapper } from "../../modules/core/users/PartnersListWrapper";

const CoreRoutes = () => {
    const PartnerForm = lazy(() =>
        import("../../modules/core/users/PartnerForm")
    );
    const ProfileDetails = lazy(() =>
        import("../../modules/core/profile/ProfileDetails.js")
    );

    return (
        <Routes>
            <Route path="" element={<Navigate to="/partners" />} />
            <Route
                path="profile/*"
                element={
                    <SuspensedView>
                        <ProfileDetails />
                    </SuspensedView>
                }
            />
            <Route path="partners" element={<PartnersListWrapper />} />
            {registerOptionalParams(
                "partners/ed/:partnerId?",
                <SuspensedView>
                    <PartnerForm />
                </SuspensedView>
            )}
            <Route path="*" element={<Navigate to="/error/404" />} />
        </Routes>
    );
};

export { CoreRoutes };
