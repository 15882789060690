import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { useEffect, useState } from "react";
import StepByStepFacebookLoginModal from "../../../modules/core/profile/components/settings/modals/StepByStepFacebookLoginModal";
import { useAuth } from "../../../modules/auth";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { toast } from "react-toastify";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

/**
 * Inicializa o SDK do Facebook
 * @returns {Promise<unknown>}
 */
export default function initFacebookSDK() {
    return new Promise((resolve) => {
        // Wait for the Facebook SDK to initialize before starting the React app.
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: FACEBOOK_APP_ID,
                cookie: true,
                xfbml: true,
                version: "v16.0",
            });

            resolve();
        };

        // Load the Facebook SDK script.
        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
                return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
    });
}

/**
 * Componente que renderiza um botão de conectar com a conta Spotify da pessoa e faz o redirect pro endpoint do Spotify
 * que abre a janelinha de oauth
 * @returns {JSX.Element}
 * @constructor
 */
const MetaLoginButton = () => {
    const { PUT } = useApi();
    const { currentUser, setCurrentUser } = useAuth();
    const { t } = useTranslate();
    const [facebookUserAccessToken, setFacebookUserAccessToken] = useState(
        currentUser.facebookToken
    );
    const [isFacebookLoginModalOpen, setIsFacebookLoginModalOpen] =
        useState(false);
    const [useSDKLogout, setUseSDKLogout] = useState(false);
    const toggleFacebookLoginModal = () =>
        setIsFacebookLoginModalOpen(!isFacebookLoginModalOpen);

    // Inicializa o SDK do Facebook ao carregar a página
    useEffect(() => {
        initFacebookSDK();
    }, []);

    /**
     * Obtém um token de acesso de curta duração do usuário no Facebook
     */
    const logInToFB = () => {
        window.FB.login(
            (response) => {
                setFacebookUserAccessToken(response.authResponse?.accessToken);
                setUseSDKLogout(true);
            },
            {
                // Escopos para publicar conteúdo no Instagram e gerenciar anúncios
                scope: "instagram_basic,pages_read_engagement,pages_show_list,ads_management,business_management,instagram_manage_insights",
            }
        );
        toggleFacebookLoginModal();
    };

    /**
     * Desconecta a conta do Facebook, limpando o token na sessão e no BD
     * @returns {Promise<void>}
     */
    const logOutOfFB = async () => {
        if (useSDKLogout) {
            window.FB.logout(() => {
                setFacebookUserAccessToken("");
            });
        } else {
            setFacebookUserAccessToken("");
        }
        try {
            let userId = currentUser.id;
            let url = `core/user-facebook-data/${userId}`;
            let formData = new FormData();
            formData.append("id", userId);
            formData.append("facebook_long_lived_token", "");
            await PUT(url, formData);
            toast.success(t("GENERAL.SUCCESS"));
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ACTION-FAILED"));
        }
        setCurrentUser({ ...currentUser, facebookToken: "" });
    };

    /**
     * Obtém o LLT do usuário e guarda no backend
     * @returns {Promise<void>}
     * @constructor
     */
    const UpdateBackEndFacebookLongLivedUserAccessToken = async () => {
        if (!currentUser) {
            return;
        }
        if (!facebookUserAccessToken) {
            return;
        }
        try {
            let accessToken = await getLongLivedUserAccessToken();
            let userId = currentUser.id;
            let url = `core/user-facebook-data/${userId}`;
            let formData = new FormData();
            formData.append("id", userId);
            formData.append("facebook_long_lived_token", accessToken);
            await PUT(url, formData);
            toast.success(t("GENERAL.SUCCESS"));
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ACTION-FAILED"));
        }
    };

    /**
     * Quando o token existente na sessão muda, verifica se é necessário atualizar o LLT no backend
     */
    useEffect(() => {
        if (facebookUserAccessToken === currentUser.facebookToken) {
            return;
        }
        UpdateBackEndFacebookLongLivedUserAccessToken();
    }, [facebookUserAccessToken]);

    /**
     * Obtém um LLT para o usuário no Facebook
     * @returns {Promise<unknown>}
     */
    const getLongLivedUserAccessToken = () => {
        return new Promise((resolve) => {
            window.FB.api(
                "oauth/access_token",
                {
                    client_id: FACEBOOK_APP_ID,
                    client_secret: process.env.REACT_APP_FACEBOOK_CLIENT_SECRET,
                    grant_type: "fb_exchange_token",
                    fb_exchange_token: facebookUserAccessToken,
                },
                (response) => {
                    resolve(response.access_token);
                    return response.access_token;
                }
            );
        });
    };

    return (
        <>
            <main id="app-main">
                <section className="app-section">
                    {facebookUserAccessToken ? (
                        <button
                            onClick={logOutOfFB}
                            className="btn btn-sm btn-light btn-active-light-danger ml-2 text-slate-50"
                        >
                            {t(
                                "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.DISCONNECT"
                            )}
                        </button>
                    ) : (
                        <button
                            style={{
                                backgroundColor: "#E1306C",
                            }}
                            className="rounded-md text-slate-50 text-lg font-semibold p-4"
                            onClick={toggleFacebookLoginModal}
                        >
                            <FontAwesomeIcon
                                icon={faInstagram}
                                size="lg"
                                className="me-2"
                            />
                            {t("AUTH.ACCOUNTS.INSTAGRAM.LOGIN")}
                        </button>
                    )}
                </section>
            </main>
            <StepByStepFacebookLoginModal
                isOpen={isFacebookLoginModalOpen}
                toggle={toggleFacebookLoginModal}
                loginFacebook={logInToFB}
            />
        </>
    );
};
export { MetaLoginButton };
