import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ScrollTo.module.css";

/**
 * @description
 * Componente de rolagem para um determinado destino na página.
 *
 * @component
 * @param {boolean} fixed - Se vai ser um componente que aparece na lateral e fica fixo
 *  ou um botão
 * @param {number} showHeight - Altura de exibição em pixels que aciona o elemento.
 * @param {string} to - ID do destino para rolar.
 * @param {object} icon - Ícone do FontAwesome a ser exibido.
 * @param {string} bg_color - Cor de fundo do elemento.
 * @param {string} icon_color - Cor do ícone.
 * @returns {JSX.Element} - Elemento de âncora com ícone para rolagem suave.
 */

export const ScrollTo = ({
    fixed,
    showHeight,
    to,
    icon,
    bg_color,
    icon_color,
    cta,
    text_color,
}) => {
    return (
        <>
            {fixed ? (
                <Fixed
                    showHeight={showHeight}
                    to={to}
                    icon={icon}
                    bg_color={bg_color}
                    iconColor={icon_color}
                />
            ) : (
                <div className={styles.wrapperButton}>
                    <a
                        className={styles.button}
                        href={to}
                        style={{
                            backgroundColor: bg_color,
                            color: text_color,
                        }}
                    >
                        {cta}
                    </a>
                </div>
            )}
        </>
    );
};

export const Fixed = ({ showHeight, to, icon, bg_color, iconColor }) => {
    const [showAnchor, setShowAnchor] = useState(false);
    useEffect(() => {
        /**
         * @description
         * Função de manipulação do evento de rolagem da página.
         *
         * @function
         * @returns {void}
         */
        const handleScroll = () => {
            const scrollHeight = window.scrollY; // pega a altura da página
            const triggerHeight = showHeight; // altura em pixels que irá acionar o icone para o scroll

            // se a altura da página for maior que a altura passada para ativação
            // "seta" o showAnchor como true para o ícone aparecer
            if (scrollHeight > triggerHeight) {
                setShowAnchor(true);
            } else {
                setShowAnchor(false);
            }
        };

        // ativa a função ao dar scroll
        window.addEventListener("scroll", handleScroll);

        // Remova o event listener quando o componente for desmontado
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <a
            href={to}
            style={{
                backgroundColor: bg_color,
                color: iconColor,
                padding: 16,
            }}
            className={showAnchor ? styles.anchor : styles.hideAnchor}
        >
            <FontAwesomeIcon icon={icon} size="2xl" />
        </a>
    );
};
