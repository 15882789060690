import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { stringify } from "qs";
import React, { useEffect } from "react";
import { useAuth } from "../../../modules/auth";
import { toast } from "react-toastify";
import { useApi } from "../../../helpers/Api/ApiWrapper";

/**
 * Componente que renderiza um botão de conectar com a conta Spotify da pessoa e faz o redirect pro endpoint do Spotify
 * que abre a janelinha de oauth
 * @returns {JSX.Element}
 * @constructor
 */
const SpotifyLoginButton = () => {
    const { t } = useTranslate();
    const { currentUser, setCurrentUser } = useAuth();
    const { PUT } = useApi();

    /**
     * Redireciona pra tela de oauth consent screen do spotify, salvando num cookie a url atual pra voltar pra ela dps
     */
    const connectSpotify = () => {
        // Salva num cookie a url atual pra redirecionar de volta pra ela depois
        document.cookie = `socialLoginSourceLocation=${window.location.href}`;

        window.location.replace(
            "https://accounts.spotify.com/authorize?" +
                stringify({
                    client_id: process.env.REACT_APP_SPOTIFY_CLIENT_ID,
                    redirect_uri:
                        process.env.REACT_APP_APP_URL +
                        "login/callback-spotify",
                    scope: "user-read-private user-read-email",
                    response_type: "token",
                    show_dialog: true,
                })
        );
    };

    /**
     * Desconecta o spotify do usuário
     */
    const disconnectSpotify = async () => {
        try {
            let formData = new FormData();
            formData.append("id", currentUser.id);
            formData.append("spotify_id", "");
            // Faz a chamada pra atualizar o usuário no BD
            await PUT(`core/user-spotify-data/${currentUser.id}`, formData);
            setCurrentUser({ ...currentUser, spotifyId: "" });
            toast.success(t("GENERAL.SUCCESS"));
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ERROR-PAGE.TITLE"));
        }
    };

    return (
        <>
            {currentUser.spotifyId ? (
                <button
                    onClick={() => disconnectSpotify()}
                    className="btn btn-sm btn-light btn-active-light-danger ml-2 text-slate-50"
                >
                    {t("APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.DISCONNECT")}
                </button>
            ) : (
                <button
                    style={{
                        backgroundColor: "#1DB954",
                    }}
                    className="rounded-md text-slate-50 text-lg font-semibold p-4"
                    onClick={() => connectSpotify()}
                >
                    <FontAwesomeIcon
                        icon={faSpotify}
                        size="lg"
                        className="me-2"
                    />
                    {t("AUTH.ACCOUNTS.SPOTIFY.LOGIN")}
                </button>
            )}
        </>
    );
};

export { SpotifyLoginButton };
