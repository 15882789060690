import { Route, Routes } from "react-router-dom";
import { lazy } from "react";
import SuspensedView from "../SuspensedView";
import { InvitesListWrapper } from "../../modules/invites/client/InviteListWrapper";

const InvitesRoutes = () => {
    const Invite = lazy(() =>
        import("../../modules/invites/client/InviteDetails")
    );

    return (
        <Routes>
            <Route path="" element={<InvitesListWrapper />} />
            <Route
                path=":inviteId"
                element={
                    <SuspensedView>
                        <Invite />
                    </SuspensedView>
                }
            />
        </Routes>
    );
};

export { InvitesRoutes };
