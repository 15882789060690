import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import TopBarProgress from "react-topbar-progress-indicator";
import { Suspense } from "react";

const SuspensedView = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      0: baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default SuspensedView;
