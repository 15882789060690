import React, { useEffect, useState } from "react";
import { useTranslate } from "../../../../../helpers/i18n/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { useAlert } from "../../../../../helpers/utils/CustomAlert";
import { FilePond } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { useAppOniApi } from "../../../../../helpers/Api/AppOniApiWrapper";
import Swal from "sweetalert2/src/sweetalert2";
import "../../../../../helpers/utils/CustomAlert.css";
import ReactAudioPlayer from "react-audio-player";

/**
 * Componente usado para gerenciar o uploader de áudio para labelassets no AppOni, usando a url de bypass
 * @param {number} assetId id do fonograma que receberá o áudio
 * @param {String} audioTrackUrl url do arquivo atual (caso haja)
 * @returns {JSX.Element}
 * @constructor
 */
const AudioManagementComponent = ({ assetId, audioTrackUrl }) => {
    const { t } = useTranslate();
    const { alert } = useAlert();
    const { apponi_delete, getHeaders } = useAppOniApi();
    const uploadEndpoint = `catalog/apiv2/upload-audio-to-label-asset/${assetId}`;
    const uploadPrefixWithBypass =
        process.env.REACT_APP_APPONI_BYPASS_UPLOAD_URL;
    const [file, setFile] = useState();
    const [currentFileUrl, setCurrentFileUrl] = useState("");

    /**
     * Se houver arquivo de áudio no backend, carrega ele quando a página abre. É necessário montar a url completa porque
     * o backend retorna o path relativo do arquivo de áudio apenas. A url é montada a partir de
     * process.env.REACT_APP_APPONI_API_URL (que termina com uma barra), e audioTrackUrl (que comeca com uma barra).
     * Pra nao ficar com a barra duplicada, usamos o .substring(1) pra remover a barra do começo de audioTrackUrl.
     */
    useEffect(() => {
        if (audioTrackUrl) {
            setCurrentFileUrl(
                process.env.REACT_APP_APPONI_API_URL +
                    audioTrackUrl.substring(1)
            );
        }
    }, [audioTrackUrl]);

    function removeAudioFile() {
        alert
            .fire({
                title: t("APPONI.LABELFORM.audio_removal_confirmation"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("GENERAL.OK"),
                cancelButtonText: t("GENERAL.CANCEL"),
                reverseButtons: true,
                showLoaderOnConfirm: true,
                allowOutsideClick: () => !Swal.isLoading(),
            })
            .then((result) => {
                if (result.isConfirmed) {
                    apponi_delete(uploadEndpoint).then((response) => {
                        if (response.data.status === 204) {
                            alert.fire(
                                t("GENERAL.DELETED"),
                                t("APPONI.LABELFORM.audio_file_removal"),
                                "success"
                            );
                            setCurrentFileUrl("");
                        } else {
                            alert.fire(
                                t("GENERAL.OOPS"),
                                t("GENERAL.ACTION-FAILED"),
                                "danger"
                            );
                        }
                    });
                }
            });
    }

    return (
        <>
            {
                //Se houver arquivo de audio, carregamos ele.
                currentFileUrl ? (
                    <div className="mb-3 d-flex align-items-center justify-content-end gap-5">
                        <ReactAudioPlayer src={currentFileUrl} controls />
                        <FontAwesomeIcon
                            className="cursor-pointer"
                            icon={faTimes}
                            color={"red"}
                            size={"2xl"}
                            onClick={removeAudioFile}
                        />
                    </div>
                ) : (
                    // Do contrário, carregamos o FilePond
                    <div className="p-0">
                        <small className="text-center" style={{ flex: 1 }}>
                            <strong>
                                {t("APPONI.LABELFORM.audio_help_text")}
                            </strong>
                        </small>
                        <FilePond
                            files={file}
                            allowMultiple={false}
                            onupdatefiles={setFile}
                            labelIdle={t("FORMS.FILEPOND.PLACEHOLDER")}
                            server={{
                                url: uploadPrefixWithBypass,
                                process: {
                                    url: "./" + uploadEndpoint,
                                    headers: getHeaders(),
                                },
                            }}
                        />
                    </div>
                )
            }
        </>
    );
};

export default AudioManagementComponent;
