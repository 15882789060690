import React from 'react';
import {Input} from "reactstrap";
import FormField from "../../FormField";

const FormTextArea = React.forwardRef(({
                                           className,
                                           label,
                                           fieldId,
                                           fieldName,
                                           validationError,
                                           required,
                                           helpText,
                                           autoFocus,
                                           value,
                                           onChange,
                                           cols = 40,
                                           rows = 3,
                                           disabled = false,
    placeholder = ''
                                       }, ref) => {
    return (
        <FormField
            className={className}
            label={label}
            fieldName={fieldId || fieldName}
            validationError={validationError}
            required={required}
            helpText={helpText}
            inputComponent={<Input type="textarea" name={fieldName} id={fieldId || fieldName}
                                   className={validationError && "input-error"}
                                   autoFocus={autoFocus}
                                   ref={ref}
                                   value={value}
                                   onChange={onChange}
                                   disabled={disabled}
                                   placeholder={placeholder}
                                   cols={cols} rows={rows}/>}
        />
    )
})

export default FormTextArea;
