import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { ThemeModeSwitcher } from "../../theme-mode/ThemeModeSwitcher";
import {
    faBell,
    faUsersGear,
    faBullhorn,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "../../../modules/auth";
import { ProfilePhoto } from "../ProfilePhoto";
import { HeaderNotificationsMenu } from "./header-menus/HeaderNotificationsMenu";
import { HeaderUserMenu } from "./header-menus/HeaderUserMenu";
import { useTranslate } from "../../../helpers/i18n/useTranslate";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
    toolbarButtonHeightClass =
        "btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40px",
    toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";

const Topbar = () => {
    const { t } = useTranslate();
    const { currentUser, currentArtist, currentClient } = useAuth();
    const [photoData, setPhotoData] = useState({ photo: "", altName: "" });

    useEffect(() => {
        if (!currentUser) return;
        if (currentArtist)
            setPhotoData({
                photo: currentArtist.avatar,
                altName: currentArtist.name,
            });
        //Faz aparecer imagem inserida no campo de logo da API
        else
            setPhotoData({
                photo: currentClient.manager_logo,
                altName: currentUser.first_name || currentUser.email,
            });
    }, [currentUser, currentArtist]);

    return (
        <div className="d-flex align-items-stretch flex-shrink-0">
            <div className="topbar d-flex align-items-stretch flex-shrink-0">
                {/* Search */}
                {/*<div*/}
                {/*  className={clsx(*/}
                {/*    "d-flex align-items-stretch",*/}
                {/*    toolbarButtonMarginClass*/}
                {/*  )}*/}
                {/*>*/}
                {/*  <Search />*/}
                {/*</div>*/}
                {/* Selecionar Artista/Gravadora */}
                {/*<div*/}
                {/*    className={clsx(*/}
                {/*        "d-flex align-items-center ",*/}
                {/*        toolbarButtonMarginClass*/}
                {/*    )}*/}
                {/*>*/}
                {/*</div>*/}

                {/* Atualizaçoes em Projetos */}
                {/*<div*/}
                {/*  className={clsx(*/}
                {/*    "d-flex align-items-center ",*/}
                {/*    toolbarButtonMarginClass*/}
                {/*  )}*/}
                {/*>*/}
                {/*  /!* begin::Drawer toggle *!/*/}
                {/*  <div*/}
                {/*    className={clsx(*/}
                {/*      "btn btn-icon btn-active-light-primary btn-custom",*/}
                {/*      toolbarButtonHeightClass*/}
                {/*    )}*/}
                {/*    id="project_changes_tab_toggle"*/}
                {/*  >*/}
                {/*    <FontAwesomeIcon icon={faBullhorn} />*/}
                {/*  </div>*/}
                {/*  /!* end::Drawer toggle *!/*/}
                {/*</div>*/}

                {/* NOTIFICATIONS */}
                {/*<div*/}
                {/*  className={clsx(*/}
                {/*    "d-flex align-items-center",*/}
                {/*    toolbarButtonMarginClass*/}
                {/*  )}*/}
                {/*>*/}
                {/*  /!* begin::Menu- wrapper *!/*/}
                {/*  <div*/}
                {/*    className={clsx(*/}
                {/*      "btn btn-icon btn-active-light-primary btn-custom position-relative",*/}
                {/*      toolbarButtonHeightClass*/}
                {/*    )}*/}
                {/*    data-kt-menu-trigger="click"*/}
                {/*    data-kt-menu-attach="parent"*/}
                {/*    data-kt-menu-placement="bottom-end"*/}
                {/*    data-kt-menu-flip="bottom"*/}
                {/*  >*/}
                {/*    <FontAwesomeIcon icon={faBell} />*/}
                {/*    <span className="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"></span>*/}
                {/*  </div>*/}
                {/*  <HeaderNotificationsMenu />*/}
                {/*  /!* end::Menu wrapper *!/*/}
                {/*</div>*/}

                {/* CHAT */}
                {/*<div*/}
                {/*  className={clsx(*/}
                {/*    "d-flex align-items-center",*/}
                {/*    toolbarButtonMarginClass*/}
                {/*  )}*/}
                {/*>*/}
                {/*  /!* begin::Menu wrapper *!/*/}
                {/*  <div*/}
                {/*    className={clsx(*/}
                {/*      "btn btn-icon btn-active-light-primary btn-custom position-relative",*/}
                {/*      toolbarButtonHeightClass*/}
                {/*    )}*/}
                {/*    id="kt_drawer_chat_toggle"*/}
                {/*  >*/}
                {/*    <FontAwesomeIcon icon={faUsersGear} />*/}
                {/*  </div>*/}
                {/*  /!* end::Menu wrapper *!/*/}
                {/*</div>*/}

                {/* Quick links */}
                {/*<div*/}
                {/*  className={clsx(*/}
                {/*    "d-flex align-items-center",*/}
                {/*    toolbarButtonMarginClass*/}
                {/*  )}*/}
                {/*>*/}
                {/*  /!* begin::Menu wrapper *!/*/}
                {/*  <div*/}
                {/*    className={clsx(*/}
                {/*      "btn btn-icon btn-active-light-primary btn-custom",*/}
                {/*      toolbarButtonHeightClass*/}
                {/*    )}*/}
                {/*    data-kt-menu-trigger="click"*/}
                {/*    data-kt-menu-attach="parent"*/}
                {/*    data-kt-menu-placement="bottom-end"*/}
                {/*    data-kt-menu-flip="bottom"*/}
                {/*  >*/}
                {/*    <FontAwesomeIcon icon={faUsersGear} />*/}
                {/*  </div>*/}
                {/*  <QuickLinks />*/}
                {/*  /!* end::Menu wrapper *!/*/}
                {/*</div>*/}

                {/* begin::Theme mode */}
                <div
                    className={clsx(
                        "d-flex align-items-center",
                        toolbarButtonMarginClass
                    )}
                >
                    {/* Switcher de Troca de Temas, para ter a possiblidade de trocar de tema apenas habilitar esse componente abaixo */}
                    {/* <ThemeModeSwitcher
                        toggleBtnClass={toolbarButtonHeightClass}
                    /> */}
                </div>
                {/* end::Theme mode */}

                {/* begin::User */}
                <div
                    className={clsx(
                        "d-flex align-items-center",
                        toolbarButtonMarginClass
                    )}
                    id="kt_header_user_menu_toggle"
                >
                    {/* begin::Toggle */}
                    <div
                        className={clsx(
                            "cursor-pointer symbol",
                            toolbarUserAvatarHeightClass
                        )}
                        data-kt-menu-trigger="click"
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement="bottom-end"
                        data-kt-menu-flip="bottom"
                    >
                        <ProfilePhoto
                            photo={photoData.photo}
                            altName={photoData.altName}
                        />
                    </div>
                    <HeaderUserMenu />
                    {/* end::Toggle */}
                </div>
                {/* end::User */}
            </div>
        </div>
    );
};

export { Topbar };
