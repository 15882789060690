import React from 'react';
import {Input} from "reactstrap";
import FormField from "../../FormField";

const FormNumberInput = React.forwardRef(({
                                              className,
                                              label,
                                              fieldId,
                                              fieldName,
                                              validationError,
                                              required,
                                              helpText,
                                              autoFocus,
                                              value,
                                              onChange,
                                              min,
                                              max,
                                              step,
                                              style,
                                              disabled = false,
                                          }, ref) => {
    return (
        <FormField
            className={className}
            label={label}
            fieldName={fieldId || fieldName}
            validationError={validationError}
            required={required}
            helpText={helpText}
            inputComponent={<Input type="number" name={fieldName} id={fieldId || fieldName}
                                   className={validationError && "input-error"}
                                   style={style}
                                   autoFocus={autoFocus}
                                   ref={ref}
                                   value={value}
                                   min={min}
                                   max={max}
                                   step={step || 1}
                                   disabled={disabled}
                                   onChange={onChange}/>}
        />
    )
})

export default FormNumberInput;
