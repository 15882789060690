import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import api from "../../../helpers/Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faSpotify } from "@fortawesome/free-brands-svg-icons";
import { faCircleCheck } from "@fortawesome/pro-solid-svg-icons";
import { SpotifyLoginButton } from "../../../components/LoginButtons/SpotifyLoginButton";
import { MetaLoginButton } from "../../../components/LoginButtons/MetaLoginButton";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useAuth } from "../utils/AuthProvider";
import * as authHelper from "../utils/AuthHelpers";

/**
 * Renderiza os botões de login com redes sociais sem o usuário precisar logar. Vai ser usado
 * apenas para embed no app pros usuários conseguirem conectar suas redes sociais.
 * @returns {JSX.Element}
 */
export function SocialLogin() {
    const location = useLocation();
    const { t } = useTranslate();
    const { currentUser, setCurrentUser, saveAuth } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    /**
     * Tenta obter o usuário da requisição com base no token indicado
     * @param {String} token access token pra buscar pelo usuário
     * @returns {Promise<void>}
     */
    const getUser = async (token) => {
        try {
            const userResponse = await api.get("/core/users", {
                // Pega o user relacionado ao token passado por parametro
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            let userData = userResponse.data[0];
            setCurrentUser({
                id: userData.id,
                email: userData.email,
                first_name: userData.first_name,
                last_name: userData.first_name,
                spotifyId: userData.spotify_id,
                facebookToken: userData.facebook_long_lived_token,
                hasAcceptedTerms: userData.has_accepted_terms,
            });
            saveAuth(
                saveAuth({
                    api_token: token,
                    refreshToken: "",
                })
            );
            setIsLoading(false);
        } catch (e) {
            window.location.replace("/error/404");
        }
    };

    /**
     * Obtém o parâmetro "at" da URL (que corresponde ao access token) e faz uma
     * chamada para o backend para buscar o usuário correspondente. Caso não
     * haja o parâmetro ou não seja possível encontrar o usuário, redireciona
     * para 404.
     */
    useEffect(() => {
        // Obtém o parâmetro "at" da URL
        const searchParams = new URLSearchParams(location.search);
        const at = searchParams.get("at");
        if (!at) window.location.replace("/error/404");

        getUser(at);
    }, [location]);

    return (
        <>
            {isLoading ? (
                <div className="flex items-center justify-center min-h-[100vh]">
                    <LoadingSpinner size={"2xl"} />
                </div>
            ) : (
                currentUser && (
                    <div className="flex flex-column border-top p-9 pb-0">
                        {/* Spotify */}
                        {false && (
                            <div className="flex justify-between items-center">
                                {/* LADO ESQUERDO DA ROW: ÍCONE DA REDE, NOME E PROPÓSITO DA CONEXÃ0 */}
                                <div className="flex items-center">
                                    {/* LOGO DA REDE SOCIAL */}
                                    <FontAwesomeIcon
                                        icon={faSpotify}
                                        color="#1DB954"
                                        className="mr-5 text-4xl"
                                    />
                                    <div className="flex flex-column">
                                        {/* TITULO DA REDE SOCIAL */}
                                        <span className="font-bold text-slate-50 text-lg mb-3">
                                            {t(
                                                "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.SPOTIFY"
                                            )}{" "}
                                            {currentUser.spotifyId ? (
                                                <FontAwesomeIcon
                                                    icon={faCircleCheck}
                                                    color="green"
                                                    className="ml-2"
                                                />
                                            ) : null}
                                        </span>
                                        {/* EXPLICACAO DO PQ QUEREMOS QUE O ARTISTA CONECTE COM AQUELA REDE SOCIAL */}
                                        <span className="font-bold text-gray-500 text-base">
                                            {t(
                                                "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.SPOTIFY.TEXT"
                                            )}
                                        </span>
                                    </div>
                                </div>
                                {/* LADO DIREITO DA ROW: STATUS DA CONEXÃO E BOTÃO DE AÇÃO (CONECTAR/DESCONECTAR) */}
                                <div>
                                    <SpotifyLoginButton />
                                </div>
                            </div>
                        )}
                        {/*Facebook*/}
                        <div className="flex justify-between items-center mt-5">
                            {/* LADO ESQUERDO DA ROW: ÍCONE DA REDE, NOME E PROPÓSITO DA CONEXÃ0 */}
                            <div className="flex items-center">
                                {/* LOGO DA REDE SOCIAL */}
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                    // instagram collor
                                    color="#E1306C"
                                    className="mr-5 text-4xl"
                                />
                                <div className="flex flex-column">
                                    {/* TITULO DA REDE SOCIAL */}
                                    <span className="font-bold text-slate-50 text-lg mb-3">
                                        {t(
                                            "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.INSTAGRAM"
                                        )}{" "}
                                        {currentUser.facebookToken ? (
                                            <FontAwesomeIcon
                                                icon={faCircleCheck}
                                                color="green"
                                                className="ml-2"
                                            />
                                        ) : null}
                                    </span>
                                    {/* EXPLICACAO DO PQ QUEREMOS QUE O ARTISTA CONECTE COM AQUELA REDE SOCIAL */}
                                    <span className="font-bold text-gray-500 text-base">
                                        {t(
                                            "APPS.CORE.ACCOUNT.CONNECTED-ACCOUNTS.INSTAGRAM.TEXT"
                                        )}
                                    </span>
                                </div>
                            </div>
                            {/* LADO DIREITO DA ROW: STATUS DA CONEXÃO E BOTÃO DE AÇÃO (CONECTAR/DESCONECTAR) */}
                            <div>
                                <MetaLoginButton />
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
}
