/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../modules/auth";
import { ProfilePhoto } from "../../ProfilePhoto";
import { useTranslate } from "../../../../helpers/i18n/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsersGear, faDownload } from "@fortawesome/pro-light-svg-icons";
import { toast } from "react-toastify";
import axios from "axios";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { handleDownloadCSV } from "../../../../helpers/utils/csvdownload";

const HeaderUserMenu = () => {
    const { currentUser, currentClient, currentArtist, logout } = useAuth();
    const { t } = useTranslate();
    const [loading, setLoading] = useState(false);

    /**
     * Baixa a lista de leads a partir do onilnk
     * @returns {Promise<void>}
     */
    const getLeads = async () => {
        if (loading) return; // Impede spam click
        toast.success("Aguarde um instante");
        setLoading(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_MUSIC_MARKETING_API_URL}${currentArtist?.id}/core/get-leads`,
                {
                    headers: {
                        Secret: process.env.REACT_APP_MUSIC_MARKETING_API_TOKEN,
                    },
                }
            );
            handleDownloadCSV(response.data.results);
            toast.success(
                "Seu download será feito automaticamente em alguns instantes"
            );
        } catch (e) {
            console.error(e);
            toast.error(t("GENERAL.ACTION-FAILED"));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px"
            data-kt-menu="true"
        >
            <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                    <ProfilePhoto
                        photo={currentUser.photo || null}
                        altName={
                            currentUser
                                ? currentUser["first_name"]
                                    ? currentUser["first_name"]
                                    : currentUser["email"]
                                : "USR"
                        }
                    />

                    <div className="d-flex flex-column ms-2">
                        <div className="fw-bold d-flex align-items-center fs-5">
                            {currentUser ? currentUser["first_name"] : ""}
                            <span className="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">
                                Pro
                            </span>
                        </div>
                        <a
                            href="#"
                            className="fw-semibold text-muted text-hover-primary fs-7"
                        >
                            {currentUser ? currentUser["email"] : ""}
                        </a>
                    </div>
                </div>
            </div>

            <div className="separator my-2"></div>

            {[130].includes(currentArtist?.id) ? (
                <>
                    <div className="menu-item px-5">
                        <a
                            href="#"
                            className="menu-link px-5"
                            onClick={() => getLeads()}
                        >
                            <FontAwesomeIcon
                                className="me-2"
                                icon={faDownload}
                            />
                            <span className="menu-text">
                                {t("APPS.MARKETING.DOWNLOAD.LEADS")}
                            </span>
                            <span className="menu-badge">
                                <span className="badge badge-light-success fs-7">
                                    Beta
                                </span>
                            </span>
                        </a>
                        {loading && <LoadingSpinner />}
                    </div>
                    <div className="separator my-2"></div>
                </>
            ) : null}

            <div className="menu-item px-5">
                <Link to="/core/profile" className="menu-link px-5">
                    {t("GENERAL.MENUS.PROFILE")}
                </Link>
            </div>

            <div className="menu-item px-5">
                <Link
                    to={"#"}
                    className="menu-link px-5"
                    style={{ pointerEvents: "none" }}
                >
                    {t("GENERAL.MENUS.SUBSCRIPTION")}
                    <span className="badge badge-secondary ms-3">
                        {t("GENERAL.MENUS.SOON")}
                    </span>
                </Link>
            </div>

            {currentClient?.available_features.includes("CTLOG") && (
                <div className="menu-item px-5">
                    <a href="/projects" className="menu-link px-5">
                        <span className="menu-text">
                            {t("GENERAL.MENUS.PROJECTS")}
                        </span>
                        <span className="menu-badge">
                            <span className="badge badge-light-danger badge-circle fw-bold fs-7">
                                !
                            </span>
                        </span>
                    </a>
                </div>
            )}

            {currentClient?.available_features.includes("CORE") && (
                <div className="menu-item px-5">
                    <a href="/core/partners" className="menu-link px-5">
                        <span className="menu-text">
                            {t("APPS.CORE.PARTNERS.LIST")}
                        </span>
                    </a>
                </div>
            )}
            {/* begin:: Selecionar artista */}
            {currentClient.manager_is_recorder ? (
                <>
                    <div className="separator my-2"></div>
                    <div
                        className="menu-item px-5"
                        data-kt-menu-trigger={"hover"}
                        data-kt-menu-attach="parent"
                        data-kt-menu-placement={"bottom-start"}
                        id="change_artist_or_client_tab_toggle"
                    >
                        <a href="#" className="menu-link px-5">
                            <FontAwesomeIcon
                                className="me-2"
                                icon={faUsersGear}
                            />
                            <span className="menu-text">
                                {t("FORMS.CORE.PROJECT.ARTIST.SELECT")}
                            </span>
                        </a>
                    </div>
                </>
            ) : null}
            {/* end:: Selecionar artista */}
            <div className="separator my-2"></div>

            {/*<Languages />*/}

            {/*<div className="menu-item px-5 my-1">*/}
            {/*  <Link to="/crafted/account/settings" className="menu-link px-5">*/}
            {/*    Account Settings*/}
            {/*  </Link>*/}
            {/*</div>*/}

            <div className="menu-item px-5">
                <a onClick={logout} className="menu-link px-5">
                    Sair
                </a>
            </div>
        </div>
    );
};

export { HeaderUserMenu };
