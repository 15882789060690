import React from 'react';
import 'bootstrap';


export default function Button({...props}) {
    return (
        <button
            {...props}
        >
            {props.icon && <Icon before={props.icon} text={props.text}/>}
            {props.text}
            {props.aftericon && <Icon after={props.aftericon} text={props.text}/>}

        </button>
    );
}

const Icon = ({before, after, text}) => {
    return before ? <i className={`${text && 'mr-2'} ${before}`}/> : <i className={`ml-2 ${after}`}/>
}
