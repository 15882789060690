import React from "react";
import { isNotEmpty } from "../../helpers/usefulFunctions";

/**
 * Renderiza a bolotinha de foto do usuário, ou a inicial do nome dele caso nao tenha foto
 * @param {String} photo path da imagem
 * @param {String} altName string da qual será retirado o primeiro caractere pra mostrar, caso nao haja imagem
 * @returns {JSX.Element}
 * @constructor
 */
const ProfilePhoto = ({ photo, altName }) => {
    return (
        <div className="symbol symbol-50px">
            {isNotEmpty(photo) ? (
                <img alt="User profile" src={photo} style={{objectFit: 'cover'}} />
            ) : (
                <div className="symbol-label fs-2 fw-bold bg-info text-inverse-info">
                    {altName ? altName[0] : ""}
                </div>
            )}
        </div>
    );
};

export { ProfilePhoto };
