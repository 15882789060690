import { useState } from "react";
import { Modal, Button } from "@nextui-org/react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { useAuth } from "../../../modules/auth";

export default function AuthenticationModal({ isOnRegister = false, currentUser = null }) {
    // i18n
    const { t } = useTranslate();
    const { PUT } = useApi();
    const { setCurrentUser } = useAuth();
    const [open, setOpen] = useState(true);
    const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

    /*
    * Essa função é chamada quando o usuário clica em "Aceitar" no modal de termos de uso. E atualiza o banco de dados
     */
    const handleAccept = async () => {
        if (isOnRegister) {
            setOpen(false);
            //     we must update the user's terms of use acceptance in the database
        } else {
            let data = new FormData();
            data.append("has_accepted_terms", true);
            data.append("id", currentUser.id);
            try {
                await PUT(`core/user-terms/${currentUser.id}`, data);
                setOpen(false);
                setCurrentUser({ ...currentUser, hasAcceptedTerms: true });
            } catch (e) {
                console.error(e);
            }
        }
    };


    return (
        <>
            <Modal open={open} preventClose={true}>
                <>
                    <Modal.Header className="flex flex-col gap-1" style={{ padding: "1rem" }}>
                        <div className="text-2xl font-bold">
                            Termo de Uso AmplifyUs
                        </div>
                    </Modal.Header>
                    {/*the modal body must be a scroll*/}
                    <Modal.Body style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}>
                        <div style={styles.text}>
                            Data da Última Atualização: Novembro de 2023
                        </div>
                        <div style={styles.subtitle}>
                            Introdução
                        </div>
                        <div style={styles.text}>
                            Solicitamos a leitura cuidadosa deste documento antes de acessar o site www.amplifyus.app ou
                            utilizar o Serviço fornecido pela AMPLIFYUS SOFTWARE LTDA (“nós”, “nosso” ou “nossa”).
                        </div>
                        <div style={styles.subtitle}>
                            Definições Essenciais:
                        </div>
                        <div style={styles.text}>
                            AmplifyUs ou Plataforma: AMPLIFYUS SOFTWARE LTDA, CNPJ nº 51.593.504/0001-01, situada na
                            Alameda
                            do Ingá, nº 38 – 4º andar, Sala 401 – Bairro Vale do Sereno, Nova Lima/MG. CEP: 34006-042
                        </div>
                        <div style={styles.text}>
                            Artista: Pessoas jurídicas, indivíduos ou grupos musicais a partir de 13 anos, interessados
                            em
                            gerir suas carreiras e promover seu trabalho através desta plataforma.
                        </div>
                        <div style={styles.text}>
                            Usuários: Todos que utilizam a plataforma.
                        </div>
                        <div style={styles.text}>
                            Selos independentes: Entidades menores envolvidas na produção, distribuição e promoção de
                            música, oferecendo maior autonomia aos artistas para tomadas de decisão criativas e
                            estratégicas.
                        </div>
                        <div style={styles.text}>
                            Gravadoras: Empresas dedicadas à produção, distribuição e promoção de música, frequentemente
                            associadas a estruturas maiores, proporcionando suporte financeiro e logístico aos artistas
                            em
                            troca dos direitos sobre suas produções musicais.
                        </div>
                        <div style={styles.subtitle}>
                            Elegibilidade e Requisitos de Idade
                        </div>
                        <div style={styles.text}>
                            Para usufruir do Serviço AmplifyUs e acessar o Conteúdo, você deve: (1) ter 13 anos de idade
                            ou
                            mais, (2) obter consentimento dos pais ou responsáveis caso seja menor de idade, (3) possuir
                            capacidade civil para contratos e (4) residir em um país onde o Serviço esteja disponível.
                            Todos
                            os dados fornecidos devem ser verdadeiros e atualizados. No caso de menores, seus
                            responsáveis
                            devem aceitar os Termos em seu nome. Informações adicionais sobre requisitos de idade mínima
                            estão disponíveis no processo de registro. O AmplifyUs não pode registrar usuários que não
                            atendam aos requisitos de idade mínima.
                        </div>
                        <div style={styles.subtitle}>
                            Serviço AmplifyUs Oferecido por Nós
                        </div>
                        <div style={styles.text}>
                            Opções de Serviço
                        </div>
                        <div style={styles.text}>
                            Oferecemos uma variedade de opções para o Serviço AmplifyUs. Alguns são gratuitos, enquanto
                            outros exigem pagamento antecipado (“Assinaturas pagas”). Também podemos apresentar planos
                            promocionais e serviços de terceiros. Produtos e serviços de terceiros não são de nossa
                            responsabilidade.
                        </div>
                        <div style={styles.text}>
                            Serviço Ilimitado
                        </div>
                        <div style={styles.text}>
                            A disponibilidade do Serviço Ilimitado pode variar entre os usuários. A descontinuidade do
                            Serviço Ilimitado pode impossibilitar uma nova inscrição no futuro. Caso isso ocorra, você
                            não
                            será mais cobrado pelo Serviço.
                        </div>
                        <div style={styles.subtitle}>
                            Interatividade com Terceiros
                        </div>
                        <div style={styles.text}>

                            Aplicativos, dispositivos e software de código-fonte aberto de terceiros
                        </div>
                        <div style={styles.text}>
                            O Serviço AmplifyUs pode ser integrado ou pode interagir com aplicativos, sites e serviços
                            de
                            terceiros (“Aplicativos de terceiros”) e computadores pessoais, telefones celulares,
                            tablets,
                            dispositivos vestíveis, alto-falantes e outros dispositivos de terceiros (“Dispositivos”).
                            Seu
                            uso dos Aplicativos de terceiros e Dispositivos pode estar sujeito a termos, condições e
                            políticas adicionais fornecidos a você pelo terceiro em questão. O AmplifyUs não garante que
                            Aplicativos de terceiros e Dispositivos sejam compatíveis com o Serviço AmplifyUs.
                        </div>
                        <div style={styles.text}>
                            Limitações e Alterações no Serviço
                        </div>
                        <div style={styles.text}>
                            Empenhamo-nos em manter o Serviço AmplifyUs funcional e atualizado. No entanto, interrupções
                            temporárias podem ocorrer por razões técnicas, atualizações ou alterações nas leis
                            aplicáveis.
                            Podemos modificar ou interromper parte ou totalidade do Serviço, sem obrigação de fornecer
                            conteúdo específico ou garantir sua continuidade.
                        </div>
                        <div style={styles.text}>
                            Para assinaturas pagas interrompidas antes do término, reembolsaremos o período não
                            utilizado. O
                            AmplifyUs não é responsável por reembolsar interrupções causadas por ações de terceiros ou
                            eventos fora de nosso controle.
                        </div>
                        <div style={styles.subtitle}>
                            Seu Uso do Serviço AmplifyUs
                        </div>
                        <div style={styles.text}>
                            Criação de Conta no AmplifyUs
                        </div>
                        <div style={styles.text}>
                            O registro de uma conta é necessário para utilizar o Serviço AmplifyUs. Seus dados de acesso
                            devem ser confidenciais.
                        </div>
                        <div style={styles.text}>
                            Você é responsável por qualquer uso não autorizado da sua conta. Em caso de perda, roubo ou
                            acesso indevido à sua conta, entre em contato com nossa equipe de Atendimento ao Cliente.
                        </div>
                        <div style={styles.text}>
                            Seus dados pessoais estão protegidos conosco e serão tratados conforme nossa Política de
                            Privacidade. Você é responsável pela manipulação de dados de terceiros, que vier a utilizar
                            via
                            AmplifyUs.
                        </div>
                        <div style={styles.text}>
                            Anormalidades no Registro
                        </div>
                        <div style={styles.text}>
                            Reservamo-nos o direito de cancelar ou impedir cadastros caso identifiquemos qualquer
                            anormalidade ou tentativa deliberada de burlar nossas políticas obrigatórias.
                        </div>
                        <div style={styles.text}>
                            Seus Direitos de Uso do Serviço AmplifyUs
                        </div>
                        <div style={styles.text}>
                            Oferecemos uma permissão limitada, não exclusiva e revogável para uso pessoal e não
                            comercial do
                            Serviço e do Conteúdo. A propriedade dos aplicativos de software e do Conteúdo permanece com
                            o
                            AmplifyUs e seus licenciadores.
                        </div>
                        <div style={styles.subtitle}>

                            Propriedade Intelectual do AmplifyUs
                        </div>
                        <div style={styles.text}>
                            O Serviço AmplifyUs, incluindo software, código fonte, intefarce, funcionalidades, layout,
                            bem
                            como suas marcas são de propriedade exclusiva do AmplifyUs ou de seus licenciadores. Estes
                            Termos não concedem direitos de uso sobre nenhum tipo de propriedade intelectual..
                        </div>
                        <div style={styles.text}>
                            Conteúdo e Links para Outros Sites
                        </div>
                        <div style={styles.text}>
                            Através do nosso Serviço, é possível compartilhar publicar, vincular, armazenar,
                            compartilhar e
                            disponibilizar informações, textos, gráficos, vídeos e outros materiais (“Conteúdo”)..
                            Podemos
                            conter links para sites de terceiros, mas não controlamos seu conteúdo.
                        </div>
                        <div style={styles.text}>
                            Alterações nos Termos
                        </div>
                        <div style={styles.text}>
                            Reservamos o direito de modificar estes Termos a qualquer momento. Mudanças significativas
                            serão
                            comunicadas com antecedência.
                        </div>
                        <div style={styles.subtitle}>
                            Entre em Contato
                        </div>
                        <div style={styles.text}>
                            Se tiver dúvidas sobre estes Termos, entre em contato conosco por meio do
                            contato@amplifyus.app
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {/*checkbox to say you have read the terms*/}
                        <input type="checkbox" id="acceptTerms" name="acceptTerms" value="acceptTerms"
                               onChange={() => setHasAcceptedTerms(!hasAcceptedTerms)} />
                        <label htmlFor="acceptTerms">{t("HAVE_READ_TERMS")}</label>
                        <Button color="primary" onClick={handleAccept} disabled={!hasAcceptedTerms}>
                            {t("GENERAL.CONFIRM")}
                        </Button>
                    </Modal.Footer>
                </>
            </Modal>
        </>
    );
}

const styles = {
    maintitle: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginBottom: "1rem"
    },
    subtitle: {
        fontSize: "1rem",
        fontWeight: "bold",
        marginBottom: "1rem"
    },
    text: {
        fontSize: "1rem",
        marginBottom: "1rem"
    }
};

