/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { Logout, AuthPage, useAuth } from "../modules/auth";
import { App } from "../App";
import { SocialLogin } from "../modules/auth/screens/SocialLogin";
import { SpotifyCallbackHandler } from "../modules/auth/socialCallbackHandlers/SpotifyCallbackHandler";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => {
    const { currentUser } = useAuth();
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<App />}>
                    <Route path="error/*" element={<ErrorsPage />} />
                    <Route path="logout" element={<Logout />} />
                    <Route path="social-login" element={<SocialLogin />} />
                    {/* Callback handler de login com spotify */}
                    <Route
                        path="login/callback-spotify"
                        element={<SpotifyCallbackHandler />}
                    />
                    {/* Rotas privadas caso o usuário esteja autenticado, e de login caso contrário */}
                    {currentUser ? (
                        <>
                            <Route path="/*" element={<PrivateRoutes />} />
                        </>
                    ) : (
                        <>
                            <Route path="auth/*" element={<AuthPage />} />
                            <Route path="*" element={<Navigate to="/auth" />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export { AppRoutes };
