import React, { useState } from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Progress,
} from "reactstrap";
import { useTranslate } from "../../../../../../helpers/i18n/useTranslate";

function StepByStepFacebookLoginModal({ isOpen, toggle, loginFacebook }) {
    const { t } = useTranslate();
    const [step, setStep] = useState(1);

    const nextStep = () => {
        if (step < 5) {
            setStep(step + 1);
        }
    };

    const previousStep = () => {
        if (step > 1) {
            setStep(step - 1);
        }
    };

    return (
        <>
            <Modal isOpen={isOpen} toggle={toggle}>
                <ModalHeader toggle={toggle}>
                    {t("GENERAL.INSTAGRAM.BUSINESS.TUTORIAL.TITLE")}
                </ModalHeader>
                <ModalBody>
                    <Progress value={(step / 5) * 100} />
                    <div className="mt-2">
                        {step === 1 && (
                            <p>
                                {t(
                                    "GENERAL.INSTAGRAM.BUSINESS.TUTORIAL.STEP-1"
                                )}
                            </p>
                        )}
                        {step === 2 && (
                            <p>
                                {t(
                                    "GENERAL.INSTAGRAM.BUSINESS.TUTORIAL.STEP-2"
                                )}
                            </p>
                        )}
                        {step === 3 && (
                            <p>
                                {t(
                                    "GENERAL.INSTAGRAM.BUSINESS.TUTORIAL.STEP-3"
                                )}
                            </p>
                        )}
                        {step === 4 && (
                            <p>
                                {t(
                                    "GENERAL.INSTAGRAM.BUSINESS.TUTORIAL.STEP-4"
                                )}
                            </p>
                        )}
                        {step === 5 && (
                            <p>
                                {t(
                                    "GENERAL.INSTAGRAM.BUSINESS.TUTORIAL.STEP-5"
                                )}
                            </p>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter>
                    {step > 1 && (
                        <Button color="primary" onClick={previousStep}>
                            {t("INTRO.BACK")}
                        </Button>
                    )}
                    {step < 5 && (
                        <Button color="primary" onClick={nextStep}>
                            {t("INTRO.NEXT")}
                        </Button>
                    )}
                    {step === 5 && (
                        <Button color="primary" onClick={loginFacebook}>
                            {t("INTRO.CONNECT")}
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
}

export default StepByStepFacebookLoginModal;
