import React from "react";
import { Input } from "reactstrap";
import FormField from "../../FormField";

const FormTimeInput = React.forwardRef(
    (
        {
            className,
            label,
            fieldId,
            fieldName,
            validationError,
            required,
            helpText,
            autoFocus,
            value,
            onChange,
            disabled = false,
        },
        ref
    ) => {
        return (
            <FormField
                className={className}
                label={label}
                fieldName={fieldId || fieldName}
                validationError={validationError}
                required={required}
                helpText={helpText}
                inputComponent={
                    <Input
                        type="time"
                        name={fieldName}
                        id={fieldId || fieldName}
                        className={validationError && "input-error"}
                        autoFocus={autoFocus}
                        ref={ref}
                        value={value}
                        disabled={disabled}
                        onChange={onChange}
                    />
                }
            />
        );
    }
);

export default FormTimeInput;
