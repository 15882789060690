import React from "react";
import { FormGroup, Input, Label } from "reactstrap";

const FormCheckboxInput = React.forwardRef(
    (
        {
            className,
            label,
            fieldId,
            fieldName,
            validationError,
            required,
            helpText,
            autoFocus,
            value,
            onChange,
            disabled = false,
        },
        ref
    ) => {
        return (
            <FormGroup check>
                <Label check for={fieldId || fieldName}>
                    <Input
                        type="checkbox"
                        name={fieldName}
                        id={fieldId || fieldName}
                        className={validationError && "input-error"}
                        autoFocus={autoFocus}
                        ref={ref}
                        checked={value}
                        onChange={onChange}
                        disabled={disabled}
                    />{" "}
                    <span className="text-white">{label}</span>
                </Label>
            </FormGroup>
        );
    }
);

export default FormCheckboxInput;
