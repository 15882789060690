import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { useAlert } from "../../../helpers/utils/CustomAlert";
import { toast } from "react-toastify";

const initialErrors = { msg: [], fields: [] };

export function ResetPassword() {
  const { POST } = useApi();
  const { t } = useTranslate();
  const { alert, dialogs } = useAlert();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errors, setErrors] = useState(initialErrors);
  const [token, setToken] = useState();

  useEffect(() => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    setToken(params.token);
  }, []);

  useEffect(() => {
    if (!token) return;
    let data = new FormData();
    data.append("token", token);
    POST("/core/u/change-password/validate_token/", data).catch(() => {
      alert
        .fire(t("AUTH.GENERAL.RESET-TOKEN-EXPIRED"))
        .then(() => window.location.replace("forgot-password"));
    });
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (password !== passwordConfirm) {
      setErrors({
        msg: [t("AUTH.CONFIRM-PASSWORD-WRONG")],
        fields: ["passwordConfirm"],
      });
      setLoading(false);
      return;
    }
    let data = new FormData();
    data.append("token", token);
    data.append("password", password);
    await POST("/core/u/change-password/confirm/", data)
      .then((response) => {
        if (response.data.status === "OK") {
          alert
            .fire(t("AUTH.CONFIRM-PASSWORD-CHANGED"))
            .then(() => window.location.replace("/auth/login"));
        } else {
          toast.error(t("GENERAL.ERROR-PAGE.TITLE"));
        }
      })
      .catch((err) => {
        if (err.response.status < 500)
          setErrors({
            msg: Array.from(err.response.data.password),
            fields: ["password"],
          });
          toast.error(t("GENERAL.ERROR-PAGE.TITLE"));
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setErrors(initialErrors);
  }, [password, passwordConfirm]);

  return (
    <>
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_password_reset_form"
        onSubmit={handleSubmit}
      >
        <div className="text-center mb-10">
          <h1 className="text-dark mb-3">
            {t("AUTH.GENERAL.RESET-PASSWORD-TITLE")}
          </h1>
          <div className="text-gray-400 fw-bold fs-4">
            {t("AUTH.GENERAL.RESET-PASSWORD-DESCRIPTION")}
          </div>
        </div>

        {errors.msg.length > 0 && (
          <div className="mb-lg-15 alert alert-danger">
            {errors.msg.map((msg, index) => (
              <div
                key={`errmsg-${index}`}
                className="alert-text font-weight-bold"
              >
                {msg}
              </div>
            ))}
          </div>
        )}
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            {t("AUTH.GENERAL.RESET-PASSWORD-NEW")}
          </label>
          <div className="d-flex align-items-center py-0">
            <input
              type="password"
              className={clsx(
                "form-control form-control-lg form-control-solid py-0",
                { "is-invalid": errors.fields.includes("password") }
              )}
              placeholder=""
              autoComplete="off"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            {loading && (
              <div className="spinner-border spinner-border-sm align-middle ms-2"></div>
            )}
          </div>
        </div>
        <div className="fv-row mb-10">
          <label className="form-label fw-bolder text-gray-900 fs-6">
            {t("AUTH.INPUT.PASSWORD-CONFIRM")}
          </label>
          <div className="d-flex align-items-center py-0">
            <input
              type="password"
              className={clsx(
                "form-control form-control-lg form-control-solid py-0",
                { "is-invalid": errors.fields.includes("passwordConfirm") }
              )}
              placeholder=""
              autoComplete="off"
              onChange={(e) => setPasswordConfirm(e.target.value)}
              value={passwordConfirm}
            />
            {loading && (
              <div className="spinner-border spinner-border-sm align-middle ms-2"></div>
            )}
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-center pb-lg-0">
          <button
            type="submit"
            id="kt_password_reset_submit"
            className="btn btn-lg btn-primary fw-bolder me-4"
            disabled={loading}
          >
            <span className="indicator-label">
              {t("AUTH.GENERAL.SUBMIT_BUTTON")}
            </span>
          </button>
          <Link to="/auth/login">
            <button
              type="button"
              id="kt_login_password_reset_form_cancel_button"
              className="btn btn-lg btn-light-primary fw-bolder"
              disabled={loading}
            >
              {t("GENERAL.CANCEL")}
            </button>
          </Link>{" "}
        </div>
      </form>
    </>
  );
}
