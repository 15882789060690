import React from "react";
import { Input } from "reactstrap";
import FormField from "../../FormField";
import { useTranslate } from "../../../../../../../helpers/i18n/useTranslate";

const FormFileInput = React.forwardRef(
    (
        {
            className,
            label,
            fieldId,
            fieldName,
            validationError,
            required,
            helpText,
            onChange,
            currentFile,
            filename,
            removeFile,
            disabled = false,
            accept = "*",
        },
        ref
    ) => {
        const { t } = useTranslate();
        return (
            <>
                <FormField
                    className={className}
                    label={label}
                    fieldName={fieldId || fieldName}
                    validationError={validationError}
                    required={required}
                    helpText={helpText}
                    inputComponent={
                        <>
                            {currentFile && (
                                <div>
                                    {t("APPONI.LABELFORM.current")}:{" "}
                                    <a
                                        href={currentFile}
                                        target="_blank"
                                        id={fieldId || fieldName}
                                        ref={ref}
                                    >
                                        {filename}
                                    </a>
                                    <br />
                                    {removeFile && (
                                        <button
                                            onClick={removeFile}
                                            style={{ color: "red" }}
                                        >
                                            {t("APPONI.LABELFORM.remove")}
                                        </button>
                                    )}
                                    <br />
                                    {t("APPONI.LABELFORM.modify")}:
                                </div>
                            )}
                            <Input
                                type="file"
                                name={fieldName}
                                id={fieldId || fieldName}
                                className={validationError && "input-error"}
                                disabled={disabled}
                                accept={accept}
                                onChange={onChange}
                            />
                        </>
                    }
                />
            </>
        );
    }
);

export default FormFileInput;
