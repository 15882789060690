import { useCreateButton } from "../../helpers/subHeaderButtons/createButtonProvider";
import { useTranslate } from "../../helpers/i18n/useTranslate";
// import { LoadingSpinner } from "../../components/LoadingSpinner";
import React, { useEffect, useState } from "react";
import { useAuth } from "../auth";
import { useApi } from "../../helpers/Api/ApiWrapper";
import { GoToHealthCheckWidget } from "./components/GoToHealthCheckWidget";
import { PageTitle } from "../../layout/core";
import { LatestCoursesWidget } from "./components/LatestCoursesWidget";
import { TrendsWidget } from "./components/TrendsWidget";
import { CustomCarousel } from "../../components/CustomCarousel";
import { Intro } from "../../components/Intro";
import { CountdownProjects } from "./components/CountdownProjects";
import { OnesheetsWidget } from "./components/OnesheetsWidget";
import "./DashboardPage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebook,
    faInstagram,
    faSpotify,
    faTiktok,
    faXTwitter,
    faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { DataChartWidget } from "./components/charts/DataChartWidget";
import { Spinner, Tab, Tabs } from "next2";
import AuthenticationModal from "../../components/Modals/AuthenticationModal/AuthenticationModal";

const Dashboard = () => {
    const { t } = useTranslate();
    const { currentArtist, currentUser, currentClient } = useAuth();
    const { GET } = useApi();
    const [latestCourses, setLatestCourses] = useState([]);
    const [steps, setSteps] = useState();

    useEffect(() => {
        if (currentClient && currentUser?.hasAcceptedTerms) {
            let tempSteps = [];
            // Elemento dashboard todos tem acesso
            tempSteps.push({
                element: "#intro-dashboard",
                title: t("MENU.DASHBOARD"),
                intro: t("INTRO.DASHBOARD"),
                position: "bottom",
                tooltipClass: "bg-body",
            });
            // Elemento convites depende do plano
            if (currentClient.available_features.includes("INV"))
                tempSteps.push({
                    element: "#intro-invites",
                    title: t("APPS.INVITES.INVITES"),
                    intro: t("INTRO.INVITE"),
                    position: "bottom",
                    tooltipClass: "bg-body",
                });
            // Elemento de análises depende do plano
            if (
                currentClient.available_features.includes("DTSCI") &&
                [130].includes(currentArtist?.id)
            )
                tempSteps.push({
                    element: "#intro-feed",
                    title: t("APPS.FEEDSOCIAL.TITLE"),
                    intro: t("INTRO.FEEDSOCIAL"),
                    position: "bottom",
                    tooltipClass: "bg-body",
                });
            // Elemento academy depende do plano
            if (currentClient.available_features.includes("KBASE"))
                tempSteps.push({
                    element: "#intro-academy",
                    title: t("APPS.KNOWLEDGEBASE.ACADEMY"),
                    intro: t("INTRO.ACADEMY"),
                    position: "bottom",
                    tooltipClass: "bg-body",
                });
            // Elemento onesheet todos tem acesso
            tempSteps.push({
                element: "#intro-onesheet",
                title: t("APPS.ONESHEETS.ONESHEET"),
                intro: t("INTRO.ONESHEETS"),
                position: "bottom",
                tooltipClass: "bg-body",
            });
            // Elemento projetos todos tem acesso
            tempSteps.push({
                element: "#intro-projects",
                title: t("APPS.CORE.PROJECTS"),
                intro: t("INTRO.PROJECTS"),
                position: "bottom",
                tooltipClass: "bg-body",
            });
            // Elemento trends todos tem acesso
            tempSteps.push({
                element: "#intro-trends",
                title: t("APPS.DATASCIENCE.TRENDS.TITLE"),
                intro: t("INTRO.TRENDS"),
                position: "bottom",
                tooltipClass: "bg-body",
            });
            // Elemento health check todos tem acesso
            tempSteps.push({
                element: "#intro-healthcheck",
                title: t("APPS.CORE.ACCOUNT.HC"),
                intro: t("INTRO.HEALTH-CHECK"),
                position: "bottom",
                tooltipClass: "bg-body",
            });
            // Elemento gráficos todos tem acesso
            tempSteps.push({
                element: "#intro-charts",
                title: t("INTRO.TITLE.SPOTIFY-ANALYTICS"),
                intro: t("INTRO.SPOTIFY-ANALYTICS"),
                position: "bottom",
                tooltipClass: "bg-body",
            });
            setSteps(tempSteps);
        }
    }, [currentClient, currentUser?.hasAcceptedTerms]);

    /**
     * Pega os cursos em destaque pra colocar na dashboard. Iremos pegar os cursos em vídeo e ebooks que estiverem em
     * destaque e alternar entre eles para colocar no carrossel
     * @returns {Promise<void>}
     */
    const getFeaturedCourses = async () => {
        try {
            const coursesResponse = await GET("/academy/courses?featured=true");
            let featuredCourses = coursesResponse.data.results || [];
            setLatestCourses(featuredCourses);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getFeaturedCourses();
    }, []);

    useEffect(() => {
        if (currentArtist?.id == 96) window.location.replace("/invites");
    }, [currentArtist]);

    const spotifyDataChart = [
        {
            parameter: "playlist_reach",
            title: t("CHARTS.SPOTIFY.PLAYLIST-REACH.TITLE"),
            description: t("CHARTS.SPOTIFY.PLAYLIST-REACH.DESC"),
            label: t("CHARTS.SPOTIFY.PLAYLIST-REACH.LABEL"),
        },
        {
            parameter: "tracks_on_playlists",
            title: t("CHARTS.SPOTIFY.TRACKS-ON-PLAYLISTS.TITLE"),
            description: t("CHARTS.SPOTIFY.TRACKS-ON-PLAYLISTS.DESC"),
            label: t("CHARTS.SPOTIFY.TRACKS-ON-PLAYLISTS.LABEL"),
        },
        {
            parameter: "streams",
            title: t("CHARTS.SPOTIFY.STREAMS.TITLE"),
            description: t("CHARTS.SPOTIFY.STREAMS.DESC"),
            label: t("CHARTS.SPOTIFY.STREAMS.LABEL"),
        },
        {
            parameter: "fanbase",
            title: t("CHARTS.SPOTIFY.FANBASE.TITLE"),
            description: t("CHARTS.SPOTIFY.FANBASE.DESC"),
            label: t("CHARTS.SPOTIFY.FANBASE.LABEL"),
        },
    ];

    const facebookDataChart = [
        {
            parameter: "facebook_fanbase",
            title: t("CHARTS.FACEBOOK.FANBASE.TITLE"),
            description: t("CHARTS.FACEBOOK.FANBASE.DESC"),
            label: t("CHARTS.FACEBOOK.FANBASE.LABEL"),
        },
    ];
    const instagramDataChart = [
        {
            parameter: "instagram_fanbase",
            title: t("CHARTS.INSTAGRAM.FANBASE.TITLE"),
            description: t("CHARTS.INSTAGRAM.FANBASE.DESC"),
            label: t("CHARTS.INSTAGRAM.FANBASE.LABEL"),
        },
        {
            parameter: "instagram_likes",
            title: t("CHARTS.INSTAGRAM.LIKES.TITLE"),
            description: t("CHARTS.INSTAGRAM.LIKES.DESC"),
            label: t("CHARTS.INSTAGRAM.LIKES.LABEL"),
        },
    ];
    const youtubeDataChart = [
        {
            parameter: "youtube_fanbase",
            title: t("CHARTS.YOUTUBE.FANBASE.TITLE"),
            description: t("CHARTS.YOUTUBE.FANBASE.DESC"),
            label: t("CHARTS.YOUTUBE.FANBASE.LABEL"),
        },
        {
            parameter: "youtube_views",
            title: t("CHARTS.YOUTUBE.VIEWS.TITLE"),
            description: t("CHARTS.YOUTUBE.VIEWS.DESC"),
            label: t("CHARTS.YOUTUBE.VIEWS.LABEL"),
        },
    ];

    const tiktokDataChart = [
        {
            parameter: "tiktok_fanbase",
            title: t("CHARTS.TIKTOK.FANBASE.TITLE"),
            description: t("CHARTS.TIKTOK.FANBASE.DESC"),
            label: t("CHARTS.TIKTOK.FANBASE.LABEL"),
        },
        {
            parameter: "tiktok_likes",
            title: t("CHARTS.TIKTOK.LIKES.TITLE"),
            description: t("CHARTS.TIKTOK.LIKES.DESC"),
            label: t("CHARTS.TIKTOK.LIKES.LABEL"),
        },
    ];

    const twitterDataChart = [
        {
            parameter: "twitter_fanbase",
            title: t("CHARTS.TWITTER.FANBASE.TITLE"),
            description: t("CHARTS.TWITTER.FANBASE.DESC"),
            label: t("CHARTS.TWITTER.FANBASE.LABEL"),
        },
        {
            parameter: "twitter_likes",
            title: t("CHARTS.TWITTER.LIKES.TITLE"),
            description: t("CHARTS.TWITTER.LIKES.DESC"),
            label: t("CHARTS.TWITTER.LIKES.LABEL"),
        },
    ];

    const socialMediaDataChart = [
        {
            parameter: "social_media_performance_points",
            title: t("CHARTS.SOCIAL-MEDIA-SCORE.TITLE"),
            description: t("CHARTS.SOCIAL-MEDIA-SCORE.DESC"),
            label: t("CHARTS.SOCIAL-MEDIA-SCORE.LABEL"),
        },
    ];

    if (!currentUser) return null;
    else
        return (
            <>
                {!currentUser.hasAcceptedTerms && (
                    <AuthenticationModal currentUser={currentUser} />
                )}
                <PageTitle
                    translateTitle={false}
                    description={t("GENERAL.DASHBOARD.WELCOME.DESC").replace(
                        "$NAME$",
                        currentArtist
                            ? currentArtist.name
                            : currentClient?.manager_name
                    )}
                    userName={currentUser.first_name}
                >
                    {t("GENERAL.DASHBOARD.WELCOME")}
                </PageTitle>
                {currentArtist ? (
                    <div className="w-auto">
                        {steps && <Intro steps={steps} />}
                        
                        <div className="wrapper">
                            <div className="td">
                                <TrendsWidget />
                            </div>
                            <div className="hc">
                                <GoToHealthCheckWidget />
                            </div>
                            {/*currentClient?.available_features.includes("DTSCI") &&*/}
                            <div className="sf">
                                <div className="flex w-full flex-col">
                                    <Tabs
                                        color="primary"
                                        aria-label="Options"
                                        variant="light"
                                    >
                                        {/*The tab must show the spotify logo*/}
                                        <Tab
                                            key="spotify"
                                            title={
                                                <div className="flex items-center space-x-2">
                                                    <FontAwesomeIcon
                                                        icon={faSpotify}
                                                        color={"#1DB954"}
                                                        size={"xl"}
                                                    />
                                                    <span className="font-bold">
                                                        Spotify
                                                    </span>
                                                </div>
                                            }
                                        >
                                            <CustomCarousel
                                                className="h-full mb-4 md:mb-0 rounded-top-0"
                                                items={spotifyDataChart.map(
                                                    (item, index) => (
                                                        <DataChartWidget
                                                            key={index}
                                                            className="mb-xl-3 mt-0 "
                                                            // chart color spotify
                                                            chartColor="#1DB954"
                                                            chartHeight="150px"
                                                            platform="spotify"
                                                            icon={faSpotify}
                                                            iconColor="#1DB954"
                                                            parameter={
                                                                item.parameter
                                                            }
                                                            title={item.title}
                                                            description={
                                                                item.description
                                                            }
                                                            label={item.label}
                                                        />
                                                    )
                                                )}
                                            />
                                        </Tab>
                                        <Tab
                                            key="facebook"
                                            title={
                                                <div className="flex items-center space-x-2">
                                                    <FontAwesomeIcon
                                                        icon={faFacebook}
                                                        color={"#3b5998"}
                                                        size={"xl"}
                                                    />
                                                    <span className="font-bold">
                                                        Facebook
                                                    </span>
                                                </div>
                                            }
                                        >
                                            <CustomCarousel
                                                className="h-full mb-4 md:mb-0 rounded-top-0"
                                                items={facebookDataChart.map(
                                                    (item, index) => (
                                                        <DataChartWidget
                                                            key={index}
                                                            className="mb-xl-3 mt-0 rounded-top-0"
                                                            // facebook COLOR
                                                            chartColor="#3b5998"
                                                            chartHeight="150px"
                                                            platform="meta"
                                                            icon={faFacebook}
                                                            iconColor={
                                                                "#3b5998"
                                                            }
                                                            parameter={
                                                                item.parameter
                                                            }
                                                            title={item.title}
                                                            description={
                                                                item.description
                                                            }
                                                            label={item.label}
                                                        />
                                                    )
                                                )}
                                            />
                                        </Tab>
                                        <Tab
                                            key="instagram"
                                            title={
                                                <div className="flex items-center space-x-2">
                                                    <FontAwesomeIcon
                                                        icon={faInstagram}
                                                        color={"#C13584"}
                                                        size={"xl"}
                                                    />
                                                    <span className="font-bold">
                                                        Instagram
                                                    </span>
                                                </div>
                                            }
                                        >
                                            <CustomCarousel
                                                className="h-full mb-4 md:mb-0 rounded-top-0"
                                                items={instagramDataChart.map(
                                                    (item, index) => (
                                                        <DataChartWidget
                                                            key={index}
                                                            className="mb-xl-3 mt-0 rounded-top-0"
                                                            // instagram COLOR
                                                            chartColor="#C13584"
                                                            chartHeight="150px"
                                                            platform="meta"
                                                            icon={faInstagram}
                                                            iconColor={
                                                                "#C13584"
                                                            }
                                                            parameter={
                                                                item.parameter
                                                            }
                                                            title={item.title}
                                                            description={
                                                                item.description
                                                            }
                                                            label={item.label}
                                                        />
                                                    )
                                                )}
                                            />
                                        </Tab>
                                        <Tab
                                            key="tiktok"
                                            title={
                                                <div className="flex items-center space-x-2">
                                                    <FontAwesomeIcon
                                                        icon={faTiktok}
                                                        // tiktok COLOR
                                                        color={"#69C9D0"}
                                                        size={"xl"}
                                                    />
                                                    <span className="font-bold">
                                                        Tiktok
                                                    </span>
                                                </div>
                                            }
                                        >
                                            <CustomCarousel
                                                className="h-full mb-4 md:mb-0 rounded-top-0"
                                                items={tiktokDataChart.map(
                                                    (item, index) => (
                                                        <DataChartWidget
                                                            key={index}
                                                            className="mb-xl-3 mt-0 rounded-top-0"
                                                            // tiktok COLOR
                                                            chartColor="#69C9D0"
                                                            chartHeight="150px"
                                                            platform="tiktok"
                                                            icon={faTiktok}
                                                            iconColor={
                                                                "#69C9D0"
                                                            }
                                                            parameter={
                                                                item.parameter
                                                            }
                                                            title={item.title}
                                                            description={
                                                                item.description
                                                            }
                                                            label={item.label}
                                                        />
                                                    )
                                                )}
                                            />
                                        </Tab>
                                        <Tab
                                            key="youtube"
                                            title={
                                                <div className="flex items-center space-x-2">
                                                    <FontAwesomeIcon
                                                        icon={faYoutube}
                                                        color={"#FF0000"}
                                                        size={"xl"}
                                                    />
                                                    <span className="font-bold">
                                                        Youtube
                                                    </span>
                                                </div>
                                            }
                                        >
                                            <CustomCarousel
                                                className="h-full mb-4 md:mb-0 rounded-top-0"
                                                items={youtubeDataChart.map(
                                                    (item, index) => (
                                                        <DataChartWidget
                                                            key={index}
                                                            className="mb-xl-3 mt-0 rounded-top-0"
                                                            // youtube COLOR
                                                            chartColor="#FF0000"
                                                            chartHeight="150px"
                                                            platform="youtube"
                                                            icon={faYoutube}
                                                            iconColor={
                                                                "#FF0000"
                                                            }
                                                            parameter={
                                                                item.parameter
                                                            }
                                                            title={item.title}
                                                            description={
                                                                item.description
                                                            }
                                                            label={item.label}
                                                        />
                                                    )
                                                )}
                                            />
                                        </Tab>
                                        <Tab
                                            key="twitter"
                                            title={
                                                <div className="flex items-center space-x-2">
                                                    <FontAwesomeIcon
                                                        icon={faXTwitter}
                                                        // twitter COLOR
                                                        color={"#000"}
                                                        size={"xl"}
                                                    />
                                                    <span className="font-bold">
                                                        Twitter
                                                    </span>
                                                </div>
                                            }
                                        >
                                            <CustomCarousel
                                                className="h-full mb-4 md:mb-0 rounded-top-0"
                                                items={twitterDataChart.map(
                                                    (item, index) => (
                                                        <DataChartWidget
                                                            key={index}
                                                            className="mb-xl-3 mt-0 rounded-top-0"
                                                            // twitter COLOR
                                                            chartColor="#1DA1F2"
                                                            chartHeight="150px"
                                                            platform="twitter"
                                                            icon={faXTwitter}
                                                            iconColor={"#000"}
                                                            parameter={
                                                                item.parameter
                                                            }
                                                            title={item.title}
                                                            description={
                                                                item.description
                                                            }
                                                            label={item.label}
                                                        />
                                                    )
                                                )}
                                            />
                                        </Tab>
                                        {/*<Tab*/}
                                        {/*    key="social"*/}
                                        {/*    title={*/}
                                        {/*        <div className="flex items-center space-x-2">*/}
                                        {/*            <FontAwesomeIcon*/}
                                        {/*                icon={faMusic}*/}
                                        {/*                // social COLOR*/}
                                        {/*                color={"#7c69d0"}*/}
                                        {/*                size={"xl"}*/}
                                        {/*            />*/}
                                        {/*            <span className="font-bold">*/}
                                        {/*                Social*/}
                                        {/*            </span>*/}
                                        {/*        </div>*/}
                                        {/*    }*/}
                                        {/*>*/}
                                        {/*    <CustomCarousel*/}
                                        {/*        className="h-full mb-4 md:mb-0 rounded-top-0"*/}
                                        {/*        items={socialMediaDataChart.map(*/}
                                        {/*            (item, index) => (*/}
                                        {/*                <DataChartWidget*/}
                                        {/*                    key={index}*/}
                                        {/*                    className="mb-xl-3 mt-0 rounded-top-0"*/}
                                        {/*                    // social COLOR*/}
                                        {/*                    chartColor="#7c69d0"*/}
                                        {/*                    chartHeight="150px"*/}
                                        {/*                    platform="social"*/}
                                        {/*                    icon={faMusic}*/}
                                        {/*                    iconColor={*/}
                                        {/*                        "#7c69d0"*/}
                                        {/*                    }*/}
                                        {/*                    parameter={*/}
                                        {/*                        item.parameter*/}
                                        {/*                    }*/}
                                        {/*                    title={*/}
                                        {/*                        item.title*/}
                                        {/*                    }*/}
                                        {/*                    description={*/}
                                        {/*                        item.description*/}
                                        {/*                    }*/}
                                        {/*                    label={*/}
                                        {/*                        item.label*/}
                                        {/*                    }*/}
                                        {/*                />*/}
                                        {/*            )*/}
                                        {/*        )}*/}
                                        {/*    />*/}
                                        {/*</Tab>*/}
                                    </Tabs>
                                </div>
                            </div>
                            <div className="rl">
                                <CountdownProjects />
                            </div>
                            <div className="cs">
                                <LatestCoursesWidget
                                    className="h-[225px] rounded-lg"
                                    courses={latestCourses}
                                />
                            </div>

                            <div className="os">
                                {currentClient?.available_features.includes(
                                    "ONSHE"
                                ) && (
                                    <div className="">
                                        <OnesheetsWidget className="h-[90px]" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="w-auto">
                        <div className="wrapper withoutCurrentArtist">
                            <div className="td">
                                {/*currentClient?.available_features.includes("DTSCI") &&*/}
                                <TrendsWidget />
                            </div>
                            <div className="hc">
                                <GoToHealthCheckWidget />
                            </div>
                            <div className="cs">
                                {latestCourses?.length > 0 ? (
                                    <LatestCoursesWidget
                                        className="h-[225px] rounded-lg"
                                        courses={latestCourses}
                                    />
                                ) : (
                                    <div className="w-full h-full flex items-center justify-center">
                                        <Spinner />
                                    </div>
                                )}
                            </div>
                            <div className="rl">
                                <CountdownProjects className="h-250px" />
                            </div>
                            <div className="os">
                                {currentClient?.available_features.includes(
                                    "ONSHE"
                                ) && (
                                    <div className="">
                                        <OnesheetsWidget className="h-[90px]" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
};

const DashboardPage = () => {
    const { setHref } = useCreateButton();
    const [loading, setLoading] = useState(true);
    setHref("");

    useEffect(() => {
        setLoading(false);
    }, []);

    return <>{loading ? <Spinner /> : <Dashboard />}</>;
};

export { DashboardPage };
