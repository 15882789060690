import React from "react";
import { Input, Tooltip } from "reactstrap";
import FormField from "../../FormField";
import { useTranslate } from "../../../../../../../helpers/i18n/useTranslate";

const FormDateInput = React.forwardRef(
    (
        {
            className,
            label,
            fieldId,
            fieldName,
            validationError,
            required,
            helpText,
            autoFocus,
            value,
            onChange,
            disabled = false,
        },
        ref
    ) => {
        const { t } = useTranslate();
        const [tooltipOpen, setTooltipOpen] = React.useState(false);
        const toggle = (open) => {
            if (open !== tooltipOpen) setTooltipOpen(open);
        };
        return (
            <>
                <FormField
                    className={className}
                    label={label}
                    fieldName={fieldId || fieldName}
                    validationError={validationError}
                    required={required}
                    helpText={helpText}
                    inputComponent={
                        <Input
                            type="date"
                            name={fieldName}
                            id={fieldId || fieldName}
                            required={required}
                            className={validationError && "input-error"}
                            autoFocus={autoFocus}
                            disabled={disabled}
                            onKeyDown={(e) => {
                                e.preventDefault();
                                toggle(true);
                                return false;
                            }}
                            ref={ref}
                            value={value}
                            onChange={(evt) => {
                                toggle(false);
                                onChange(evt);
                            }}
                        />
                    }
                />
                <Tooltip
                    placement="top"
                    autohide={false}
                    isOpen={tooltipOpen}
                    target={fieldId || fieldName}
                    toggle={() => setTimeout(() => toggle(false), 5000)}
                >
                    <span style={{ color: "#c98838" }}>
                        {t("APPONI.LABELFORM.click_calendar_msg")}
                    </span>
                </Tooltip>
            </>
        );
    }
);

export default FormDateInput;
