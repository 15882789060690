import { Link } from "react-router-dom";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrush } from "@fortawesome/pro-light-svg-icons";
import { Card, CardBody } from "next2";
import { CardTitle } from "../../../components/Cards/CardTitle";

const OnesheetsWidget = ({ className }) => {
    const { t } = useTranslate();
    return (
        <Card className="p-8 h-full bg-body">
            <CardBody className="p-0">
                <Link to={"/onesheet"} className={"p-0"}>
                    <div
                        className={`${className} flex flex-col justify-center items-center text-white`}
                    >
                        <FontAwesomeIcon icon={faBrush} size={"2xl"} className="mb-4" />
                        <CardTitle text={t("APPS.ONESHEETS.TITLE")} />
                    </div>
                </Link>
            </CardBody>
        </Card>
    );
};

export { OnesheetsWidget };
