import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

export default function Warning({ title, description }) {
    return (
        <div className="d-flex align-items-center">
            <div>
                <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="orange"
                    size="2xl"
                    className="me-3"
                />
            </div>
            <div>
                <span className="text-dark fw-bold fs-5">{title}</span>
                <br />
                <span>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: description,
                            }}
                        />
                    </span>
            </div>
        </div>
    );
}
