import { MenuItem } from "./MenuItem";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { useAuth } from "../../../modules/auth";

export function MenuInner() {
    const { t } = useTranslate();
    const { currentClient, currentArtist } = useAuth();

    const menus = {
        ONSHE: {
            title: "APPS.ONESHEETS.TITLE",
            path: "onesheet",
            id: "intro-onesheet",
        },
        CTLOG: {
            title: "APPS.CORE.PROJECTS",
            path: "projects",
            id: "intro-projects",
        },
        KBASE: {
            title: "APPS.KNOWLEDGEBASE.ACADEMY",
            path: "academy",
            id: "intro-academy",
        },
        INV: {
            title: "APPS.INVITES.INVITES",
            path: "invites",
            id: "intro-invites",
        },
        DTSCI: {
            title: "APPS.DATASCIENCE.FEED",
            path: "datascience",
            id: "intro-feed",
        },
    };

    return (
        <>
            {/* Todos tem acesso a dashboard, é a pagina inicial */}
            {currentArtist?.id != 96 && (
                <MenuItem
                    id={"intro-dashboard"}
                    title={t("MENU.DASHBOARD")}
                    to={"/dashboard"}
                />
            )}
            {/* Agora são os menus específicos do plano assinado */}
            {currentClient?.available_features.map((module, index) => {
                if (!menus[module]) return null;
                if (module === "DTSCI" && ![130].includes(currentArtist?.id))
                    return null;
                return (
                    <MenuItem
                        id={menus[module].id}
                        key={index}
                        title={t(menus[module].title)}
                        to={menus[module].path}
                        className={menus[module].className}
                    />
                );
            })}
        </>
    );
}
