import React, { useEffect, useRef, useState } from "react";
import {
    bytesToMB,
    dateStrToHtml,
    getFilenameFromStringPath,
    isNotEmpty,
    mBToBytes,
    removeNullDataFromDict,
    urlToFile,
} from "../../../../helpers/usefulFunctions";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import FormTextInput from "../AppOniComponents/Forms/Inputs/FormTextInput";
import FormField from "../AppOniComponents/Forms/FormField";
import { AsyncTypeahead, Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import FormDateInput from "../AppOniComponents/Forms/Inputs/FormDateInput";
import FormImageInput from "../AppOniComponents/Forms/Inputs/FormImageInput";
import FormFileInput from "../AppOniComponents/Forms/Inputs/FormFileInput";
import Separator from "../AppOniComponents/Forms/FormGroupSeparator";
import FormTimeInput from "../AppOniComponents/Forms/Inputs/FormTimeInput";
import FormTextArea from "../AppOniComponents/Forms/Inputs/FormTextArea";
import Button from "../AppOniComponents/Button";
import Warning from "../AppOniComponents/Warning";
import { useTranslate } from "../../../../helpers/i18n/useTranslate";
import { useAlert } from "../../../../helpers/utils/CustomAlert";
import { useAppOniApi } from "../../../../helpers/Api/AppOniApiWrapper";
import { useAuth } from "../../../auth";
import { makeFormDataBodyForAppOni } from "../../../../helpers/utils/appOniHelpers";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { CustomSaveButton } from "../../../../components/CustomSaveButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { Accordion, AccordionItem } from "next2";
import CreatableSelect from "react-select/creatable";

const itemClasses = {
    base: "py-0 w-full",
    title: "font-bold text-xl text-white",
    trigger:
        "px-2 py-0 data-[hover=false]:bg-default-100 rounded-lg h-14 flex items-center",
    indicator: "text-medium",
    content: "text-small px-2",
};

/**
 * Renderiza a parte do objeto LabelProduct do formulário de label
 * @param {function} setFormPhase Funcao que renderiza os estágios do formulário corretamente
 * @param {function} setForm Setter do formulário geral
 * @param {object} form state do formulário geral
 * @param {String} labelId id da label, em caso de edicao
 * @param {String} projectId id do projeto correspondente à label
 * @returns {JSX.Element}
 * @constructor
 */
export default function ProductForm({
    setFormPhase,
    setForm,
    form,
    labelId,
    projectId,
}) {
    const { t } = useTranslate();
    const { alert } = useAlert();
    const { currentArtist, currentClient } = useAuth();
    const { apponi_get, apponi_post } = useAppOniApi();

    // Opções para o campo type (que equivale ao formato do produto)
    const formatOptions = [
        { value: "ALB", label: t("APPONI.LABELFORM.album") },
        {
            value: "EP",
            label: t("APPONI.LABELFORM.ep"),
        },
        { value: "SIN", label: t("APPONI.LABELFORM.single") },
    ];
    // Opções para o campo media
    const mediaOptions = [
        { value: "AUVD", label: t("APPONI.LABELFORM.label_media_auvd") },
        { value: "AUDI", label: t("APPONI.LABELFORM.label_media_audi") },
        { value: "VIDE", label: t("APPONI.LABELFORM.label_media_vide") },
    ];
    const languageOptions = [
        { value: "PT", label: t("APPONI.LABELFORM.portuguese") },
        { value: "EN", label: t("APPONI.LABELFORM.english") },
        { value: "SP", label: t("APPONI.LABELFORM.spanish") },
    ];

    const [formSending, setFormSending] = useState(false);
    const [loading, setLoading] = useState(true);

    const holderInputRef = useRef(null);
    const dateInputRef = useRef(null);
    const languageInputRef = useRef(null);
    const upcInputRef = useRef(null);
    const stCoverRef = useRef(null);
    const stTrackRef = useRef(null);
    const coverRef = useRef(null);
    const docsRef = useRef(null);

    const [validationErrors, setValidationErrors] = useState({});

    const [labelData, setLabelData] = useState({
        id: "",
        product: "",
        upc: "",
        title: "",
        main_interpreter: currentArtist
            ? { label: currentArtist.name, value: currentArtist.name }
            : "",
        release_date: "",
        video_release_time: "",
        audio_release_time: "",
        type: ["SIN"],
        product_media: ["AUVD"],
        audio_language: ["PT"],
        version: "",
        language: t("APPONI.LABELFORM.portuguese"),
        holder_text: "",
        extras_datasheet: "",
        extras_bio: "",
        extras_notes: "",
        project_model: "",
        approved: false,
        documents: "",
        media_links: "",
        cover: "",
        copyright_text_label: "",
        // preview_start_time: '',
        onimusic_network_comm_date: "",
        content_delivery_dates: "",
        gender: "",
        subgender: "",
        primary_artists: [],
        featuring_artists: [],
        sticker_teaser_cover: "",
        sticker_teaser_audio_track: "",
        amplifyus_id: "",
    }); // Campos da label

    const [holderIsLoading, setHolderIsLoading] = useState(false);
    const [holderOptions, setHolderOptions] = useState([]);
    const [artistIsLoading, setArtistIsLoading] = useState(false);
    const [artistOptions, setArtistOptions] = useState([]);
    const [coverFilename, setCoverFilename] = useState("");
    const [docsFilename, setDocsFilename] = useState("");
    const [stCoverFilename, setSTCoverFilename] = useState("");
    const [stTrackFilename, setSTTrackFilename] = useState("");

    const [copyrightHolders, setCopyrightHolders] = useState([
        { name: "", percentage: "" },
    ]);
    const [collabArtistsSelected, setCollabArtistsSelected] = useState(
        currentArtist
            ? [{ label: currentArtist.name, value: currentArtist.name }]
            : []
    ); // Usado no createable select de artistas colab
    const [featArtistsSelected, setFeatArtistsSelected] = useState([]); // Usado no createable select de artistas feat

    const fieldRefsCorrespondence = {
        cover: setCoverFilename,
        documents: setDocsFilename,
        sticker_teaser_cover: setSTCoverFilename,
        sticker_teaser_audio_track: setSTTrackFilename,
    };

    /**
     * Faz a chamada de API pra pegar as opcoes de titular do app oni para preenchimento de label no modo gravadora
     * @returns {[{label: string, value: number}]} lista de titulares
     */
    function getHolderOptions() {
        setLoading(true);
        let tempHolderOptions = [{ value: 0, label: "-------" }];
        apponi_get("catalog/api/holders/")
            .then((response) => {
                let holders_from_api = response.data.results;
                let holder = null;
                for (let i = 0; i < holders_from_api.length; i++) {
                    holder = holders_from_api[i];
                    tempHolderOptions.push({
                        value: holder.id,
                        label: holder.name,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setLoading(false));
        return tempHolderOptions;
    }

    /**
     * Possibilita remover arquivos de campos cujo arquivo é opcional
     */
    const removeFile = (e, field) => {
        e.preventDefault();
        setLabelData({ ...labelData, [field]: "" });
        fieldRefsCorrespondence[field](""); // Reseta o filename do ref correspondente ao field indicado
    };

    /**
     * Carrega os dados de uma label já existente
     */
    function loadLabelData() {
        apponi_get(`catalog/api/labels/${labelId}`)
            .then((response) => {
                let data = removeNullDataFromDict(response.data);
                data.release_date = dateStrToHtml(data.release_date);
                data.onimusic_network_comm_date = dateStrToHtml(
                    data.onimusic_network_comm_date
                );

                // Carregamento de intérpretes do backend para preencher os createable select
                let interpreterSplittedStrings =
                    data.main_interpreter?.split(" feat. ");
                let splitInterpreterNames =
                    interpreterSplittedStrings[0]?.split(", ");
                let splitFeatNames = interpreterSplittedStrings[1]?.split(", ");
                let interpreters = (splitInterpreterNames || []).map(
                    (name) => ({
                        label: name,
                        value: name,
                    })
                );
                let feats = (splitFeatNames || []).map((name) => ({
                    label: name,
                    value: name,
                }));
                setCollabArtistsSelected(interpreters);
                setFeatArtistsSelected(feats);

                setLabelData({
                    id: data.id,
                    product: data.product,
                    holder: [
                        { value: data.holder.id, label: data.holder.name },
                    ],
                    upc: data.upc,
                    title: data.title,
                    main_interpreter: data.main_interpreter,
                    release_date: data.release_date,
                    video_release_time: data.video_release_time,
                    audio_release_time: data.audio_release_time,
                    type: [data.type],
                    product_media: [data.product_media],
                    audio_language: [data.audio_language],
                    version: data.version,
                    language: data.language,
                    holder_text: data.holder_text,
                    extras_datasheet: data.extras_datasheet,
                    extras_bio: data.extras_bio,
                    extras_notes: data.extras_notes,
                    project_model: data.project_model,
                    approved: data.approved,
                    documents: data.documents,
                    label_creator: data.label_creator,
                    amplifyus_id: data.amplifyus_id,
                    media_links: data.media_links,
                    cover: data.cover,
                    cover_for_humans: data.cover_for_humans,
                    copyright_text_label: data.copyright_text_label,
                    // preview_start_time: data.preview_start_time,
                    onimusic_network_comm_date: data.onimusic_network_comm_date,
                    content_delivery_dates: data.content_delivery_dates,
                    gender: data.gender,
                    subgender: data.subgender,
                    primary_artists: data.primary_artists_for_typeahead,
                    featuring_artists: data.featuring_artists_for_typeahead,
                    sticker_teaser_cover: data.sticker_teaser_cover,
                    sticker_teaser_audio_track: data.sticker_teaser_audio_track,
                });
            })
            .catch((err) => {
                alert(t("APPONI.LABELFORM.fatal_error"));
            })
            .finally(() => setTimeout(() => setLoading(false), 1500));
    }

    /**
     * Carrega informacoes necessarias ao form de label, tais como dados da label existente(em caso de edicao),
     * id do titular da label, opcoes para o Select de titular (caso seja um colaborador preenchendo).
     */
    useEffect(() => {
        // Se estiver no modo gravadora, tem que renderizar o select de titulares
        if (!currentArtist?.id) setHolderOptions(getHolderOptions());
        // Se tivermos labelId nos parâmetros da URL, trata-se de uma edição de label, então aqui dentro vamos carregar
        //  os campos de acordo com as informações do BD, trazidas pela API
        if (labelId) loadLabelData();
        else setLoading(false);
    }, []);

    /*
     * Carrega nomes de arquivos para melhorar a visibilidade do form. Isso tem que ser feito depois que os
     * dados da label forem carregados, e só faz isso caso seja um form de edição.
     */
    useEffect(() => {
        if (labelData.approved) {
            alert
                .fire(t("APPONI.LABELFORM.label_is_approved_restriction"))
                .then(() =>
                    window.location.replace(`/projects/dt/${projectId}`)
                );
        }
        // Define o nome do arquivo atual presente nos campos de arquivo ao carregar o form de edição
        if (labelData.cover) {
            if (typeof labelData.cover === "string")
                setCoverFilename(getFilenameFromStringPath(labelData.cover));
            else setCoverFilename(labelData.cover.name); // Se quiser pegar o tamanho do arquivo, acesse com .size. O retorno é em bytes
        }
        if (labelData.documents) {
            if (typeof labelData.documents === "string")
                setDocsFilename(getFilenameFromStringPath(labelData.documents));
            else setDocsFilename(labelData.documents.name); // Se quiser pegar o tamanho do arquivo, acesse com .size. O retorno é em bytes
        }
        if (labelData.sticker_teaser_cover) {
            if (typeof labelData.sticker_teaser_cover === "string")
                setSTCoverFilename(
                    getFilenameFromStringPath(labelData.sticker_teaser_cover)
                );
            else setSTCoverFilename(labelData.sticker_teaser_cover.name); // Se quiser pegar o tamanho do arquivo, acesse com .size. O retorno é em bytes
        }
        if (labelData.sticker_teaser_audio_track) {
            if (typeof labelData.sticker_teaser_audio_track === "string")
                setSTTrackFilename(
                    getFilenameFromStringPath(
                        labelData.sticker_teaser_audio_track
                    )
                );
            else setSTTrackFilename(labelData.sticker_teaser_audio_track.name); // Se quiser pegar o tamanho do arquivo, acesse com .size. O retorno é em bytes
        }
    }, [labelData]);

    /**
     * Handler de edicao do formulário
     * @param evt
     */
    const handleChange = (evt) => {
        let name = evt.target.name;
        let value = evt.target.value;
        const file = evt.target.files;
        // Se for um arquivo, pega o fulano e joga no vale pra ser setado no state logo abaixo
        if (file) value = file[0];
        setLabelData({
            ...labelData,
            [name]: value,
        });
    };

    /**
     * Valida dimensões e tamanho da capa
     * @param value
     * @returns {boolean}
     */
    const validateCover = (value) => {
        let media = labelData.product_media[0];
        const fileSizeForEachMediaType = {
            AUVD: 5,
            AUDI: 5,
            VIDE: 2,
        };
        if (value.size > mBToBytes(fileSizeForEachMediaType[media])) {
            const sizeInMB = bytesToMB(value.size);

            // Se o tamanho do arquivo for maior que 1.5 MB, mostre um alerta
            alert.fire(
                `${t("APPONI.LABELFORM.file.size")} ${sizeInMB} ${t(
                    "APPONI.LABELFORM.file.size.max"
                ).replace("$SIZE$", fileSizeForEachMediaType[media])} `
            );
            return false;
        } else {
            // Se for upada uma capa, temos que validar as suas dimensões
            var img = document.createElement("img");
            img.onload = function () {
                // Registra dimensões permitidas para cada tipo de mídia de produto
                const dimensionsForEachMediaType = {
                    AUVD: [
                        [3000, 3000],
                        [1500, 1500],
                    ],
                    AUDI: [
                        [3000, 3000],
                        [1500, 1500],
                    ],
                    VIDE: [[1280, 720]],
                };
                // Registra as dimensões permitidas para a capa com base na mídia definida para esse produto
                const allowedCoverDimensions =
                    dimensionsForEachMediaType[media];
                if (
                    !allowedCoverDimensions.some((dimensions) =>
                        dimensions.every(
                            (val, index) =>
                                val === [this.width, this.height][index]
                        )
                    )
                ) {
                    // Se estiver inválida, mostre um alerta
                    alert.fire(
                        `${t("APPONI.LABELFORM.cover_dimensions_invalid")} (${
                            this.width
                        }x${this.height}). ${t(
                            "APPONI.LABELFORM.thumb_must_be"
                        )} ${t(
                            media === "VIDE"
                                ? "APPONI.LABELFORM.thumb_dimensions"
                                : "APPONI.LABELFORM.cover_dimensions"
                        )}`
                    );
                    return false;
                }
            };

            var reader = new FileReader();
            reader.onloadend = function (ended) {
                img.src = ended.target.result;
            };
            reader.readAsDataURL(value);
        }
        return true;
    };

    const handleCoverChange = (evt) => {
        const image = evt.target.files;
        if (!image) return;
        let value = image[0];
        if (!validateCover(value)) {
            // Limpe o campo de entrada de arquivo e adicione a classe de erro conforme necessário
            value = "";
            evt.target.value = "";
            evt.target.nextElementSibling.classList.add("validationErrors");
        } else {
            // Se a capa for válida, remova a classe de erro na mensagem abaixo do input
            evt.target.nextElementSibling.classList.remove("validationErrors");
        }
        // Atualiza o state dos dados conforme validação
        setLabelData({
            ...labelData,
            cover: value,
        });
    };

    const handleAddCopyrightHolder = (e) => {
        e.preventDefault();
        const newCopyrightolders = [
            ...copyrightHolders,
            {
                name: "",
                percentage: "",
                document: "",
                mode: "",
                obs: "",
            },
        ];
        setCopyrightHolders(newCopyrightolders);
    };

    const handleCopyrightHolderChange = (e, index) => {
        const { name, value } = e.target;
        const newHolders = [...copyrightHolders];
        newHolders[index][name] = value;
        setCopyrightHolders(newHolders);
    };

    const handleRemoveCopyrightHolder = (e, index) => {
        e.preventDefault();
        const newCopyrightHolders = [...copyrightHolders];
        newCopyrightHolders.splice(index, 1);
        setCopyrightHolders(newCopyrightHolders);
    };

    /**
     * Faz o processamento dos campos que têm que ser enviado pro backend em formato diferente do
     * que estão no state do React.
     */
    function preprocessLabelFormData() {
        let formData = { ...labelData };
        formData.amplifyus_id = formData.amplifyus_id || projectId;
        formData.type = formData.type ? formData.type[0] : "";
        formData.holder = currentArtist
            ? currentArtist.appOniHolderId
            : formData.holder
            ? formData.holder[0]
            : "";
        formData.product_media = formData.product_media
            ? formData.product_media[0]
            : "";
        formData.audio_language = formData.audio_language
            ? formData.audio_language[0]
            : "";

        formData.primary_artists = formData.primary_artists
            ? formData.primary_artists.map((p_artist) => {
                  return p_artist.value;
              })
            : "";
        formData.featuring_artists = formData.featuring_artists
            ? formData.featuring_artists.map((f_artist) => {
                  return f_artist.value;
              })
            : "";
        // Formatação dos createable select pra transformar em uma string
        const collabArtistLabels = collabArtistsSelected.map(
            (item) => item.label
        );
        const featArtistsLabels = featArtistsSelected.map((item) => item.label);
        const collabArtistsString = collabArtistLabels.join(", "); // Adicionar uma "," a cada artista adicionado no input de select
        const featArtistsString = featArtistsLabels.join(", "); // Adicionar uma "," a cada artista adicionado no input de select
        // Junta os colab e os feat em uma string só
        formData.main_interpreter = isNotEmpty(featArtistsString)
            ? `${collabArtistsString} feat. ${featArtistsString}`
            : collabArtistsString;

        let copyrightData = "";
        copyrightData += "<ul>";
        for (let copyrightHolder of copyrightHolders) {
            if (copyrightHolder.name !== "") {
                copyrightData += `<li>${t("APPONI.LABELFORM.holder_name")}: ${t(
                    copyrightHolder.name
                )}</li>`;
                copyrightData += `<li>${t(
                    "APPONI.LABELFORM.holder_percentage"
                )}: ${t(copyrightHolder.percentage)}%</li>`;
                copyrightData += `<li>${t(
                    "APPONI.LABELFORM.holder_document"
                )}: ${t(copyrightHolder.document)}</li>`;
                copyrightData += `<li>${t("APPONI.LABELFORM.holder_mode")}: ${t(
                    copyrightHolder.mode
                )}</li>`;
                copyrightData += `<li>${t("APPONI.LABELFORM.holder_obs")}: ${t(
                    copyrightHolder.obs
                )}</li>`;
                copyrightData += "</br>";
            }
        }
        copyrightData += "</ul>";
        formData.holder_text = copyrightData;

        return formData;
    }

    /**
     * Monta o formData e faz a chamada de envio de informações para o backend do AppOni com commit=false, só pra
     * validar esses dados. O envio do form real oficial ocorre na próxima etapa do formulário
     */
    const submitForm = () => {
        setFormSending(true);
        setValidationErrors({});
        const formsetData = [
            {
                djangoFormsetPrefix: "labelasset",
                totalForms: 0,
                initialForms: 0,
                minNumForms: 0,
                maxNumForms: 1000,
                content: [],
            },
        ];
        let formattedLabelData = preprocessLabelFormData();
        let formData = makeFormDataBodyForAppOni(
            formattedLabelData,
            formsetData
        );
        let url;
        let id = labelId || formattedLabelData.id;
        // Se for uma edição, chama a Url com o id da label
        if (id) url = `catalog/apiv2/label/new-or-edit/${id}?commit=False`;
        // Se for criação, chama a Url sem id
        else url = "catalog/apiv2/label/new-or-edit?commit=False";
        apponi_post(url, formData)
            .then((response) => {
                response = response.data;
                // Se correu tudo certo e a label foi editada carrega as informações atualizadas no formulário
                if (response.status === 200 && response.data.items.length) {
                    setForm(formattedLabelData);
                    setFormPhase(2);
                } else showFormErrors(response); // Do contrário exibe os erros de validação do form
            })
            .catch((err) => {
                console.error(err);
                console.error(err.message);
            })
            .finally(() => setFormSending(false));
    };

    /**
     * Confere se a label tem documentos e chama a função que faz o POST na api para submeter o formulário.
     * Chamando esse método toda vez que o usuário clica no botão de prosseguir pras faixas, garantimos a
     * possibilidade de ele editar a label mesmo depois de chegar em fases mais adiantes do formulário, já
     * que o componente ProductForm fica renderizado no index.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        let canSubmit = true;
        // As primeiras validações barram o envio do formulário
        let coverFile = labelData.cover;
        if (typeof labelData.cover === "string") {
            coverFile = await urlToFile(labelData.cover, "cover", "jpg");
        }
        canSubmit = validateCover(coverFile);
        // Alerta de limite de artistas colab atingido. Barra o envio do formulário
        if (collabArtistsSelected.length > 3) {
            await alert.fire({
                icon: "warning",
                text: t("APPONI.LABELFORM.colab_limit_reached"),
            });
            canSubmit = false;
        }
        // Alerta de capa e documentos sendo o mesmo arquivo. Barra o envio do formulário
        if (coverFilename === docsFilename) {
            setValidationErrors({
                ...validationErrors,
                documents: t("APPONI.LABELFORM.COVER-IN-DOCS-FIELD"),
            });
            alert.fire(t("APPONI.LABELFORM.SAME-COVER-AND-DOCS"));
            canSubmit = false;
        }

        if (!canSubmit) return; // Fim das validações que barram o envio do formulário
        // Alerta de campos importantes não preenchidos. Não barra o envio do formulário
        if (
            labelData.gender === "" ||
            labelData.version === "" ||
            labelData.media_links === ""
        ) {
            await alert
                .fire({
                    icon: "warning",
                    title: t("APPONI.LABELFORM.warning_title"),
                    text: t("APPONI.LABELFORM.warning_text"),
                    confirmButtonText: t("GENERAL.CONTINUE"),
                    cancelButtonText: t("APPONI.LABELFORM.back_form"),
                    showCancelButton: true,
                })
                .then((result) => {
                    // Pode enviar o formulário se o usuário confirmar a ação
                    canSubmit = result.isConfirmed;
                });
        }
        // Se não entrou em nenhuma falha de validação, submete o formulário
        if (canSubmit) submitForm();
    };

    // função que trata os inputs com erro
    function handleInputsErrors(e) {
        const inputNameError = Object.keys(e); // retorna o nome de todos os campos com erro em uma array

        for (let field of inputNameError) {
            // input de Titular
            if (field === holderInputRef.current?.props.id) {
                holderInputRef.current.focus();
            }
            // input de Data de Lançamento
            if (field === dateInputRef.current?.props.id) {
                const dateInput = document.getElementById("release_date"); // nesse caso o useRef não funcionou com o focus, foi preciso utilizar getElementById
                dateInput.focus();
            }
            // input de Idioma
            if (field === languageInputRef.current?.props.id) {
                const formMoreData = document.getElementById("form-more-data"); // pega a div 'oculta' onde o input de idioma está
                const languageInput = document.getElementById("language"); // nesse caso o useRef não funcionou com o focus, foi preciso utilizar getElementById

                formMoreData.classList.add("show"); // caso o input de idioma tenha algum erro, primeiro ele dá um collapse
                languageInput.focus(); // e só depois dá o focus
            }
            // input de UPC
            if (field === upcInputRef.current?.props.id) {
                const formMoreData = document.getElementById("form-more-data"); // pega a div 'oculta' onde o input de idioma está
                const upcInput = document.getElementById("upc"); // nesse caso o useRef não funcionou com o focus, foi preciso utilizar getElementById

                formMoreData.classList.add("show"); // caso o input de idioma tenha algum erro, primeiro ele dá um collapse
                upcInput.focus(); // e só depois dá o focus
            }
            // input de capa
            if (
                coverRef.current &&
                field === coverRef.current?.getAttribute("id")
            ) {
                const coverInput = document.getElementById("cover");
                coverInput.focus();
            }
            // input de documentos
            if (
                docsRef.current &&
                field === docsRef.current?.getAttribute("id")
            ) {
                const docsInput = document.getElementById("documents");
                docsInput.focus();
            }
            // input de capa do sticker teaser
            if (
                stCoverRef.current &&
                field === stCoverRef.current?.getAttribute("id")
            ) {
                const stCoverInput = document.getElementById(
                    "sticker_teaser_cover"
                );
                stCoverInput.focus();
            }
            // input de áudio do sticker teaser
            if (
                stTrackRef.current &&
                field === stTrackRef.current?.getAttribute("id")
            ) {
                const stTrackInput = document.getElementById(
                    "sticker_teaser_audio_track"
                );
                stTrackInput.focus();
            }
        }
    }

    /*
     * Exibe os erros de validação retornados pelo backend no formulário.
     */
    const showFormErrors = (response) => {
        if (!response) {
            // Guard clause para caso a função seja chamada com um null em vez de uma response da api
            alert.fire(t("APPONI.LABELFORM.fatal_error"));
            return;
        }
        const inputErrors = { ...response.data.message };

        let alertMessage = t("APPONI.LABELFORM.validation_error_msg");

        const inputError = Object.keys(inputErrors);
        for (let error of inputError) {
            if (
                error === "holder" ||
                error === "main_interpreter" ||
                error === "title" ||
                error === "cover" ||
                (error === "release_date" && !labelData.release_date)
            ) {
                alertMessage = t("APPONI.LABELFORM.fill_all_required_fields");
            }
        }

        handleInputsErrors(inputErrors);
        setValidationErrors(inputErrors);
        alert.fire(alertMessage);
    };

    const HolderSearch = (inputValue) => {
        setHolderIsLoading(true);
        apponi_get(
            `catalog/apiv2/dynamic_search/?response_type=react-select&filtered_class=holder&search=${inputValue}`
        ).then((response) => {
            setHolderOptions(response.data.results);
            setHolderIsLoading(false);
        });
    };

    const ArtistsSearch = (inputValue) => {
        setArtistIsLoading(true);
        apponi_get(
            `catalog/apiv2/dynamic_search/?response_type=react-select&filtered_class=artist&search=${inputValue}`
        ).then((response) => {
            setArtistOptions(response.data.results);
            setArtistIsLoading(false);
        });
    };

    useEffect(() => {
        // Necessário pra definir o botão de transição da fase 2
        setForm({
            ...form,
            product_media: labelData.product_media[0],
            title: labelData.title || t("APPONI.LABELFORM.new_label"),
        });
    }, [labelData.product_media]);

    return (
        <>
            {loading ? (
                <LoadingSpinner size={"xl"} />
            ) : (
                <>
                    <Warning
                        title={t("APPONI.LABELFORM.label")}
                        description={t("APPONI.LABELFORM.labels_tutorial")}
                    />
                    <Separator y={3} />
                    <Form onSubmit={handleSubmit} id="label-form">
                        {/*<Input*/}
                        {/*    type="hidden"*/}
                        {/*    name="label_creator"*/}
                        {/*    value={labelData.label_creator}*/}
                        {/*    onChange={handleChange}*/}
                        {/*/>*/}
                        <Row className="mb-3">
                            {!currentArtist && (
                                <Col xs={12} lg={4}>
                                    <FormField
                                        fieldName="holder"
                                        label={t("APPONI.LABELFORM.holder")}
                                        validationError={
                                            validationErrors.holder
                                        }
                                        required
                                        inputComponent={
                                            <AsyncTypeahead
                                                ref={holderInputRef}
                                                filterBy={() => {
                                                    return true;
                                                }}
                                                id="holder"
                                                isLoading={holderIsLoading}
                                                labelKey="label"
                                                minLength={2}
                                                onSearch={HolderSearch}
                                                options={holderOptions}
                                                placeholder={t(
                                                    "APPONI.LABELFORM.type_to_search"
                                                )}
                                                clearButton
                                                onChange={(selected) =>
                                                    handleChange({
                                                        target: {
                                                            name: "holder",
                                                            value: selected,
                                                        },
                                                    })
                                                }
                                                selected={labelData.holder}
                                                className={
                                                    validationErrors.holder &&
                                                    "input-error"
                                                }
                                            />
                                        }
                                    />
                                </Col>
                            )}
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} lg={4}>
                                <FormTextInput
                                    fieldName="title"
                                    label={t("APPONI.LABELFORM.title")}
                                    validationError={validationErrors.title}
                                    value={labelData.title}
                                    onChange={handleChange}
                                    required
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <label className="text-lg">
                                    {t("APPONI.LABELFORM.interpreter")}
                                </label>
                                <CreatableSelect
                                    onChange={(item) =>
                                        setCollabArtistsSelected(item)
                                    }
                                    isMulti
                                    isClearable={true}
                                    isLoading={false}
                                    isDisabled={false}
                                    isSearchable={true}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#1e1e2d",
                                            primary: "black",
                                        },
                                    })}
                                    value={collabArtistsSelected}
                                />
                                <small>
                                    {t("APPONI.LABELFORM.colab_help")}
                                </small>
                            </Col>
                            <Col xs={12} lg={4}>
                                <label className="text-lg">
                                    {t("APPONI.LABELFORM.feat")}
                                </label>
                                <CreatableSelect
                                    onChange={(item) =>
                                        setFeatArtistsSelected(item)
                                    }
                                    isMulti
                                    isClearable={true}
                                    isLoading={false}
                                    isDisabled={false}
                                    isSearchable={true}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: "#1e1e2d",
                                            primary: "black",
                                        },
                                    })}
                                    value={featArtistsSelected}
                                />
                                <small>{t("APPONI.LABELFORM.feat_help")}</small>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} lg={4}>
                                <FormField
                                    fieldName="type"
                                    label={t("APPONI.LABELFORM.format")}
                                    required
                                    validationError={validationErrors.type}
                                    inputComponent={
                                        <Typeahead
                                            id="type"
                                            name="type"
                                            labelKey="label"
                                            selected={formatOptions.filter(
                                                (obj) => {
                                                    return (
                                                        obj.value ===
                                                        labelData.type[0]
                                                    );
                                                }
                                            )}
                                            onChange={(selected) => {
                                                if (undefined !== selected[0]) {
                                                    handleChange({
                                                        target: {
                                                            name: "type",
                                                            value: [
                                                                selected[0]
                                                                    .value,
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            options={formatOptions}
                                            filterBy={() => {
                                                return true;
                                            }}
                                        />
                                    }
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <FormField
                                    fieldName="product_media"
                                    label={t("APPONI.LABELFORM.media")}
                                    required
                                    validationError={
                                        validationErrors.product_media
                                    }
                                    inputComponent={
                                        <Typeahead
                                            id="product_media"
                                            name="product_media"
                                            labelKey="label"
                                            selected={mediaOptions.filter(
                                                (obj) => {
                                                    return (
                                                        obj.value ===
                                                        labelData
                                                            .product_media[0]
                                                    );
                                                }
                                            )}
                                            onChange={(selected) => {
                                                if (undefined !== selected[0]) {
                                                    handleChange({
                                                        target: {
                                                            name: "product_media",
                                                            value: [
                                                                selected[0]
                                                                    .value,
                                                            ],
                                                        },
                                                    });
                                                }
                                            }}
                                            options={mediaOptions}
                                            filterBy={() => {
                                                return true;
                                            }}
                                        />
                                    }
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <FormDateInput
                                    fieldName="release_date"
                                    label={t("APPONI.LABELFORM.release_date")}
                                    validationError={
                                        validationErrors.release_date
                                    }
                                    value={labelData.release_date}
                                    onChange={handleChange}
                                    required
                                    ref={dateInputRef}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormImageInput
                                    fieldName="cover"
                                    label={t("APPONI.LABELFORM.cover")}
                                    helpText={t(
                                        "APPONI.LABELFORM.label_cover_description"
                                    )}
                                    currentFile={labelData.cover}
                                    currentFileThumb={
                                        labelData.cover_for_humans
                                    }
                                    onChange={handleCoverChange}
                                    required={!labelData.cover}
                                    ref={coverRef}
                                    filename={coverFilename}
                                    validationError={validationErrors.cover}
                                />
                            </Col>
                            <Col xs={12} md={6}>
                                <FormFileInput
                                    fieldName="documents"
                                    label={t("APPONI.LABELFORM.documents")}
                                    helpText={t(
                                        "APPONI.LABELFORM.label_docs_description"
                                    )}
                                    currentFile={labelData.documents}
                                    onChange={handleChange}
                                    ref={docsRef}
                                    filename={docsFilename}
                                    validationError={validationErrors.documents}
                                    accept="image/*,.pdf,.doc,.txt,.odt,.docx,.rtf,.zip,.rar,.7z"
                                    removeFile={(e) =>
                                        removeFile(e, "documents")
                                    }
                                />
                            </Col>
                        </Row>

                        <Separator />

                        <Row className="mb-3">
                            <Col xs={12} lg={4}>
                                <FormTimeInput
                                    fieldName="video_release_time"
                                    label={t(
                                        "APPONI.LABELFORM.video_release_time"
                                    )}
                                    validationError={
                                        validationErrors.video_release_time
                                    }
                                    value={labelData.video_release_time}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <FormTextInput
                                    fieldName="gender"
                                    validationError={validationErrors.gender}
                                    value={labelData.gender}
                                    label={t("APPONI.LABELFORM.gender")}
                                    maxLength={25}
                                    onChange={handleChange}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <FormTextInput
                                    fieldName="subgender"
                                    validationError={validationErrors.subgender}
                                    value={labelData.subgender}
                                    label={t("APPONI.LABELFORM.subgender")}
                                    maxLength={25}
                                    onChange={handleChange}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} lg={4}>
                                <FormTextInput
                                    fieldName="version"
                                    label={t("APPONI.LABELFORM.version")}
                                    helpText={t(
                                        "APPONI.LABELFORM.label_version_description"
                                    )}
                                    value={labelData.version}
                                    onChange={handleChange}
                                    validationError={validationErrors.version}
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <FormDateInput
                                    label={t("APPONI.LABELFORM.contact_date")}
                                    fieldName="onimusic_network_comm_date"
                                    value={labelData.onimusic_network_comm_date}
                                    onChange={handleChange}
                                    validationError={
                                        validationErrors.onimusic_network_comm_date
                                    }
                                />
                            </Col>
                            <Col xs={12} lg={4}>
                                <FormTextArea
                                    label={t("APPONI.LABELFORM.media_links")}
                                    fieldName="media_links"
                                    helpText={t(
                                        "APPONI.LABELFORM.media_links_description"
                                    )}
                                    value={labelData.media_links}
                                    onChange={handleChange}
                                    validationError={
                                        validationErrors.media_links
                                    }
                                />
                            </Col>
                        </Row>
                        {currentClient.is_global && !currentArtist && (
                            <>
                                <Separator />
                                <Row className="mb-3">
                                    <Col xs="12" md="6">
                                        <FormField
                                            fieldName="primary_artists"
                                            label={t(
                                                "APPONI.LABELFORM.artists"
                                            )}
                                            validationError={
                                                validationErrors.primary_artists
                                            }
                                            inputComponent={
                                                <AsyncTypeahead
                                                    id="label-artists"
                                                    isLoading={artistIsLoading}
                                                    minLength={2}
                                                    onSearch={ArtistsSearch}
                                                    filterBy={() => {
                                                        return true;
                                                    }}
                                                    options={artistOptions}
                                                    multiple
                                                    clearButton
                                                    onChange={(selected) =>
                                                        handleChange({
                                                            target: {
                                                                name: "primary_artists",
                                                                value: selected,
                                                            },
                                                        })
                                                    }
                                                    selected={
                                                        labelData.primary_artists
                                                    }
                                                    placeholder={t(
                                                        "APPONI.LABELFORM.type_to_search"
                                                    )}
                                                />
                                            }
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FormField
                                            fieldName="featuring_artists"
                                            label={t("APPONI.LABELFORM.feat")}
                                            validationError={
                                                validationErrors.featuring_artists
                                            }
                                            inputComponent={
                                                <AsyncTypeahead
                                                    id="label-feat"
                                                    isLoading={artistIsLoading}
                                                    minLength={2}
                                                    onSearch={ArtistsSearch}
                                                    filterBy={() => {
                                                        return true;
                                                    }}
                                                    options={artistOptions}
                                                    multiple
                                                    clearButton
                                                    onChange={(selected) =>
                                                        handleChange({
                                                            target: {
                                                                name: "featuring_artists",
                                                                value: selected,
                                                            },
                                                        })
                                                    }
                                                    selected={
                                                        labelData.featuring_artists
                                                    }
                                                    placeholder={t(
                                                        "APPONI.LABELFORM.type_to_search"
                                                    )}
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs="12" md="6">
                                        <FormImageInput
                                            fieldName="sticker_teaser_cover"
                                            label={t(
                                                "APPONI.LABELFORM.sticker_teaser_cover"
                                            )}
                                            helpText={t(
                                                "APPONI.LABELFORM.label_sticker_teaser_cover_description"
                                            )}
                                            currentFile={
                                                labelData.sticker_teaser_cover
                                            }
                                            onChange={handleChange}
                                            ref={stCoverRef}
                                            filename={stCoverFilename}
                                            validationError={
                                                validationErrors.sticker_teaser_cover
                                            }
                                            removeFile={(e) =>
                                                removeFile(
                                                    e,
                                                    "sticker_teaser_cover"
                                                )
                                            }
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FormFileInput
                                            fieldName="sticker_teaser_audio_track"
                                            label={t(
                                                "APPONI.LABELFORM.sticker_teaser_audio_track"
                                            )}
                                            currentFile={
                                                labelData.sticker_teaser_audio_track
                                            }
                                            onChange={handleChange}
                                            ref={stTrackRef}
                                            filename={stTrackFilename}
                                            help_text={t(
                                                "APPONI.LABELFORM.audio_help_text"
                                            )}
                                            validationError={
                                                validationErrors.sticker_teaser_audio_track
                                            }
                                            removeFile={(e) =>
                                                removeFile(
                                                    e,
                                                    "sticker_teaser_audio_track"
                                                )
                                            }
                                        />
                                    </Col>
                                </Row>
                            </>
                        )}
                        <hr />
                        <Accordion itemClasses={itemClasses}>
                            <AccordionItem
                                aria-label="moredata"
                                title={t("GENERAL.MORE-DETAILS")}
                            >
                                <Row className="mb-3">
                                    <Col xs={12} lg={3}>
                                        <FormTextInput
                                            label={t(
                                                "APPONI.LABELFORM.upc_ean"
                                            )}
                                            fieldName="upc"
                                            validationError={
                                                validationErrors.upc
                                            }
                                            ref={upcInputRef}
                                            value={labelData.upc}
                                            onChange={handleChange}
                                            maxLength={13}
                                        />
                                    </Col>

                                    <Col xs={12} lg={3}>
                                        <FormTextInput
                                            label={t(
                                                "APPONI.LABELFORM.language"
                                            )}
                                            fieldName="language"
                                            validationError={
                                                validationErrors.language
                                            }
                                            ref={languageInputRef}
                                            value={labelData.language}
                                            maxLength={15}
                                            onChange={handleChange}
                                        />
                                    </Col>

                                    <Col xs={12} lg={3}>
                                        <FormField
                                            label={t(
                                                "APPONI.LABELFORM.audio_language"
                                            )}
                                            fieldName="audio_language"
                                            validationError={
                                                validationErrors.audio_language
                                            }
                                            inputComponent={
                                                <Typeahead
                                                    id="audio_language"
                                                    name="audio_language"
                                                    labelKey="label"
                                                    selected={languageOptions.filter(
                                                        (obj) => {
                                                            return (
                                                                obj.value ===
                                                                labelData
                                                                    .audio_language[0]
                                                            );
                                                        }
                                                    )}
                                                    onChange={(selected) => {
                                                        if (
                                                            undefined !==
                                                            selected[0]
                                                        ) {
                                                            handleChange({
                                                                target: {
                                                                    name: "audio_language",
                                                                    value: [
                                                                        selected[0]
                                                                            .value,
                                                                    ],
                                                                },
                                                            });
                                                        }
                                                    }}
                                                    options={languageOptions}
                                                    filterBy={() => {
                                                        return true;
                                                    }}
                                                />
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} lg={3}>
                                        <FormTextInput
                                            fieldName="copyright_text_label"
                                            validationError={
                                                validationErrors.copyright_text_label
                                            }
                                            value={
                                                labelData.copyright_text_label
                                            }
                                            label={t(
                                                "APPONI.LABELFORM.copyright_text_label"
                                            )}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs="12" md="6">
                                        <FormTextArea
                                            label={t(
                                                "APPONI.LABELFORM.general_info"
                                            )}
                                            fieldName="extras_notes"
                                            validationError={
                                                validationErrors.extras_notes
                                            }
                                            value={labelData.extras_notes}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FormTextArea
                                            label={t(
                                                "APPS.CATALOG.PROJECT.PITCH"
                                            )}
                                            fieldName="extras_bio"
                                            validationError={
                                                validationErrors.extras_bio
                                            }
                                            value={labelData.extras_bio}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-3">
                                    <Col xs="12" md="6">
                                        <FormTextArea
                                            label={t(
                                                "APPONI.LABELFORM.datasheet"
                                            )}
                                            fieldName="extras_datasheet"
                                            validationError={
                                                validationErrors.extras_datasheet
                                            }
                                            value={labelData.extras_datasheet}
                                            onChange={handleChange}
                                        />
                                    </Col>
                                    {currentClient.is_global && !currentArtist && (
                                        <Col xs="12" md="6">
                                            <FormTextArea
                                                label={t(
                                                    "APPONI.LABELFORM.content_delivery_dates"
                                                )}
                                                fieldName="content_delivery_dates"
                                                helpText={t(
                                                    "APPONI.LABELFORM.content_delivery_description"
                                                )}
                                                validationError={
                                                    validationErrors.content_delivery_dates
                                                }
                                                value={
                                                    labelData.content_delivery_dates
                                                }
                                                onChange={handleChange}
                                            />
                                        </Col>
                                    )}
                                </Row>
                            </AccordionItem>
                        </Accordion>
                        <Accordion itemClasses={itemClasses}>
                            <AccordionItem
                                aria-label="transfers"
                                title={t("APPONI.LABELFORM.transfer_options")}
                            >
                                {copyrightHolders.map((holder, index) => (
                                    <div key={index}>
                                        <FormGroup key={index}>
                                            <Label for={`name${index}`}>
                                                {t(
                                                    "APPONI.LABELFORM.copyright_holder"
                                                )}{" "}
                                                #{index + 1}
                                            </Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                id={`name${index}`}
                                                value={holder.name}
                                                onChange={(e) =>
                                                    handleCopyrightHolderChange(
                                                        e,
                                                        index
                                                    )
                                                }
                                            />
                                            <Label for={`percentage${index}`}>
                                                {t(
                                                    "APPONI.LABELFORM.percentage"
                                                )}
                                            </Label>
                                            <Input
                                                type="number"
                                                name="percentage"
                                                id={`percentage${index}`}
                                                value={holder.percentage}
                                                onChange={(e) =>
                                                    handleCopyrightHolderChange(
                                                        e,
                                                        index
                                                    )
                                                }
                                            />
                                            <Label for={`document${index}`}>
                                                {t("APPONI.LABELFORM.document")}
                                            </Label>
                                            <Input
                                                type="text"
                                                name="document"
                                                id={`document${index}`}
                                                value={holder.document}
                                                onChange={(e) =>
                                                    handleCopyrightHolderChange(
                                                        e,
                                                        index
                                                    )
                                                }
                                            />

                                            <Label for={`mode${index}`}>
                                                {t("APPONI.LABELFORM.mode")}
                                            </Label>
                                            <Input
                                                type="text"
                                                name="mode"
                                                id={`mode${index}`}
                                                value={holder.mode}
                                                onChange={(e) =>
                                                    handleCopyrightHolderChange(
                                                        e,
                                                        index
                                                    )
                                                }
                                            />
                                            <Label for={`obs${index}`}>
                                                {t("APPONI.LABELFORM.obs")}
                                            </Label>
                                            <Input
                                                type="text"
                                                name="obs"
                                                id={`obs${index}`}
                                                value={holder.obs}
                                                onChange={(e) =>
                                                    handleCopyrightHolderChange(
                                                        e,
                                                        index
                                                    )
                                                }
                                            />
                                        </FormGroup>
                                        <Button
                                            className="btn shadow colorDanger"
                                            onClick={(e) =>
                                                handleRemoveCopyrightHolder(
                                                    e,
                                                    index
                                                )
                                            }
                                            text={t(
                                                "APPONI.LABELFORM.remove_transfer"
                                            )}
                                        />
                                        <hr />
                                    </div>
                                ))}

                                <Row className="mb-3">
                                    <Col xs="12" md="6">
                                        <Button
                                            className="btn shadow colorPrimary"
                                            onClick={(e) =>
                                                handleAddCopyrightHolder(e)
                                            }
                                            text={t(
                                                "APPONI.LABELFORM.add_transfer"
                                            )}
                                            aftericon="fas fa-arrow-right"
                                        />
                                    </Col>
                                </Row>
                            </AccordionItem>
                        </Accordion>
                        <div className="d-flex justify-content-end mt-2 mx-0">
                            <CustomSaveButton
                                type="submit"
                                form="label-form"
                                className="btn btn-lg btn-primary mb-5"
                                formSending={formSending}
                                buttonText={
                                    "APPONI.LABELFORM.label_form_go_to_assets"
                                }
                                afterIcon={
                                    <FontAwesomeIcon icon={faArrowRight} />
                                }
                            />
                        </div>
                    </Form>
                </>
            )}
        </>
    );
}
