/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { useThemeMode } from "../../../layout/theme-mode/ThemeModeProvider";
import { getCSSVariableValue } from "../../../../_metronic/assets/ts/_utils";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { formatNumberWithAbbreviation } from "../../../helpers/usefulFunctions";
import { Card, CardBody, CardHeader } from "next2";
import { CardTitle } from "../../../components/Cards/CardTitle";
import { CardSubtitle } from "../../../components/Cards/CardSubtitle";

type Props = {
    title: string;
    icon: JSX.Element;
    description: string;
    label: string;
    xAxis: Array<string> | object;
    yAxis: Array<number>;
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined;
    minValue: number;
    maxValue: number;
    className: string;
    chartColor: string | Array<string>;
    chartHeight: string;
    customCard: boolean;
    chartType:
        | "area"
        | "line"
        | "bar"
        | "pie"
        | "donut"
        | "radialBar"
        | "scatter"
        | "bubble"
        | "heatmap"
        | "candlestick"
        | "boxPlot"
        | "radar"
        | "polarArea"
        | "rangeBar"
        | "rangeArea"
        | "treemap"
        | undefined;
    chartStacked: boolean;
    plotOptions: object;
    dataLabels: object;
    addPadding: boolean;
};

const ChartWidget: React.FC<Props> = ({
    title,
    icon,
    description,
    label,
    xAxis,
    yAxis,
    series,
    minValue,
    maxValue,
    className,
    chartColor,
    chartHeight,
    customCard,
    chartType,
    chartStacked,
    plotOptions,
    dataLabels,
    addPadding = true,
}) => {
    const chartRef = useRef<HTMLDivElement | null>(null);
    const { mode } = useThemeMode();
    const { t } = useTranslate();
    const [newTitle, setNewTitle] = useState("");
    const [newDescription, setNewDescription] = useState("");

    const refreshChart = () => {
        if (!chartRef.current) {
            return;
        }

        const chart1 = new ApexCharts(
            chartRef.current,
            chart1Options(
                xAxis,
                yAxis,
                minValue,
                maxValue,
                chartColor,
                chartHeight,
                label,
                series,
                chartType,
                chartStacked,
                plotOptions,
                dataLabels
            )
        );
        if (chart1) {
            chart1.render();
        }

        return chart1;
    };

    useEffect(() => {
        const chart1 = refreshChart();

        return () => {
            if (chart1) {
                chart1.destroy();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        chartRef,
        mode,
        xAxis,
        yAxis,
        minValue,
        maxValue,
        chartColor,
        chartHeight,
    ]);

    useEffect(() => {
        setNewTitle(String(title));
        setNewDescription(String(description));
    }, [title, description]);

    return (
        <>
            {customCard ? (
                <Card className="p-8 h-full bg-body shadow-none rounded-top-0">
                    <CardHeader className="p-0 mb-4 items-start justify-between flex-wrap">
                        <div className="flex flex-col md:mb-4">
                            <div className="flex items-center">
                                {icon}
                                <CardTitle text={newTitle} className="" />
                            </div>
                            <CardSubtitle text={newDescription} />
                        </div>
                    </CardHeader>
                    <CardBody>
                        {yAxis.length === 0 ? (
                            <div className="mixed-widget-5-chart card-rounded-top h-full d-flex justify-content-center align-items-center text-white">
                                {String(t("CHARTS.SEARCHING-FOR-DATA"))}
                            </div>
                        ) : (
                            <div
                                ref={chartRef}
                                id={`ch1-${title}`}
                                className="mixed-widget-5-chart card-rounded-top"
                            ></div>
                        )}
                    </CardBody>
                </Card>
            ) : (
                <div className={`card ${className}`}>
                    {/* begin::Header */}
                    {Boolean(icon || title || description) && (
                        // <div className="flex justify-between items-stretch border-0 p-0">
                        <div
                            className={
                                addPadding
                                    ? "card-header border-0 py-5 pb-0"
                                    : "flex justify-between items-stretch border-0 p-0"
                            }
                        >
                            {Boolean(icon || title) && (
                                <h3 className="card-title align-items-start flex-column word-wrap">
                                    <div className="d-flex justify-content-between">
                                        {icon}
                                        <span className="card-label fw-bold fs-3 mb-1">
                                            {title}
                                        </span>
                                    </div>
                                </h3>
                            )}
                            {Boolean(description) && (
                                <span className="text-gray-700 font-semibold fs-7 h-auto">
                                    {description}
                                </span>
                            )}
                        </div>
                    )}
                    {/* end::Header */}

                    {/* begin::Body */}
                    <div
                        className={
                            addPadding ? "card-body pb-3" : "card-body p-0"
                        }
                    >
                        {yAxis.length === 0 ? (
                            <div className="mixed-widget-5-chart card-rounded-top h-150px d-flex justify-content-center align-items-center">
                                {String(t("CHARTS.SEARCHING-FOR-DATA"))}
                            </div>
                        ) : (
                            <div
                                ref={chartRef}
                                id={`ch2-${title}`}
                                className="mixed-widget-5-chart card-rounded-top"
                            ></div>
                        )}
                    </div>
                    {/* end::Body */}
                </div>
            )}
        </>
    );
};

const chart1Options = (
    xAxis: Array<string> | object,
    yAxis: Array<number>,
    minValue: number,
    maxValue: number,
    chartColor: string | Array<string>,
    chartHeight: string,
    label: string,
    series: ApexAxisChartSeries | ApexNonAxisChartSeries | undefined = [
        {
            name: label || "",
            data: yAxis,
        },
    ],
    chartType:
        | "area"
        | "line"
        | "bar"
        | "pie"
        | "donut"
        | "radialBar"
        | "scatter"
        | "bubble"
        | "heatmap"
        | "candlestick"
        | "boxPlot"
        | "radar"
        | "polarArea"
        | "rangeBar"
        | "rangeArea"
        | "treemap"
        | undefined = "area",
    chartStacked: boolean = false,
    plotOptions: object = {},
    dataLabels: object = {
        enabled: false,
    }
): ApexOptions => {
    const labelColor = getCSSVariableValue("--bs-gray-800");
    const strokeColor = getCSSVariableValue("--bs-gray-300");
    const baseColor = Array.isArray(chartColor) ? chartColor : [chartColor];
    const lightColor = Array.isArray(chartColor)
        ? chartColor.map((color) => color + "33")
        : [chartColor + "33"];

    return {
        series: series,
        chart: {
            fontFamily: "inherit",
            type: chartType,
            stacked: chartStacked,
            height: chartHeight,
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false,
            },
            sparkline: {
                enabled: true,
            },
        },
        plotOptions: plotOptions,
        legend: {
            show: false,
        },
        dataLabels: dataLabels,
        fill: {
            type: "solid",
            opacity: 1,
        },
        fill1: {
            type: "gradient",
            opacity: 1,
            gradient: {
                type: "vertical",
                shadeIntensity: 0.5,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 0.375,
                stops: [0, 2000000, 5000000],
                colorStops: [],
            },
        },
        stroke: {
            curve: "smooth",
            show: true,
            width: 3,
            colors: baseColor,
        },
        xaxis: {
            categories: xAxis,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            labels: {
                show: true,
                style: {
                    colors: labelColor,
                    fontSize: "12px",
                },
            },
            crosshairs: {
                show: false,
                position: "front",
                stroke: {
                    color: strokeColor,
                    width: 1,
                    dashArray: 3,
                },
            },
            tooltip: {
                enabled: true,
                formatter: undefined,
                offsetY: 0,
                style: {
                    fontSize: "12px",
                },
            },
        },
        yaxis: {
            min: minValue,
            max: maxValue,
            labels: {
                show: false,
                style: {
                    colors: labelColor,
                    fontSize: "12px",
                },
            },
        },
        states: {
            normal: {
                filter: {
                    type: "none",
                    value: 0,
                },
            },
            hover: {
                filter: {
                    type: "none",
                    value: 0,
                },
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: "none",
                    value: 0,
                },
            },
        },
        tooltip: {
            style: {
                fontSize: "12px",
            },
            y: {
                formatter: function (val) {
                    return String(formatNumberWithAbbreviation(val));
                },
            },
        },
        colors: lightColor,
        markers: {
            colors: lightColor,
            // @ts-ignore
            strokeColor: baseColor,
            strokeWidth: 3,
        },
    };
};

export { ChartWidget };
