import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './styles.css'
// import required modules
import {Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';

/**
 * Componente CustomCarousel
 *
 * @description
 * Este componente cria um carrossel de slides usando a biblioteca Swiper para exibir uma lista de itens.
 *
 * @param {Array} props.items - Um array de elementos a serem exibidos como slides no carrossel.
 *
 * @returns {JSX.Element} Um componente React que exibe um carrossel de slides.
 *
 */

export const CustomCarousel = ({ items, className }) => {
    return (
        <div className={className}>
            <Swiper
               effect={'fade'}
               navigation={true}
               pagination={{
                 clickable: true,
               }}
               modules={[Autoplay, EffectFade, Navigation, Pagination]}
            >
                <>
                    {items ? (
                        <>
                            {items.map((item, index) => (
                                <SwiperSlide key={index}>{item}</SwiperSlide>
                            ))}
                        </>
                    ) : (
                        <SwiperSlide>{items}</SwiperSlide>
                    )}
                </>
            </Swiper>
        </div>
    );
};
