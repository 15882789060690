import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import "../label_styles.css";
import ProductForm from "./productForm";
import { useTranslate } from "../../../../helpers/i18n/useTranslate";
import AssetsForm from "./assetsForm";
import { useCreateButton } from "../../../../helpers/subHeaderButtons/createButtonProvider";
import { useAppOniApi } from "../../../../helpers/Api/AppOniApiWrapper";
import { useAuth } from "../../../auth";
import { useAlert } from "../../../../helpers/utils/CustomAlert";
import { useParams } from "react-router-dom";
import { PageTitle } from "../../../../layout/core";
import AssetsTracks from "./assetsTracksForm";

/**
 * Renderiza e gerencia os estados necessários para o funcionamento do form de label
 * @returns {JSX.Element}
 * @constructor
 */
export default function LabelForm() {
    const { t } = useTranslate();
    const { projectId, labelId } = useParams();
    const { apponi_get } = useAppOniApi();
    const { alert } = useAlert();
    const { setHref } = useCreateButton();
    setHref("");
    const [formPhase, setFormPhase] = useState(1);
    const [form, setForm] = useState({
        title: t("APPS.CATALOG.PROJECT.LABEL-NEW"),
    });

    const projectBreadCrumbs = [
        {
            title: "APPS.CORE.PROJECTS",
            path: "/projects",
            isSeparator: false,
            isActive: false,
        },
        {
            title: "GENERAL.DETAILS",
            path: `/projects/dt/${projectId}`,
            isSeparator: false,
            isActive: false,
        },
        {
            title: "APPS.CATALOG.PROJECT.LABEL",
            path: `/projects/${projectId}/label${labelId ? "/" + labelId : ""}`,
            isSeparator: true,
            isActive: true,
        },
    ];

    /**
     * Ao carregar a tela, tenta conectar no AppOni
     */
    useEffect(() => {
        apponi_get("catalog/api/labels").catch((error) => {
            // Se não conseguir comunicar lá, o usuário não pode continuar nessa tela. Avisaremos do erro e
            // mandaremos ele embora.
            console.error(error);
            alert
                .fire(
                    t("FORMS.APPONI.AUTH-ERROR"),
                    t("FORMS.APPONI.AUTH-ERROR.TRY-AGAIN-LATER"),
                    "error"
                )
                .then(() => window.location.replace("/")); // Mandando o usuário embora pra dashboard
        });
    }, []);

    return (
        <>
            <PageTitle breadcrumbs={projectBreadCrumbs}>
                APPS.CATALOG.PROJECT.LABEL
            </PageTitle>
            <div>
                <ul className="nav nav-tabs nav-menu">
                    <li className="nav-item">
                        <button
                            className={`nav-link disabled ${
                                formPhase === 1 && "active"
                            }`}
                            role="tab"
                            data-toggle="tab"
                        >
                            {t("FORMS.CATALOG.LABEL.GENERAL-DATA")}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link disabled ${
                                formPhase === 2 && "active"
                            }`}
                            role="tab"
                            data-toggle="tab"
                        >
                            {t("FORMS.CATALOG.LABEL.ASSETS")}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link disabled ${
                                formPhase === 3 && "active"
                            }`}
                            role="tab"
                            data-toggle="tab"
                        >
                            {t("FORMS.CATALOG.LABEL.AUDIO-FILES")}
                        </button>
                    </li>
                </ul>
            </div>
            <Card>
                <CardBody>
                    {/* Vamos sempre renderizar as três etapas para manter os states, mas exibir apenas uma por vez */}
                    <div className={formPhase !== 1 ? "d-none" : ""}>
                        <ProductForm
                            setFormPhase={setFormPhase}
                            setForm={setForm}
                            form={form}
                            labelId={labelId}
                            projectId={projectId}
                        />
                    </div>
                    <div className={formPhase !== 2 ? "d-none" : ""}>
                        <AssetsForm
                            setFormPhase={setFormPhase}
                            formPhase={formPhase}
                            setForm={setForm}
                            form={form}
                            labelId={form.id}
                            appName={"catalog"}
                            projectId={projectId}
                        />
                    </div>
                    <div className={formPhase !== 3 ? "d-none" : ""}>
                        <AssetsTracks
                            setFormPhase={setFormPhase}
                            projectId={projectId}
                            labelId={form.id}
                            formPhase={formPhase}
                        />
                    </div>
                </CardBody>
            </Card>
        </>
    );
}
