import React from 'react';

const FormField = ({className, fieldName, label, required, validationError, inputComponent, helpText}) => {
    return (
        <>
            {label?
            <label className={className} style={{fontSize: 16}}
                   htmlFor={fieldName}>{label}{required && label && '*'}</label> : null}
            { inputComponent }
            {
                helpText &&
                <small>{helpText}</small>
            }
            {helpText && validationError && <br/>}
            {validationError &&
            <small className="validationErrors">{validationError}</small>}
        </>
    )
}

export default FormField