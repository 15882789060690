import { Route, Routes } from "react-router-dom";
import { lazy } from "react";
import SuspensedView from "../SuspensedView";
import { Academy } from "../../modules/academy/academy";
import {TutorialDetails} from "../../modules/academy/Tutorials/TutorialDetails";

const AcademyRoutes = () => {
    const CourseDetails = lazy(() =>
        import("../../modules/academy/Courses/CourseDetails")
    );

    const PostDetails = lazy(() =>
        import("../../modules/academy/Blog/PostDetails")
    );

    return (
        <Routes>
            <Route path="" element={<Academy />} />
            <Route path="posts/:postTitle"
                   element={
                    <PostDetails />
                   }
                   />
            <Route
                path="course/:courseId/*"
                element={
                    <SuspensedView>
                        <CourseDetails />
                    </SuspensedView>
                }
            />
            <Route
                path="tutorial/:tutorialId"
                element={
                        <TutorialDetails />
                }
            />
        </Routes>
    );
};

export { AcademyRoutes };