/**
 * Retorna a string da cor da barra de progresso, de acordo com a sua completude. Abaixo de 60%, a cor é vermelha.
 * @param value
 */
function getColorSchemeForProgressBar(value) {
    if (value < 60) return "danger";
    if (value >= 60 && value <= 80) return "warning";
    if (value > 80) return "success";
}

export { getColorSchemeForProgressBar };
