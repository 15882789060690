import { useEffect, useState } from "react";
import { useTranslate } from "../../../helpers/i18n/useTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGrid2 } from "@fortawesome/pro-light-svg-icons";
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider";
import clsx from "clsx";
import { useApi } from "../../../helpers/Api/ApiWrapper";
import { faGoogle, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { Card, CardBody, CardHeader } from "next2";
import { CardTitle } from "../../../components/Cards/CardTitle";
import { CardSubtitle } from "../../../components/Cards/CardSubtitle";

const defaultFilterOption = { date: "TO", locale: "BR" };

/**
 * Widget de trends
 * @returns {JSX.Element}
 * @constructor
 */
const TrendsWidget = () => {
    const { t } = useTranslate();
    const { GET } = useApi();
    const TODAY = new Date().toISOString().split("T")[0];
    const [allTrends, setAllTrends] = useState([]);
    const [filteredTrends, setFilteredTrends] = useState([]);
    const [filters, setFilters] = useState(defaultFilterOption);

    /**
     * Pega as trends quando a página carrega
     */
    useEffect(() => {
        const getTrends = async () => {
            try {
                const response = await GET("/ds/trends");
                setAllTrends(response.data);
                setFilteredTrends(
                    response.data.filter(
                        (trend) => trend.date === TODAY && trend.locale === "BR"
                    )
                );
            } catch (e) {
                console.error(e);
            }
        };
        getTrends();
    }, []);

    /**
     * Re-obtém os resultados das trends toda vez que os filtros mudam
     */
    useEffect(() => {
        let tempTrends = [...allTrends];
        tempTrends = tempTrends.filter((trend) => {
            // Se o filtro por data não estiver em HOJE pode retornar. Caso contrário, só pode retornar se a data da
            // trend for a de hoje. Só pode retornar a trend cujo local seja o selecionado no filtro
            return (
                Boolean(filters.date !== "TO" || trend.date === TODAY) &&
                Boolean(filters.locale === trend.locale)
            );
        });
        setFilteredTrends(tempTrends);
    }, [filters.date, filters.locale]);

    return (
        <>
            <Card className="p-8 h-full bg-body" id="intro-trends">
                <CardHeader className="p-0 items-start justify-between flex-wrap">
                    <div className="flex flex-col md:mb-4">
                        <CardTitle text={t("APPS.DATASCIENCE.TRENDS.TITLE")} />
                        <CardSubtitle
                            text={
                                <>
                                    {t(
                                        `APPS.DATASCIENCE.TRENDS.${
                                            filters.date === "TO"
                                                ? "TODAY"
                                                : "7DAYS"
                                        }.DESC`
                                    )}{" "}
                                    {t(
                                        `APPS.DATASCIENCE.TRENDS.${filters.locale}-TEXT`
                                    )}
                                </>
                            }
                        />
                    </div>

                    <div className="flex">
                        <ul className="nav">
                            <li className="nav-item">
                                <a
                                    className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1"
                                    data-bs-toggle="tab"
                                    href="#trends_google"
                                >
                                    <FontAwesomeIcon icon={faGoogle} />
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"
                                    data-bs-toggle="tab"
                                    href="#trends_twitter"
                                >
                                    <FontAwesomeIcon icon={faXTwitter} />
                                </a>
                            </li>
                        </ul>
                        <button
                            type="button"
                            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                            data-kt-menu-flip="top-end"
                        >
                            <FontAwesomeIcon icon={faGrid2} />
                        </button>
                        <FilterTrendsDropdown
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </div>
                </CardHeader>
                <CardBody className="p-0 overflow-visible py-2">
                    <div className="tab-content">
                        <div
                            className=" tab-pane fade show active"
                            id="trends_google"
                        >
                            {filteredTrends.filter(
                                (trend) => trend.source === "GOG"
                            ).length > 0 ? (
                                filteredTrends
                                    .filter((trend) => trend.source === "GOG")
                                    .map((trend, index) => (
                                        <div
                                            key={`G-${index}`}
                                            className="d-flex flex-stack mb-5"
                                        >
                                            <div className="d-flex align-items-center me-2">
                                                <div className="symbol symbol-50px me-3">
                                                    <div className="symbol-label bg-light">
                                                        #{trend.position}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span
                                                        href="#"
                                                        className="fs-6 text-gray-800 fw-bold"
                                                    >
                                                        {trend.title}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <span className="text-white">
                                    {t("APPS.DATASCIENCE.TRENDS.EMPTY")}
                                </span>
                            )}
                        </div>
                        <div className="tab-pane fade" id="trends_twitter">
                            {filteredTrends.filter(
                                (trend) => trend.source === "TWT"
                            ).length > 0 ? (
                                filteredTrends
                                    .filter((trend) => trend.source === "TWT")
                                    .map((trend, index) => (
                                        <div
                                            key={`TW-${index}`}
                                            className="d-flex flex-stack mb-5"
                                        >
                                            <div className="d-flex align-items-center me-2">
                                                <div className="symbol symbol-50px me-3">
                                                    <div className="symbol-label bg-light">
                                                        #{trend.position}
                                                    </div>
                                                </div>
                                                <div>
                                                    <span
                                                        href="#"
                                                        className="fs-6 text-gray-800 fw-bold"
                                                    >
                                                        {trend.title}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            ) : (
                                <span className="text-white">
                                    {t("APPS.DATASCIENCE.TRENDS.EMPTY")}
                                </span>
                            )}
                        </div>
                    </div>
                </CardBody>
            </Card>
        </>
    );
};

/**
 * Dropdown com as opções de filtro para as trends
 * @param {object} filters state de filtros
 * @param {function} setFilters setter do state de filtros
 * @returns {JSX.Element}
 * @constructor
 */
function FilterTrendsDropdown({ filters, setFilters }) {
    const { mode } = useThemeMode();
    const [locale, setLocale] = useState(filters.locale);
    const [date, setDate] = useState(filters.date);
    const { t } = useTranslate();

    return (
        <div
            className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
            data-kt-menu="true"
        >
            <div className="px-7 py-5">
                <div className="fs-5 text-dark fw-bold">
                    {t("AUTH.GENERAL.FILTER-OPTIONS")}
                </div>
            </div>

            <div className="separator border-gray-200"></div>

            <div className="px-7 py-5">
                <div className="mb-10">
                    <label className="form-label fw-semibold">
                        {t("APPS.DATASCIENCE.TRENDS.LOCALES")}:
                    </label>

                    <div>
                        <select
                            className="form-select form-select-solid"
                            data-kt-select2="true"
                            data-placeholder={t("APPONI.LABELFORM.select_one")}
                            data-allow-clear="true"
                            defaultValue={filters.locale}
                            onChange={(evt) => setLocale(evt.target.value)}
                        >
                            <option value="BR">
                                {t("APPS.DATASCIENCE.TRENDS.BR")}
                            </option>
                            <option value="US">
                                {t("APPS.DATASCIENCE.TRENDS.US")}
                            </option>
                        </select>
                    </div>
                </div>

                {/*<div className="mb-10">*/}
                {/*<label className="form-label fw-semibold">*/}
                {/*    {t("APPS.DATASCIENCE.TRENDS.DATE")}:*/}
                {/*</label>*/}

                {/*<div>*/}
                {/*    <select*/}
                {/*        className="form-select form-select-solid"*/}
                {/*        data-kt-select2="true"*/}
                {/*        data-placeholder={t("APPONI.LABELFORM.select_one")}*/}
                {/*        data-allow-clear="true"*/}
                {/*        defaultValue={filters.date}*/}
                {/*        onChange={(evt) => setDate(evt.target.value)}*/}
                {/*    >*/}
                {/*        <option value="TO">*/}
                {/*            {t("APPS.DATASCIENCE.TRENDS.TODAY.TITLE")}*/}
                {/*        </option>*/}
                {/*        <option value="LS">*/}
                {/*            {t("APPS.DATASCIENCE.TRENDS.7DAYS.TITLE")}*/}
                {/*        </option>*/}
                {/*    </select>*/}
                {/*</div>*/}
                {/*</div>*/}

                <div className="d-flex justify-content-end">
                    <button
                        onClick={() => setFilters(defaultFilterOption)}
                        className={clsx(
                            "btn btn-sm btn-active-light-primary me-2",
                            {
                                "btn-light ": mode === "light",
                            }
                        )}
                        data-kt-menu-dismiss="true"
                    >
                        {t("AUTH.GENERAL.FILTER-RESET")}
                    </button>
                    <button
                        className="btn btn-sm btn-primary"
                        data-kt-menu-dismiss="true"
                        onClick={() =>
                            setFilters({ locale: locale, date: date })
                        }
                    >
                        {t("AUTH.GENERAL.FILTER-APPLY")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export { TrendsWidget };
