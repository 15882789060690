import React from 'react';

const separator = {
    height: '2px',
    width: '100%',
    backgroundColor: '#e1e1e1',
}

const Separator = ({y=5}) => {
    return <div style={separator} className={`my-${y}`}/>
}

export default Separator;