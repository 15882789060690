import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { ShareButtons } from "../../components/ShareButtons";
import React from "react";
import { useTranslate } from "../../helpers/i18n/useTranslate";
import { InfoForFields } from "../../components/InfoForFields";

/**
 * Modal para compartilhamento de OneSheet
 * @param {Boolean} modalOpen indica se o modal está aberto ou fechado
 * @param {function} setModalOpen setter para abrir/fechar o modal
 * @param {String} slug slug da onesheet
 * @param {String} artistName nome do artista
 * @param {string} description texto de descrição do modal
 * @param {string} descriptionId identificador para o tooltip
 * @returns {JSX.Element}
 * @constructor
 */
const ShareOnesheetModal = ({ modalOpen, setModalOpen, slug, artistName, description, descriptionId }) => {
    const { t } = useTranslate();
    return (
        <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
            <Modal.Header>
                <Modal.Title>{t("APPS.ONESHEET.SHARE")}</Modal.Title>
                {description ? (
                    <>
                        <InfoForFields 
                            id={descriptionId}
                            text={description}
                            place="bottom"
                        />
                    </>
                ) : null}
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex mb-5">
                    <input
                        type="text"
                        className="w-100 me-5 form-control form-control-lg form-control-solid"
                        disabled
                        value={`${process.env.REACT_APP_ONESHEET_URL_PREFIX}${slug}`}
                        onChange={(e) => e.preventDefault()}
                    />
                    <button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                `${process.env.REACT_APP_ONESHEET_URL_PREFIX}${slug}`
                            );
                            toast.info(t("GENERAL.LINK.COPIED"));
                        }}
                    >
                        {" "}
                        <FontAwesomeIcon icon={faCopy} />{" "}
                    </button>
                </div>
                <div className="flex justify-center mb-5">
                    <ShareButtons
                        url={t("APPS.INVITES.SHARE-DEFAULT-TEXT")
                            .replace("$ARTIST$", artistName)
                            .replace(
                                "$LINK$",
                                `${process.env.REACT_APP_ONESHEET_URL_PREFIX}${slug}`
                            )}
                        title={artistName}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export { ShareOnesheetModal };
