import React, { useEffect, useState } from "react";
import {
    dynamicSort,
    isNotEmpty,
    removeNullDataFromDict,
    updateArrayAtIndex,
} from "../../../../helpers/usefulFunctions";
import { useAlert } from "../../../../helpers/utils/CustomAlert";
import { Card, Col, Form, FormGroup, Row, Table } from "reactstrap";
import FormNumberInput from "../AppOniComponents/Forms/Inputs/FormNumberInput";
import FormTextInput from "../AppOniComponents/Forms/Inputs/FormTextInput";
import FormTextArea from "../AppOniComponents/Forms/Inputs/FormTextArea";
import FormSelectInput from "../AppOniComponents/Forms/Inputs/FormSelectInput";
import FormCheckboxInput from "../AppOniComponents/Forms/Inputs/FormCheckboxInput";
import Separator from "../AppOniComponents/Forms/FormGroupSeparator";
import FormTimeInput from "../AppOniComponents/Forms/Inputs/FormTimeInput";
import FormFileInput from "../AppOniComponents/Forms/Inputs/FormFileInput";
import FormField from "../AppOniComponents/Forms/FormField";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ModalComponent from "../AppOniComponents/Modal";
import Warning from "../AppOniComponents/Warning";
import { useTranslate } from "../../../../helpers/i18n/useTranslate";
import { useAppOniApi } from "../../../../helpers/Api/AppOniApiWrapper";
import { makeFormDataBodyForAppOni } from "../../../../helpers/utils/appOniHelpers";
import { useApi } from "../../../../helpers/Api/ApiWrapper";
import { useAuth } from "../../../auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlusCircle,
    faArrowRight,
    faArrowLeft,
    faCheckCircle,
    faEdit,
    faTrashAlt,
    faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { CustomSaveButton } from "../../../../components/CustomSaveButton";
import clsx from "clsx";
import CreatableSelect from "react-select/creatable";
import { Accordion, AccordionItem } from "next2";

const styles = {
    hidden: {
        opacity: 0,
        transition: "all 300ms linear",
    },
    show: {
        opacity: 1,
    },
    invalid: {
        fontWeight: "bold",
        color: "red",
    },
    valid: {},
};
const itemClasses = {
    base: "py-0 w-full",
    title: "font-bold text-xl text-white",
    trigger:
        "px-2 py-0 data-[hover=false]:bg-default-100 rounded-lg h-14 flex items-center",
    indicator: "text-medium",
    content: "text-small px-2",
};

/**
 * Renderiza a parte de lista de fonogramas do formulario de label
 * @param {function} setFormPhase Funcao que renderiza os estágios do formulário corretamente
 * @param {function} setForm Setter do formulário geral
 * @param {object} form state do formulário geral
 * @param {String} labelId id da label, em caso de edicao
 * @param {String} projectId id do projeto correspondente à label
 * @returns {JSX.Element}
 * @constructor
 */
export default function AssetsForm({
    setFormPhase,
    formPhase,
    form,
    setForm,
    projectId,
}) {
    const { t } = useTranslate();
    const { alert, dialogs } = useAlert();
    const { POST } = useApi();
    const { currentClient, currentArtist } = useAuth();
    const { apponi_get, apponi_post } = useAppOniApi();
    const [formSending, setFormSending] = useState(false);
    const [needsAudioTracks, setNeedsAudioTracks] = useState(false);
    const [needsMoreAssets, setNeedsMoreAssets] = useState(true);
    const [collabArtistsSelected, setCollabArtistsSelected] = useState([]); // Usado no createable select de artistas colab
    const [featArtistsSelected, setFeatArtistsSelected] = useState([]); // Usado no createable select de artistas feat

    const headers = [
        t("APPONI.LABELFORM.order"),
        t("APPONI.LABELFORM.title"),
        t("APPONI.LABELFORM.work_song"),
    ];
    const body = ["order", "title", "work_song"];

    const mediaOptions = [
        { value: "AUVD", label: t("APPONI.LABELFORM.label_media_auvd") },
        { value: "AUDI", label: t("APPONI.LABELFORM.label_media_audi") },
        { value: "VIDE", label: t("APPONI.LABELFORM.label_media_vide") },
    ];
    const languageOptions = [
        { value: "PT", label: t("APPONI.LABELFORM.portuguese") },
        { value: "EN", label: t("APPONI.LABELFORM.english") },
        { value: "SP", label: t("APPONI.LABELFORM.spanish") },
    ];

    const [artistIsLoading, setArtistIsLoading] = useState(false);
    const [artistOptions, setArtistOptions] = useState([]);

    const [assetList, setAssetList] = useState([]); // Lista de fonogramas (formsets)
    const [invalidAssets, setInvalidAssets] = useState([]);
    const initialAssetData = {
        id: "",
        product: "",
        asset: "",
        title: "",
        main_interpreter: "",
        asset_holder_text: "",
        asset_composers_text: "",
        featuring: "",
        lyrics: "",
        isrc: "",
        producers: "",
        extras_notes: "",
        valid: false,
        order: assetList.length + 1,
        documents: "",
        media: "AUVD",
        audio_language: "PT",
        audio_track_url: "",
        video_cover: "",
        media_links: "",
        tiktok_preview_start_time: "",
        version: "",
        gender: "",
        subgender: "",
        work_song: false,
        primary_artists: [],
        featuring_artists: [],
        DELETE: "", // Necessário para indicar ao Django se ou não esta instância deve ser apagada do BD
    }; // Campos em branco do fonograma, usado para adição de novos fonogramas
    const [assetData, setAssetData] = useState(initialAssetData); // Campos do fonograma

    const [fadeOut, setFadeOut] = useState([]); // Serve pra controle da animação de fadeOut da lista de formsets
    const [modal, setModal] = useState(false); // Serve pra abrir/fechar o modal
    const [currentAssetIndex, setCurrentAssetIndex] = useState(0); // Serve para indicar o index do fonograma que esta sendo editado/removido

    const [validationErrors, setValidationErrors] = useState({});

    // States de controle dos formsets
    const [initialForms, setInitialForms] = useState(0); // Indica o número inicial de formsets
    const minNumForms = 0; // Indica o número mínimo de formsets
    const maxNumForms = 1000; // Indica o número máximo de formsets

    /**
     * Busca por artistas no backend do apponi
     * @param {String} inputValue valor de busca
     * @constructor
     */
    const ArtistsSearch = (inputValue) => {
        setArtistIsLoading(true);
        apponi_get(
            `catalog/apiv2/dynamic_search/?response_type=react-select&filtered_class=artist&search=${inputValue}`
        ).then((response) => {
            setArtistOptions(response.data.results);
            setArtistIsLoading(false);
        });
    };

    /**
     * Abre e fecha o modal de fonograma
     * @param {number} index índice do fonograma que está sendo aberto
     */
    const toggle = (index) => {
        if (Number.isInteger(index)) {
            // Se entrar aqui, é abertura do modal
            setCurrentAssetIndex(index);
            // Se houver fonograma na lista na posição que foi passada, carrega os dados dele
            if (assetList[index]) setAssetData(assetList[index]);
            // Do contrário, pega um objeto fonograma vazio
            else setAssetData(initialAssetData);
            // Se for um fonograma novo, e tiver currentArtist selecionado, colocamos ele no campo, pré-preenchido
            if (!collabArtistsSelected[index] && currentArtist) {
                let tempCollabArtistsSelected = [...collabArtistsSelected];
                updateArrayAtIndex(tempCollabArtistsSelected, index, [
                    {
                        label: currentArtist.name,
                        value: currentArtist.name,
                    },
                ]);
                setCollabArtistsSelected(tempCollabArtistsSelected);
            }
            setModal(!modal); // Fecha o modal
        } else {
            // Se cair aqui, é fechamento do modal
            if (collabArtistsSelected[currentAssetIndex].length > 3) {
                // Só pode fechar modal se n tiver mais q 3 colab
                alert.fire({
                    icon: "warning",
                    text: t("APPONI.LABELFORM.colab_limit_reached"),
                });
            } else {
                // Se entrar aqui, é pq colab tá válido. Pode fechar o modal
                setCurrentAssetIndex(assetList.length - 1);
                setModal(!modal);
            }
        }
    };

    useEffect(() => {
        // Se a mídia do produto for diferente de "apenas vídeo", é necessário cadastrar os fonogramas
        setNeedsAudioTracks(form.product_media !== "VIDE");
        if (form.id && !assetList.length) {
            // Se for edição e houver fonogramas, carregue-os
            loadLabelAssetsData();
        }
    }, [form.id, form.product_media, formPhase]);

    /**
     * Verifica se a quantidade de fonogramas cadastrados está ok
     */
    useEffect(() => {
        let validAssets = assetList.filter((asset) => asset.DELETE !== "on");
        // Se o tipo do produto não for single, são necessários no mínimo dois fonogramas válidos cadastrados
        setNeedsMoreAssets(form.type !== "SIN" && validAssets.length < 2);
    }, [assetData.DELETE, assetList, form.type]);

    /**
     * Busca os fonogramas no backend do apponi
     */
    function loadLabelAssetsData() {
        apponi_get(`catalog/api/label-assets/?label_id=${form.id}`).then(
            (response) => {
                let responseData = response.data.results;
                setCurrentAssetIndex(responseData.length);
                setInitialForms(responseData.length);
                let loadedAssetsData = [];
                let loadedFadeOutList = [];
                let colabsList = [];
                let featsList = [];
                responseData.forEach((labelAsset) => {
                    labelAsset = removeNullDataFromDict(labelAsset);
                    // Carrega dados de intérprete e feat no formato de string e converte pra lista de dicts para o input customizado
                    let splitInterpreterNames =
                        labelAsset.main_interpreter?.split(", ");
                    let splitFeatNames = isNotEmpty(labelAsset.featuring)
                        ? labelAsset.featuring.split(", ")
                        : [];
                    let interpreters = (splitInterpreterNames || []).map(
                        (name) => ({
                            label: name,
                            value: name,
                        })
                    );
                    let feats = (splitFeatNames || []).map((name) => ({
                        label: name,
                        value: name,
                    }));
                    // Carrega os states de input de interprete e feat customizados
                    colabsList.push(interpreters);
                    featsList.push(feats);
                    // Carrega o state de fonogramas
                    loadedAssetsData.push({
                        id: labelAsset.id,
                        product: labelAsset.product,
                        asset: labelAsset.asset,
                        title: labelAsset.title,
                        main_interpreter: labelAsset.main_interpreter,
                        asset_holder_text: labelAsset.asset_holder_text,
                        asset_composers_text: labelAsset.asset_composers_text,
                        featuring: labelAsset.featuring,
                        lyrics: labelAsset.lyrics,
                        isrc: labelAsset.isrc,
                        producers: labelAsset.producers,
                        extras_notes: labelAsset.extras_notes,
                        valid: labelAsset.valid,
                        order: labelAsset.order,
                        documents: labelAsset.documents,
                        media: labelAsset.media,
                        audio_language: labelAsset.audio_language,
                        audio_track_url: labelAsset.audio_track_url,
                        video_cover: labelAsset.video_cover,
                        media_links: labelAsset.media_links,
                        tiktok_preview_start_time:
                            labelAsset.tiktok_preview_start_time,
                        version: labelAsset.version,
                        gender: labelAsset.gender,
                        subgender: labelAsset.subgender,
                        primary_artists:
                            labelAsset.primary_artists_for_typeahead,
                        featuring_artists:
                            labelAsset.featuring_artists_for_typeahead,
                        work_song: labelAsset.work_song,
                        DELETE: "",
                    });
                    loadedFadeOutList.push(false);
                });
                setCollabArtistsSelected(colabsList);
                setFeatArtistsSelected(featsList);
                loadedAssetsData.sort(dynamicSort("order")); // Ordenando a lista pelo campo order.
                setAssetList([...loadedAssetsData]);
                setFadeOut([...loadedFadeOutList]);
            }
        );
    }

    /**
     * Handler de mudanca no formulário de UM fonograma (o que está aberto no modal)
     * @param evt
     */
    const handleAssetChange = (evt) => {
        let value = evt.target.value;
        const file = evt.target.files;
        if (file) value = file[0]; // Se for um arquivo, pega o fulano e joga no vale pra ser setado no state logo abaixo
        if (evt.target.name === "video_cover" && file) {
            // Se for upload de capa de thumb do asset, temos que validar as suas dimensões
            var img = document.createElement("img");
            img.onload = function () {
                let getImageFile = document.querySelector(
                    '[name="video_cover"]'
                ); //pega o input de thumb do video
                if (this.width !== 1280 || this.height !== 720) {
                    // Se estiver inválido, mostre um alerta
                    alert.fire(
                        `${t("APPONI.LABELFORM.thumb_dimensions_invalid")} (${
                            this.width
                        }x${this.height}). ${t(
                            "APPONI.LABELFORM.thumb_must_be"
                        )} 1280x720.`
                    );

                    getImageFile.value = ""; //se as dimensões forem inválidas, não carrega nada
                    getImageFile.nextElementSibling.classList.add(
                        "validationErrors"
                    ); //se as dimensões forem inválidas, adiciona classe de erro na mensagem abaixo do input
                    setAssetData({
                        ...assetData,
                        [evt.target.name]: "",
                    });
                } else {
                    // Do contrário, apenas adicione o arquivo no state correspondente
                    setAssetData({
                        ...assetData,
                        [evt.target.name]: value,
                    });
                    getImageFile.nextElementSibling.classList.remove(
                        "validationErrors"
                    ); //se as dimensões forem válidas e houver classe de erro na mensagem, remove a classe
                }
            };
            // Uns código que eu não entendo mas que faz a validação funcionar
            var reader = new FileReader();
            reader.onloadend = function (ended) {
                img.src = ended.target.result;
            };
            reader.readAsDataURL(file[0]);
        } else {
            // Qualquer outro campo que for alterado cai automaticamente aqui
            setAssetData({
                ...assetData,
                [evt.target.name]: value,
            });
        }

        // Validação de ISRC
        if (evt.target.name === "isrc") {
            const regex = /^[A-Z]{2}-?\w{3}-?\d{2}-?\d{5}$/;
            const tempValErrors = { ...validationErrors };
            const inputValue = value.trim(); // Remover espaços em branco no início e no final

            if (inputValue === "") {
                // O campo está vazio, não faça nada
                return;
            }

            if (!regex.test(inputValue)) {
                if (validationErrors.formset_errors) {
                    if (validationErrors.formset_errors[currentAssetIndex]) {
                        tempValErrors.formset_errors[currentAssetIndex][
                            "isrc"
                        ] = t("APPONI.LABELFORM.isrc_invalid");
                        setValidationErrors(tempValErrors);
                    }
                }
            } else {
                if (currentAssetHasError("isrc")) {
                    delete tempValErrors.formset_errors[currentAssetIndex].isrc;
                    if (
                        Object.keys(
                            tempValErrors.formset_errors[currentAssetIndex]
                        ).length === 0
                    ) {
                        let tempInvalidAssets = [...invalidAssets];
                        tempInvalidAssets[currentAssetIndex] = "-";
                        setInvalidAssets(tempInvalidAssets);
                    }
                }
                return setValidationErrors(tempValErrors);
            }
        }
    };

    /**
     * Remove um fonograma da lista
     * @param {number} index
     */
    const removeFromAssetList = (index) => {
        let fadeOuList = fadeOut;
        fadeOuList[index] = true; // Definimos que o elemento atual será removido da lista
        setFadeOut([...fadeOuList]);
        let assets = assetList;
        // É essencial que o elemento sofra a transição de fadeOut, e só depois o DELETE seja marcado como true
        // para criar o efeito de que o elemento está sumindo da lista até que a lista realmente seja re-renderizada
        // sem o elemento que foi removido
        setTimeout(() => {
            assets[index].DELETE = "on";
            setAssetList([...assets]);
        }, 350); // 350 para coincidir com o tempo do efeito de transição do fadeOut

        setModal(false); // Necessário apenas ao remover o fonograma a partir do modal
    };

    /**
     * Verifica se um fonograma possui erros em um determinado campo
     * @param {String} field
     * @returns {*|boolean}
     */
    const currentAssetHasError = (field) => {
        return (
            validationErrors.formset_errors &&
            validationErrors.formset_errors[currentAssetIndex] &&
            currentAssetIndex < assetList.length &&
            validationErrors.formset_errors[currentAssetIndex][field]
        );
    };

    /**
     * Atualiza a lista de fonogramas
     * @param e
     */
    const updateAssetList = (e) => {
        e.preventDefault();
        // Validação de quantidade de intérpretes
        if (collabArtistsSelected[currentAssetIndex].length > 3) {
            alert.fire({
                icon: "warning",
                text: t("APPONI.LABELFORM.colab_limit_reached"),
            });
            return;
        }
        //caso o valor do isrc seja inválido, ele invalida o envio e da um focus no campo
        if (currentAssetHasError("isrc")) {
            const isrc = document.getElementById("isrc");
            isrc.focus();
        } else {
            // Atualizando a lista de fonogramas
            let currentList = assetList;
            currentList[currentAssetIndex] = assetData;
            setAssetList([...currentList]);

            // Atualizando a lista de fadeOuts de acordo com a lista de fonogramas
            let currentFadeOutList = fadeOut;
            currentFadeOutList[currentAssetIndex] = false;
            setFadeOut([...currentFadeOutList]);

            // Resetando assetData, uma vez que já usamos seu conteúdo
            setAssetData(initialAssetData);
            setModal(!modal);
        }
    };

    /**
     * Exibe os erros de validação retornados pelo backend no formulário.
     */
    const showFormErrors = (response) => {
        if (!response) {
            // Guard clause para caso a função seja chamada com um null em vez de uma response da api
            alert.fire(t("APPONI.LABELFORM.fatal_error"));
            return;
        }

        let formErrors = { ...response.data.message };
        const formsetErrors = formErrors.formset_errors;

        if (!formsetErrors) {
            alert.fire(t("APPONI.LABELFORM.fatal_error"));
            return;
        }

        let alertMessage = t("APPONI.LABELFORM.validation_error_msg");

        let invalidAssets = []; // Lista de objetos contendo os erros de cada formset
        for (let index = 0; index < assetList.length; index++) {
            invalidAssets.push({}); // Começa com um objeto vazio. Será preenchido com os erros logo em seguida
        }
        let invalidAssetIndexes = []; // Índices dos formsets com erro de validação
        for (let invalidFormset of formsetErrors) {
            let errorIndex = invalidFormset.index;
            let errorMsg = invalidFormset.errors;
            invalidAssets[errorIndex] = errorMsg;
            invalidAssetIndexes.push(errorIndex);
            // Se houver erro referente à música de trabalho, muda a mensagem de alerta.
            if (Object.keys(errorMsg).includes("work_song")) {
                alertMessage = errorMsg["work_song"][0];
            }
        }
        setInvalidAssets([...invalidAssetIndexes]);
        setValidationErrors({ formset_errors: invalidAssets });

        alert.fire(alertMessage);
    };

    /**
     * Handler de submissao do formulário
     * @param e
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSending(true);
        // Preciso pegar a lista de assets. Para cada asset, tenho que alterar os campos
        // main_interpreter e featuring. Para isso, vou pegar os states que contém a lista
        // com os valores desses campos em que cada índice corresponde a um asset, e fazer
        // a conversão da lista de objetos para lista de strings, tal como é feito no produto
        // 1. Guarda assetsList em uma variável que pode ser alterada em tempo real
        // 2. Em cada asset, pega o state de colabs no indice i e faz a formatação, colocando
        //      no campo main_interpreter
        // 2. Ainda em cada asset, pega o state de feats no indice i e faz a formatação, colocando
        //      no campo featuring
        // 3. Envia essa nova variável para formsetData
        let assetsContent = [...assetList]; // Variável cópia do state para podermos alterá-la sincronamente
        assetsContent.forEach((asset, index) => {
            // O forEach vai alterar na própria lista
            let collabArtistLabels = collabArtistsSelected[index]?.map(
                (item) => item.label
            );
            const featArtistsLabels = featArtistsSelected[index]?.map(
                (item) => item.label
            );
            asset.main_interpreter = isNotEmpty(collabArtistLabels)
                ? collabArtistLabels.join(", ")
                : ""; // Adicionar uma "," a cada artista adicionado no input de select
            asset.featuring = isNotEmpty(featArtistsLabels)
                ? featArtistsLabels.join(", ")
                : ""; // Adicionar uma "," a cada artista adicionado no input de select
        });
        const formsetData = [
            {
                djangoFormsetPrefix: "labelasset",
                totalForms: assetsContent.length,
                initialForms: initialForms,
                minNumForms: minNumForms,
                maxNumForms: maxNumForms,
                content: assetsContent,
            },
        ];
        let formData = makeFormDataBodyForAppOni(form, formsetData); // Constrói o Form Data

        let url;
        if (form.id)
            // Se for uma edição, chama a Url com o id da label
            url = `catalog/apiv2/label/new-or-edit/${form.id}`;
        // Se for criação, chama a Url sem id
        else url = "catalog/apiv2/label/new-or-edit";
        apponi_post(url, formData)
            .then((response) => {
                response = response.data;
                // Se correu tudo certo e a label foi editada carrega as informações atualizadas no formulário
                if (response.status === 200 && response.data.items.length) {
                    setForm({ ...form, id: response.data.items[0] });
                    let setProjectLabelIdFormData = new FormData();
                    setProjectLabelIdFormData.append(
                        "app_oni_label_id",
                        response.data.items[0]
                    );
                    POST(
                        `core/releases/set_app_oni_label_id/${projectId}`,
                        setProjectLabelIdFormData
                    );
                    if (needsAudioTracks) {
                        setFormPhase(3);
                    } else {
                        dialogs.success(() =>
                            window.location.replace("/projects/")
                        );
                    }
                } else showFormErrors(response); // Do contrário exibe os erros de validação do form
            })
            .catch((err) => {
                console.error(err);
                console.error(err.message);
                // apponi_post.post(Urls["label_catalog:log_label_validation_error"](), {
                //   error: err.message,
                // });
                alert.fire(t("APPONI.LABELFORM.import_error_msg"));
            })
            .finally(() => setFormSending(false));
    };

    /**
     * Ao clicar fora do campo de isrc, preenche as informações automaticamente caso já exista um fonograma correspondente
     * @param e
     */
    const handleBlur = (e) => {
        const value = e.target.value;
        if (!value) {
            return;
        }
        apponi_get(`catalog/apiv2/check-isrc-exists/${value}`)
            .then((response) => {
                let responseData = response.data;
                if (responseData.status === 200) {
                    let localData = assetData;
                    localData = {
                        ...localData,
                        asset: responseData.data.items[0].asset_id,
                        audio_language:
                            responseData.data.items[0].audio_language || "PT",
                        primary_artists:
                            responseData.data.items[0].primary_artists || [],
                        featuring_artists:
                            responseData.data.items[0].featuring_artists || [],
                        gender: responseData.data.items[0].gender || "",
                        subgender: responseData.data.items[0].subgender || "",
                        isrc: responseData.data.items[0].isrc,
                        media: responseData.data.items[0].media || "AUVD",
                        producers: responseData.data.items[0].producers || "",
                        tiktok_preview_start_time:
                            responseData.data.items[0]
                                .tiktok_preview_start_time || "",
                        title: responseData.data.items[0].title || "",
                        version: responseData.data.items[0].version || "",
                        asset_composers_text:
                            responseData.data.items[0].composers_text || "",
                    };

                    alert
                        .fire({
                            text: t("APPONI.LABELFORM.isrc_already_exists"),
                            confirmButtonText: t("GENERAL.OK"),
                            cancelButtonText: t("GENERAL.CANCEL"),
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                setAssetData(localData);
                            }
                        });
                } else setAssetData({ ...initialAssetData, isrc: value });
            })
            .catch((err) => {
                setAssetData({ ...initialAssetData, isrc: value });
            });
    };

    return (
        <>
            <Warning
                title={t("APPONI.LABELFORM.tracks")}
                description={t("APPONI.LABELFORM.tracks_tutorial")}
            />
            <Form onSubmit={handleSubmit} id="assets-form">
                <Table className="mt-3">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th
                                    key={`th-${index}`}
                                    className="fw-semibold fs-4"
                                >
                                    {header}
                                </th>
                            ))}
                            <th className="fw-semibold fs-4">
                                {t("APPONI.LABELFORM.edit")}
                            </th>
                            <th className="fw-semibold fs-4">
                                {t("APPONI.LABELFORM.remove")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {assetList.length ? (
                            assetList.map((formset, index) => {
                                // 'on' é o que o Django precisa receber para entender que é para deletar o formset.
                                if (formset.DELETE !== "on")
                                    return (
                                        <tr
                                            key={`assetList-${index}`}
                                            style={
                                                fadeOut[index]
                                                    ? styles.hidden
                                                    : styles.show
                                            }
                                        >
                                            {body.map((key, i) => (
                                                <td
                                                    style={
                                                        invalidAssets.includes(
                                                            index
                                                        )
                                                            ? styles.invalid
                                                            : styles.valid
                                                    }
                                                    key={`td-${i}`}
                                                >
                                                    {formset[key] === true ? (
                                                        <i className="fas fa-check" />
                                                    ) : (
                                                        formset[key]
                                                    )}
                                                </td>
                                            ))}
                                            <td>
                                                <CustomSaveButton
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                        />
                                                    }
                                                    className="btn btn-lg btn-primary"
                                                    type="button"
                                                    onClick={() =>
                                                        toggle(index)
                                                    }
                                                    buttonText={""}
                                                />
                                            </td>
                                            <td>
                                                <CustomSaveButton
                                                    icon={
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                        />
                                                    }
                                                    className="btn btn-lg btn-danger"
                                                    type="button"
                                                    onClick={() =>
                                                        removeFromAssetList(
                                                            index
                                                        )
                                                    }
                                                    buttonText={""}
                                                />
                                            </td>
                                        </tr>
                                    );
                            })
                        ) : (
                            <tr>
                                <td colSpan={headers.length + 2}>
                                    {t(
                                        "APPONI.LABELFORM.empty_formset_list_pt_one"
                                    )}
                                    <b> {t("APPONI.LABELFORM.add_asset")}</b>{" "}
                                    {t(
                                        "APPONI.LABELFORM.empty_formset_list_pt_two"
                                    )}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
                <CustomSaveButton
                    icon={<FontAwesomeIcon icon={faPlusCircle} />}
                    className="btn shadow btn-info w-100 mb-5"
                    onClick={() => toggle(assetList.length)}
                    buttonText={"APPONI.LABELFORM.add_asset"}
                    type="button"
                />
            </Form>
            <div className="d-flex justify-content-between mt-2 mb-5">
                <CustomSaveButton
                    icon={<FontAwesomeIcon icon={faArrowLeft} />}
                    buttonText={"APPONI.LABELFORM.back_to_label_filling_1"}
                    onClick={() => setFormPhase(1)}
                    className={`btn btn-lg btn-secondary`}
                />
                <CustomSaveButton
                    type="submit"
                    form="assets-form"
                    className={clsx("btn btn-lg", {
                        "btn-primary": needsAudioTracks,
                        "btn-success": !needsAudioTracks,
                    })}
                    disabled={needsMoreAssets}
                    formSending={formSending}
                    buttonText={
                        needsAudioTracks
                            ? "APPONI.LABELFORM.label_form_go_to_audio"
                            : "APPONI.LABELFORM.submit"
                    }
                    afterIcon={
                        needsAudioTracks ? (
                            <FontAwesomeIcon icon={faArrowRight} />
                        ) : null
                    }
                    icon={
                        !needsAudioTracks ? (
                            <>
                                <FontAwesomeIcon icon={faCheckCircle} />
                            </>
                        ) : null
                    }
                />
            </div>
            {needsMoreAssets && (
                <Row>
                    <Col xs={8}></Col>
                    <Col xs={4}>
                        <Warning
                            description={t(
                                "APPONI.LABELFORM.needs_more_tracks_warning"
                            )}
                        />
                    </Col>
                </Row>
            )}
            <ModalComponent
                modalHeader={
                    assetData.title
                        ? assetData.title
                        : t("APPONI.LABELFORM.new_asset")
                }
                isOpen={modal}
                toggle={toggle}
                modalFooter={
                    <div className="w-100 d-flex justify-content-between">
                        <CustomSaveButton
                            icon={<FontAwesomeIcon icon={faTrashAlt} />}
                            className="btn btn-lg btn-danger"
                            buttonText={"APPONI.LABELFORM.remove_asset"}
                            onClick={() =>
                                removeFromAssetList(currentAssetIndex)
                            }
                        />
                        <CustomSaveButton
                            icon={<FontAwesomeIcon icon={faCheckCircle} />}
                            className="btn btn-lg btn-primary"
                            buttonText={"APPONI.LABELFORM.confirm"}
                            type="submit"
                            form="asset-form"
                        />
                    </div>
                }
            >
                <Card className="mb-0 pb-0">
                    <Form onSubmit={updateAssetList} id="asset-form">
                        <FormGroup>
                            <input type="hidden" name="id" />
                            {assetData.asset && (
                                <>
                                    <div
                                        className="w-100 p-3 mb-3 rounded shadow-sm"
                                        style={{ backgroundColor: "#FFF9E9" }}
                                    >
                                        <span className="text-left d-flex justify-content-center align-items-center">
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                                color="orange"
                                                size={"2xl"}
                                                className="me-2"
                                            />
                                            <strong className="strongStyle">
                                                {t(
                                                    "APPONI.LABELFORM.existing_related_asset"
                                                )}{" "}
                                                {t(
                                                    "APPONI.LABELFORM.disabled_fields_postfix"
                                                )}
                                            </strong>
                                        </span>
                                    </div>
                                </>
                            )}
                            <Row className="mb-3">
                                <Col xs="2">
                                    <FormNumberInput
                                        validationError={currentAssetHasError(
                                            "order"
                                        )}
                                        required
                                        fieldName="order"
                                        label={t("APPONI.LABELFORM.order")}
                                        value={assetData.order}
                                        onChange={handleAssetChange}
                                        min={1}
                                    />
                                </Col>
                                <Col xs="6">
                                    <FormTextInput
                                        fieldName="title"
                                        label={t("APPONI.LABELFORM.title")}
                                        fieldId="modal_title"
                                        validationError={currentAssetHasError(
                                            "title"
                                        )}
                                        required
                                        disabled={assetData.asset !== ""}
                                        value={assetData.title}
                                        onChange={handleAssetChange}
                                    />
                                </Col>
                                <Col xs="4">
                                    <FormTextInput
                                        onBlur={handleBlur}
                                        fieldName="isrc"
                                        label={t("APPONI.LABELFORM.isrc")}
                                        validationError={currentAssetHasError(
                                            "isrc"
                                        )}
                                        required
                                        value={assetData.isrc}
                                        maxLength={12}
                                        onChange={handleAssetChange}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs="12" md="4">
                                    <FormTextArea
                                        fieldName="asset_composers_text"
                                        label={t("APPONI.LABELFORM.composers")}
                                        helpText={t(
                                            "APPONI.LABELFORM.label_composers_description"
                                        )}
                                        validationError={currentAssetHasError(
                                            "asset_composers_text"
                                        )}
                                        value={assetData.asset_composers_text}
                                        onChange={handleAssetChange}
                                        disabled={assetData.asset !== ""}
                                        cols={40}
                                        rows={1}
                                        required
                                    />
                                </Col>

                                <Col xs="12" md="4">
                                    <FormSelectInput
                                        options={mediaOptions}
                                        label={t("APPONI.LABELFORM.media")}
                                        fieldName="media"
                                        validationError={currentAssetHasError(
                                            "media"
                                        )}
                                        required
                                        value={assetData.media}
                                        onChange={handleAssetChange}
                                    />
                                </Col>
                                <Col xs="12" md="4">
                                    <FormTextInput
                                        label={t("APPONI.LABELFORM.producers")}
                                        required
                                        fieldName="producers"
                                        validationError={currentAssetHasError(
                                            "producers"
                                        )}
                                        value={assetData.producers}
                                        onChange={handleAssetChange}
                                        disabled={assetData.asset !== ""}
                                    />
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col xs={12} md={6}>
                                    <label className="text-lg">
                                        {t("APPONI.LABELFORM.interpreter")}
                                    </label>
                                    <CreatableSelect
                                        onChange={(item) => {
                                            let tempCollabArtistsSelected = [
                                                ...collabArtistsSelected,
                                            ];
                                            updateArrayAtIndex(
                                                tempCollabArtistsSelected,
                                                currentAssetIndex,
                                                item
                                            );
                                            setCollabArtistsSelected(
                                                tempCollabArtistsSelected
                                            );
                                        }}
                                        isMulti
                                        isClearable={true}
                                        isLoading={false}
                                        isDisabled={assetData.asset !== ""}
                                        isSearchable={true}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#1e1e2d",
                                                primary: "black",
                                            },
                                        })}
                                        fieldName="main_interpreter"
                                        value={
                                            collabArtistsSelected[
                                                currentAssetIndex
                                            ] || null
                                        }
                                    />
                                    <small>
                                        {t("APPONI.LABELFORM.colab_help")}
                                    </small>
                                </Col>
                                <Col xs={12} md={6}>
                                    <label className="text-lg">
                                        {t("APPONI.LABELFORM.feat")}
                                    </label>
                                    <CreatableSelect
                                        onChange={(item) => {
                                            let tempFeatArtistsSelected = [
                                                ...featArtistsSelected,
                                            ];
                                            updateArrayAtIndex(
                                                tempFeatArtistsSelected,
                                                currentAssetIndex,
                                                item
                                            );
                                            setFeatArtistsSelected(
                                                tempFeatArtistsSelected
                                            );
                                        }}
                                        isMulti
                                        isClearable={true}
                                        isLoading={false}
                                        isDisabled={assetData.asset !== ""}
                                        isSearchable={true}
                                        theme={(theme) => ({
                                            ...theme,
                                            borderRadius: 0,
                                            colors: {
                                                ...theme.colors,
                                                primary25: "#1e1e2d",
                                                primary: "black",
                                            },
                                        })}
                                        fieldName="featuring"
                                        value={
                                            featArtistsSelected[
                                                currentAssetIndex
                                            ] || null
                                        }
                                    />
                                    <small>
                                        {t("APPONI.LABELFORM.feat_help")}
                                    </small>
                                </Col>
                            </Row>

                            <Separator />

                            <Row className="mb-3">
                                <Col xs="12" md="6">
                                    <FormCheckboxInput
                                        fieldName="work_song"
                                        fieldId="work_song"
                                        label={t("APPONI.LABELFORM.work_song")}
                                        value={assetData.work_song}
                                        validationError={currentAssetHasError(
                                            "work_song"
                                        )}
                                        onChange={(evt) =>
                                            handleAssetChange({
                                                target: {
                                                    name: evt.target.name,
                                                    value: evt.target.checked,
                                                },
                                            })
                                        }
                                    />
                                    <br />
                                    {currentAssetHasError("work_song") && (
                                        <small className="validationErrors">
                                            {
                                                validationErrors.formset_errors[
                                                    currentAssetIndex
                                                ].work_song
                                            }
                                        </small>
                                    )}
                                </Col>
                                <Col xs="12" md="6">
                                    <FormTextArea
                                        label={t(
                                            "APPONI.LABELFORM.media_links"
                                        )}
                                        helpText={t(
                                            "APPONI.LABELFORM.media_links_description"
                                        )}
                                        fieldName="media_links"
                                        validationError={currentAssetHasError(
                                            "media_links"
                                        )}
                                        fieldId="modal_media_links"
                                        value={assetData.media_links}
                                        disabled={assetData.asset !== ""}
                                        cols={40}
                                        rows={1}
                                        onChange={handleAssetChange}
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs="12" md="4">
                                    <FormTimeInput
                                        label={`${t(
                                            "APPONI.LABELFORM.preview_start_time"
                                        )} (TIKTOK)`}
                                        fieldName="tiktok_preview_start_time"
                                        validationError={currentAssetHasError(
                                            "tiktok_preview_start_time"
                                        )}
                                        value={
                                            assetData.tiktok_preview_start_time
                                        }
                                        disabled={assetData.asset !== ""}
                                        onChange={handleAssetChange}
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormTextInput
                                        fieldName="gender"
                                        validationError={currentAssetHasError(
                                            "gender"
                                        )}
                                        value={assetData.gender}
                                        label={t("APPONI.LABELFORM.gender")}
                                        maxLength={25}
                                        disabled={assetData.asset !== ""}
                                        onChange={handleAssetChange}
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormTextInput
                                        fieldName="subgender"
                                        validationError={currentAssetHasError(
                                            "subgender"
                                        )}
                                        value={assetData.subgender}
                                        label={t("APPONI.LABELFORM.subgender")}
                                        maxLength={25}
                                        disabled={assetData.asset !== ""}
                                        onChange={handleAssetChange}
                                    />
                                </Col>
                            </Row>
                            <Separator />
                            <Row className="mb-3">
                                <Col xs="12" md="6">
                                    <FormFileInput
                                        label={t(
                                            "APPONI.LABELFORM.authoral_licences_documents"
                                        )}
                                        helpText={t(
                                            "APPONI.LABELFORM.documents_description"
                                        )}
                                        fieldName="documents"
                                        validationError={currentAssetHasError(
                                            "documents"
                                        )}
                                        currentFile={assetData.documents}
                                        filename={t(
                                            "APPONI.LABELFORM.download"
                                        )}
                                        disabled={assetData.asset !== ""}
                                        onChange={handleAssetChange}
                                        accept={
                                            ".pdf, .doc, .txt, .xlsx, .csv, .odt"
                                        }
                                    />
                                </Col>

                                <Col xs="12" md="6">
                                    {/* Thumbnail */}
                                    <FormFileInput
                                        helpText={t(
                                            "APPONI.LABELFORM.video_thumbnail_description"
                                        )}
                                        label={t(
                                            "APPONI.LABELFORM.video_thumbnail"
                                        )}
                                        currentFile={assetData.documents}
                                        filename={t(
                                            "APPONI.LABELFORM.download"
                                        )}
                                        fieldName="video_cover"
                                        validationError={currentAssetHasError(
                                            "video_cover"
                                        )}
                                        onChange={handleAssetChange}
                                        accept={".jpg, .jpeg, .png"}
                                    />
                                </Col>
                            </Row>
                            {currentClient.is_global && !currentArtist && (
                                <Row className="mb-3">
                                    <Col xs="12" md="6">
                                        <FormField
                                            label={t(
                                                "APPONI.LABELFORM.artists"
                                            )}
                                            fieldName="asset-artists"
                                            validationError={currentAssetHasError(
                                                "primary_artists"
                                            )}
                                            inputComponent={
                                                <AsyncTypeahead
                                                    id="asset-artists"
                                                    isLoading={artistIsLoading}
                                                    minLength={2}
                                                    onSearch={ArtistsSearch}
                                                    filterBy={() => {
                                                        return true;
                                                    }}
                                                    options={artistOptions}
                                                    multiple
                                                    clearButton
                                                    onChange={(selected) =>
                                                        handleAssetChange({
                                                            target: {
                                                                name: "primary_artists",
                                                                value: selected,
                                                            },
                                                        })
                                                    }
                                                    disabled={
                                                        assetData.asset !== ""
                                                    }
                                                    selected={
                                                        assetData.primary_artists
                                                    }
                                                    placeholder={t(
                                                        "APPONI.LABELFORM.type_to_search"
                                                    )}
                                                />
                                            }
                                        />
                                    </Col>
                                    <Col xs="12" md="6">
                                        <FormField
                                            label={t("APPONI.LABELFORM.feat")}
                                            fieldName="asset-feat"
                                            validationError={currentAssetHasError(
                                                "featuring_artists"
                                            )}
                                            inputComponent={
                                                <AsyncTypeahead
                                                    id="asset-feat"
                                                    isLoading={artistIsLoading}
                                                    minLength={2}
                                                    onSearch={ArtistsSearch}
                                                    filterBy={() => {
                                                        return true;
                                                    }}
                                                    options={artistOptions}
                                                    multiple
                                                    clearButton
                                                    onChange={(selected) =>
                                                        handleAssetChange({
                                                            target: {
                                                                name: "featuring_artists",
                                                                value: selected,
                                                            },
                                                        })
                                                    }
                                                    disabled={
                                                        assetData.asset !== ""
                                                    }
                                                    selected={
                                                        assetData.featuring_artists
                                                    }
                                                    placeholder={t(
                                                        "APPONI.LABELFORM.type_to_search"
                                                    )}
                                                />
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}

                            <Accordion itemClasses={itemClasses}>
                                <AccordionItem
                                    aria-label="moredataassets"
                                    title={t("GENERAL.MORE-DETAILS")}
                                >
                                    <Row className="my-4">
                                        <Col xs="12" md="4">
                                            <FormSelectInput
                                                label={t(
                                                    "APPONI.LABELFORM.audio_language"
                                                )}
                                                fieldName="audio_language"
                                                required
                                                validationError={currentAssetHasError(
                                                    "audio_language"
                                                )}
                                                value={assetData.audio_language}
                                                onChange={handleAssetChange}
                                                disabled={
                                                    assetData.asset !== ""
                                                }
                                                options={languageOptions}
                                            />
                                        </Col>
                                        <Col xs="12" md="4">
                                            <FormTextInput
                                                label={t(
                                                    "APPONI.LABELFORM.version"
                                                )}
                                                fieldName="version"
                                                fieldId="asset_version"
                                                validationError={currentAssetHasError(
                                                    "version"
                                                )}
                                                value={assetData.version}
                                                maxLength={40}
                                                onChange={handleAssetChange}
                                                disabled={
                                                    assetData.asset !== ""
                                                }
                                            />
                                        </Col>
                                        <Col md="12">
                                            <Row className="mb-3">
                                                <Col md="12">
                                                    <FormTextArea
                                                        label={t(
                                                            "APPONI.LABELFORM.lyrics"
                                                        )}
                                                        fieldName="lyrics"
                                                        validationError={currentAssetHasError(
                                                            "lyrics"
                                                        )}
                                                        value={assetData.lyrics}
                                                        onChange={
                                                            handleAssetChange
                                                        }
                                                        disabled={
                                                            assetData.asset !==
                                                            ""
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md="12">
                                                    <FormTextArea
                                                        label={t(
                                                            "APPONI.LABELFORM.general_info"
                                                        )}
                                                        helpText={t(
                                                            "APPONI.LABELFORM.general_info_description"
                                                        )}
                                                        fieldName="extras_notes"
                                                        fieldId="modal_extras_notes"
                                                        validationError={currentAssetHasError(
                                                            "extras_notes"
                                                        )}
                                                        value={
                                                            assetData.extras_notes
                                                        }
                                                        onChange={
                                                            handleAssetChange
                                                        }
                                                        disabled={
                                                            assetData.asset !==
                                                            ""
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                            <Row className="mb-3">
                                                <Col md="12">
                                                    <FormTextArea
                                                        label={t(
                                                            "APPONI.LABELFORM.copyright_notes"
                                                        )}
                                                        helpText={t(
                                                            "APPONI.LABELFORM.copyright_notes_description"
                                                        )}
                                                        fieldName="asset_holder_text"
                                                        fieldId="modal_asset_holder_text"
                                                        validationError={currentAssetHasError(
                                                            "asset_holder_text"
                                                        )}
                                                        value={
                                                            assetData.asset_holder_text
                                                        }
                                                        onChange={
                                                            handleAssetChange
                                                        }
                                                        disabled={
                                                            assetData.asset !==
                                                            ""
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </AccordionItem>
                            </Accordion>
                        </FormGroup>
                    </Form>
                </Card>
            </ModalComponent>
        </>
    );
}
