import React from "react";
import { Input } from "reactstrap";
import FormField from "../../FormField";
import { useTranslate } from "../../../../../../../helpers/i18n/useTranslate";

// O componente SelectInput deve ser usado APENAS por FormSelectInput.
const SelectInput = React.forwardRef(
    (
        {
            fieldName,
            fieldId,
            validationError,
            autoFocus,
            value,
            onChange,
            options,
            emptyChoiceDefault,
            disabled = false,
            nullable = false,
        },
        ref
    ) => {
        const { t } = useTranslate();
        return (
            <Input
                type="select"
                name={fieldName}
                id={fieldId || fieldName}
                className={validationError && "input-error"}
                autoFocus={autoFocus}
                ref={ref}
                value={value}
                disabled={disabled}
                onChange={onChange}
            >
                {emptyChoiceDefault && nullable ? (
                    <option selected value="">
                        --- {t("APPONI.LABELFORM.select_one")} ---
                    </option>
                ) : (
                    <option selected disabled value>
                        --- {t("APPONI.LABELFORM.select_one")} ---
                    </option>
                )}
                {options.map((option) => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    );
                })}
            </Input>
        );
    }
);

const FormSelectInput = ({
    className,
    label,
    fieldId,
    fieldName,
    validationError,
    required,
    helpText,
    autoFocus,
    inputRef,
    value,
    onChange,
    options,
    emptyChoiceDefault,
    nullable,
}) => {
    return (
        <FormField
            className={className}
            label={label}
            fieldName={fieldId || fieldName}
            validationError={validationError}
            helpText={helpText}
            required={required}
            inputComponent={
                <SelectInput
                    fieldName={fieldName}
                    fieldId={fieldId}
                    validationError={validationError}
                    autoFocus={autoFocus}
                    inputRef={inputRef}
                    value={value}
                    onChange={onChange}
                    options={options}
                    nullable={nullable}
                    emptyChoiceDefault={emptyChoiceDefault}
                />
            }
        />
    );
};

export default FormSelectInput;
